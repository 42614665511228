import http from '../utils/Http'
export function getLexiconList(data) {
    return http({
        url: 'car_info_dict/list',
        method: 'GET',
		params:data
    })
}
export function addLexicon(data) {
	return http({
		url: 'car_info_dict/add',
		method: 'POST',
		data: data
	})
}
export function updateLexicon(data) {
	return http({
		url: 'car_info_dict/update',
		method: 'PUT',
		data: data
	})
}

export function deleteLexicon(id) {
	return http({
		url: 'car_info_dict/delete?id='+id,
		method: 'DELETE'
	})
}

export function getLexiconSortList(data) {
    return http({
        url: 'car_info_dict_category/list',
        method: 'GET',
		params:data
    })
}
export function addLexiconSort(data) {
	return http({
		url: 'car_info_dict_category/add',
		method: 'POST',
		data: data
	})
}

export function updateLexiconSort(data) {
	return http({
		url: 'car_info_dict_category/update',
		method: 'PUT',
		data: data
	})
}

export function deleteLexiconSort(id) {
	return http({
		url: 'car_info_dict_category/delete?id='+id,
		method: 'DELETE'
	})
}