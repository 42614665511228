<template>
  <div class="switch" :class="{ on: on }" @click="change">
    <div class="dot"></div>
  </div>
</template>
<script>
export default {
  emits: ["changed"],
  props: {
    on: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    change() {
      this.$emit("changed");
    },
  },
};
</script>
<style lang="less" scoped>
@switchWidth: 72px;
@switchHeight: 36px;
@dotSize: @switchHeight - 4px;
@dotLeft: @switchWidth - @dotSize - 2px;
.switch {
  display: inline-block;
  width: @switchWidth;
  height: @switchHeight;
  border-radius: @switchHeight / 2;
  background: #f7f8f9;
  border: #e1e2e3 1px solid;
  position: relative;
  transition: background-color 400ms, border-color 400ms;

  .dot {
    position: absolute;
    left: 2px;
    top: 2px;
    width: @dotSize;
    height: @dotSize;
    border-radius: @dotSize / 2;
    background: white;
    box-sizing: border-box;
    border: #e1e2e3 1px solid;
    transition: left 400ms, border-color 400ms;
  }

  &.on {
    background: #07c160;
    border-color: #07c160;

    .dot {
      border-color: white;
      left: @dotLeft;
    }
  }
}
</style>