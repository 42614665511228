import {
	createRouter,
	createWebHashHistory
} from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import Dash from '../views/Dash.vue'
import Route from '../views/admin/Route'
import Permission from '../views/admin/Permission'
import PermissionAdd from '../views/admin/PermissionAdd'
import PermissionUpdate from '../views/admin/PermissionUpdate'
import Role from '../views/admin/Role'
import RoleAdd from '../views/admin/RoleAdd'
import RoleUpdated from '../views/admin/RoleUpdate'
import AdminUser from '../views/admin/User'
import AdminUserAdd from '../views/admin/UserAdd'
import AdminUserUpdated from '../views/admin/UserUpdate'
import AdminUserUpdateInfo from '../views/admin/UserUpdateInfo'
import AdminLog from '../views/admin/Log'
import AdminLogDetail from '../views/admin/LogDetail'

import HotelTranslateList from '../views/hotel/TranslateList'
import CarBrandTranslateList from '../views/car/BrandTranslateList'
import CarSeriesTranslateList from '../views/car/SeriesTranslateList'
import CarManufacturerTranslateList from '../views/car/ManufacturerTranslateList'
import CarModelTranslateList from '../views/car/ModelTranslateList'
import ConfigTranslateList from '../views/car/ConfigTranslateList'
import CarDictionaryTranslateList from '../views/car/DictionaryTranslateList'
import RegionTranslateList from '../views/region/RegionTranslateList'
import MerchantList from '../views/merchant/MerchantList'
import MerchantUpdate from '../views/merchant/MerchantUpdate'
import UsedCarList from '../views/used-car/UsedCarList'
import UsedCarUpdate from '../views/used-car/UsedCarUpdate'
import UsedCarTranslate from '../views/used-car/UsedCarTranslate'
import UsedCarPriceList from '../views/filters/price/List'
import UsedCarPriceAdd from '../views/filters/price/Add'
import UsedCarPriceUpdate from '../views/filters/price/Update'
import UsedCarOutputVolumeList from '../views/used-car/output-volume/UsedCarOutputVolumeList'
import UsedCarOutputVolumeAdd from '../views/used-car/output-volume/UsedCarOutputVolumeAdd'
import UsedCarOutputVolumeUpdate from '../views/used-car/output-volume/UsedCarOutputVolumeUpdate'
import UsedCarColorList from '../views/used-car/color/UsedCarColorList'
import UsedCarColorAdd from '../views/used-car/color/UsedCarColorAdd'
import UsedCarColorUpdate from '../views/used-car/color/UsedCarColorUpdate'
import UsedCarStandardList from '../views/used-car/standard/UsedCarStandardList.vue'
import UsedCarStandardAdd from '../views/used-car/standard/UsedCarStandardAdd.vue'
import UsedCarStandardUpdate from '../views/used-car/standard/UsedCarStandardUpdate.vue'
import UsedCarFuelList from '../views/used-car/fuel/UsedCarFuelList'
import UsedCarFuelAdd from '../views/used-car/fuel/UsedCarFuelAdd'
import UsedCarFuelUpdate from '../views/used-car/fuel/UsedCarFuelUpdate'
import UsedCarGearBoxList from '../views/used-car/gear-box/UsedCarGearBoxList'
import UsedCarGearBoxAdd from '../views/used-car/gear-box/UsedCarGearBoxAdd'
import UsedCarGearBoxUpdate from '../views/used-car/gear-box/UsedCarGearBoxUpdate'

import MenuList from '../views/admin/Menu'
import CarAttachmentList from '../views/car-attachment/CarAttachmentList'
import VinList from '../views/vin/VinList'
import CustomerList from '../views/customer/CustomerList'
import CustomerUpdate from '../views/customer/CustomerUpdate'
import ProvincesList from '../views/provinces/ProvincesList'
import CitiesList from '../views/cities/CitiesList'
import HelpList from '../views/help/List'
import HelpAdd from '../views/help/Add'
import HelpUpdated from '../views/help/Update'
import MessageList from '../views/message/List'
import MessageDetail from '../views/message/Detail'
import MessageSend from '../views/message/Send'
import MsgTemplateList from '../views/msg-template/List.vue'
import MsgTemplateAdd from '../views/msg-template/Add.vue'
import MsgTemplateUpdate from '../views/msg-template/Update.vue'
import PartsCategoryList from '../views/parts/CategoryList.vue'
import PartsCategoryAdd from '../views/parts/CategoryAdd.vue'
import PartsCategoryUpdate from '../views/parts/CategoryUpdate.vue'
import PartsPartList from '../views/parts/PartList.vue'
import PartsPartUpdate from '../views/parts/PartUpdate.vue'
import AboutDetail from '../views/about/detail.vue'
import SettingList from '../views/setting/SettingList'
import SettingAdd from '../views/setting/SettingAdd'
import SettingUpdate from '../views/setting/SettingUpdate'
import carTypeList from '../views/car-type/carTypeList'
import FiltersAgesList from '../views/filters/ages/List'
import FiltersAgesUpdate from '../views/filters/ages/Update'
import FiltersAgesAdd from '../views/filters/ages/Add'
import FiltersMileagesList from '../views/filters/mileages/List'
import FiltersMileagesUpdate from '../views/filters/mileages/Update'
import FiltersMileagesAdd from '../views/filters/mileages/Add'
import FiltersOutputVolumesList from '../views/filters/output-volumes/List'
import FiltersOutputVolumesUpdate from '../views/filters/output-volumes/Update'
import FiltersOutputVolumesAdd from '../views/filters/output-volumes/Add'
import ReportOptionList from '../views/report/option/List';
import ReportOptionAdd from '../views/report/option/Add';
import ReportOptionUpdate from '../views/report/option/Update';
import ReportLogList from '../views/report/log/List.vue'
import ReportLogUpdate from '../views/report/log/Update.vue'
import AgentList from '../views/agent/List'
import AgentAdd from '../views/agent/Add'
import AgentUpdate from '../views/agent/Update'
import AdviceList from '../views/advice/List'
import AdviceUpdate from '../views/advice/Update'
import VipPlanList from '../views/vip-plan/List'
import VipPlanAdd from '../views/vip-plan/Add'
import VipPlanUpdate from '../views/vip-plan/Update'
import LexiconList from '../views/lexicon/List'
import LexiconAdd from '../views/lexicon/Add'
import LexiconUpdate from '../views/lexicon/Update'
import LexiconSortList from '../views/lexicon/SortList'
import LexiconSortAdd from '../views/lexicon/SortAdd'
import LexiconSortUpdate from '../views/lexicon/SortUpdate'


const routes = [{
		path: '/',
		name: 'Home',
		component: Home,
		children: [{
				path: '',
				name: 'Dash',
				component: Dash
			},
			{
				path: '/admin/route',
				name: 'Route',
				component: Route
			},
			{
				path: '/admin/permission',
				name: 'Permission',
				component: Permission
			},

			{
				path: '/admin/permission/add',
				name: 'PermissionAdd',
				component: PermissionAdd
			},

			{
				path: '/admin/permission/update/:id',
				name: 'PermissionUpdate',
				component: PermissionUpdate
			},

			{
				path: '/admin/role',
				name: 'Role',
				component: Role
			},
			{
				path: '/admin/role/add',
				name: 'RoleAdd',
				component: RoleAdd
			},
			{
				path: '/admin/role/update/:id',
				name: 'RoleUpdated',
				component: RoleUpdated
			},
			{
				path: '/admin/user',
				name: 'AdminUser',
				component: AdminUser
			},
			{
				path: '/admin/user/add',
				name: 'AdminUserAdd',
				component: AdminUserAdd
			},
			{
				path: '/admin/user/update/:id',
				name: 'AdminUserUpdated',
				component: AdminUserUpdated
			},
			{
				path: '/admin/user/update-info',
				name: 'AdminUserUpdateInfo',
				component: AdminUserUpdateInfo
			},
			{
				path: '/admin/log',
				name: 'AdminLog',
				component: AdminLog
			},
			{
				path: '/admin/detail',
				name: 'AdminLogDetail',
				component: AdminLogDetail
			},
			{
				path: '/hotel/translate/list',
				name: 'HotelTranslateList',
				component: HotelTranslateList
			},
			{
				path: '/car-brand/translate/list',
				name: 'CarBrandTranslateList',
				component: CarBrandTranslateList
			},
			{
				path: '/car/config/translate/list',
				name: 'ConfigTranslateList',
				component: ConfigTranslateList
			},

			{
				path: '/car-series/translate/list',
				name: 'CarSeriesTranslateList',
				component: CarSeriesTranslateList
			},
			{
				path: '/car-manufacturer/translate/list',
				name: 'CarManufacturerTranslateList',
				component: CarManufacturerTranslateList
			},
			{
				path: '/car-model/translate/list',
				name: 'CarModelTranslateList',
				component: CarModelTranslateList
			},
			{
				path: '/car-dictionary/translate/list',
				name: 'CarDictionaryTranslateList',
				component: CarDictionaryTranslateList
			},
			{
				path: '/region/translate/list',
				name: 'RegionTranslateList',
				component: RegionTranslateList
			},

			{
				path: '/merchant/list',
				name: 'MerchantList',
				component: MerchantList
			},
			{
				path: '/merchant/update',
				name: 'MerchantUpdate',
				component: MerchantUpdate
			},
			{
				path: '/used/car/list',
				name: 'UsedCarList',
				component: UsedCarList
			},
			{
				path: '/used/car/update/:id',
				name: 'UsedCarUpdate',
				component: UsedCarUpdate
			},
			{
				path: '/used/car/translate/:id',
				name: 'UsedCarTranslate',
				component: UsedCarTranslate
			},
			{
				path: '/used/car/price/list',
				name: 'UsedCarPriceList',
				component: UsedCarPriceList
			},
			{
				path: '/used/car/price/add',
				name: 'UsedCarPriceAdd',
				component: UsedCarPriceAdd
			},
			{
				path: '/used/car/price/update',
				name: 'UsedCarPriceUpdate',
				component: UsedCarPriceUpdate
			},
			{
				path: '/used/car/output-volume/list',
				name: 'UsedCarOutputVolumeList',
				component: UsedCarOutputVolumeList
			},
			{
				path: '/used/car/output-volume/add',
				name: 'UsedCarOutputVolumeAdd',
				component: UsedCarOutputVolumeAdd
			},
			{
				path: '/used/car/output-volume/update',
				name: 'UsedCarOutputVolumeUpdate',
				component: UsedCarOutputVolumeUpdate
			},
			{
				path: '/used/car/color/list',
				name: 'UsedCarColorList',
				component: UsedCarColorList
			},
			{
				path: '/used/car/color/add',
				name: 'UsedCarColorAdd',
				component: UsedCarColorAdd
			},
			{
				path: '/used/car/color/update',
				name: 'UsedCarColorUpdate',
				component: UsedCarColorUpdate
			},
			{
				path: '/used/car/standard/list',
				name: 'UsedCarStandardList',
				component: UsedCarStandardList
			},
			{
				path: '/used/car/standard/add',
				name: 'UsedCarStandardAdd',
				component: UsedCarStandardAdd
			},
			{
				path: '/used/car/standard/update',
				name: 'UsedCarStandardUpdate',
				component: UsedCarStandardUpdate
			},
			{
				path: '/used/car/fuel/list',
				name: 'UsedCarFuelList',
				component: UsedCarFuelList
			},
			{
				path: '/used/car/fuel/add',
				name: 'UsedCarFuelAdd',
				component: UsedCarFuelAdd
			},
			{
				path: '/used/car/fuel/update',
				name: 'UsedCarFuelUpdate',
				component: UsedCarFuelUpdate
			},
			{
				path: '/used/car/gear-box/list',
				name: 'UsedCarGearBoxList',
				component: UsedCarGearBoxList
			},
			{
				path: '/used/car/gear-box/add',
				name: 'UsedCarGearBoxAdd',
				component: UsedCarGearBoxAdd
			},
			{
				path: '/used/car/gear-box/update',
				name: 'UsedCarGearBoxUpdate',
				component: UsedCarGearBoxUpdate
			},
			{
				path: '/admin/menu/list',
				name: 'MenuList',
				component: MenuList
			},
			{
				path: '/car-attachment/list',
				name: 'CarAttachmentList',
				component: CarAttachmentList
			},

			{
				path: '/vin/list',
				name: 'VinList',
				component: VinList
			},

			{
				path: '/customer/list',
				name: 'CustomerList',
				component: CustomerList
			},

			{
				path: '/customer/update',
				name: 'CustomerUpdate',
				component: CustomerUpdate
			},
			{
				path: '/cities/list',
				name: 'CitiesList',
				component: CitiesList
			},
			{
				path: '/provinces/list',
				name: 'ProvincesList',
				component: ProvincesList
			},
			{
				path: '/help/list',
				name: 'HelpList',
				component: HelpList
			},
			{
				path: '/help/add',
				name: 'HelpAdd',
				component: HelpAdd
			},
			{
				path: '/help/update',
				name: 'HelpUpdated',
				component: HelpUpdated
			},
			{
				path: '/message/list',
				name: 'MessageList',
				component: MessageList
			},
			{
				path: '/message/detail',
				name: 'MessageDetail',
				component: MessageDetail
			},
			{
				path: '/message/send',
				name: 'MessageSend',
				component: MessageSend
			},
			{
				path: '/msg-template/list',
				name: 'MsgTemplateList',
				component: MsgTemplateList
			},
			{
				path: '/msg-template/add',
				name: 'MsgTemplateAdd',
				component: MsgTemplateAdd
			},
			{
				path: '/msg-template/update',
				name: 'MsgTemplateUpdate',
				component: MsgTemplateUpdate
			},
			{
				path: '/parts/category/list',
				name: 'PartsCategoryList',
				component: PartsCategoryList
			},
			{
				path: '/parts/category/add',
				name: 'PartsCategoryAdd',
				component: PartsCategoryAdd
			},
			{
				path: '/parts/category/update',
				name: 'PartsCategoryUpdate',
				component: PartsCategoryUpdate
			},
			{
				path: '/parts/part/list',
				name: 'PartsPartList',
				component: PartsPartList
			},
			{
				path: '/parts/part/update',
				name: 'PartsPartUpdate',
				component: PartsPartUpdate
			},
			{
				path: '/about/detail',
				name: 'AboutDetail',
				component: AboutDetail
			},
			{
				path: '/setting/list',
				name: 'SettingList',
				component: SettingList
			},
			{
				path: '/setting/add',
				name: 'SettingAdd',
				component: SettingAdd
			},
			{
				path: '/setting/update',
				name: 'SettingUpdate',
				component: SettingUpdate
			},
			{
				path: 'car-type/carTypeList',
				name: 'carTypeList',
				component: carTypeList
			},
			{
				path: 'filters/ages/list',
				name: 'FiltersAgesList',
				component: FiltersAgesList
			},
			{
				path: 'filters/ages/update',
				name: 'FiltersAgesUpdate',
				component: FiltersAgesUpdate
			},
			{
				path: 'filters/ages/add',
				name: 'FiltersAgesAdd',
				component: FiltersAgesAdd
			},
			{
				path: 'filters/mileages/list',
				name: 'FiltersMileagesList',
				component: FiltersMileagesList
			},
			{
				path: 'filters/mileages/update',
				name: 'FiltersMileagesUpdate',
				component: FiltersMileagesUpdate
			},
			{
				path: 'filters/mileages/add',
				name: 'FiltersMileagesAdd',
				component: FiltersMileagesAdd
			},
			{
				path: 'filters/output-volumes/list',
				name: 'FiltersOutputVolumesList',
				component: FiltersOutputVolumesList
			},
			{
				path: 'filters/output-volumes/update',
				name: 'FiltersOutputVolumesUpdate',
				component: FiltersOutputVolumesUpdate
			},
			{
				path: 'filters/output-volumes/add',
				name: 'FiltersOutputVolumesAdd',
				component: FiltersOutputVolumesAdd
			},
			{
				path: 'report/option/list',
				name: 'ReportOptionList',
				component: ReportOptionList
			},
			{
				path: 'report/option/add',
				name: 'ReportOptionAdd',
				component: ReportOptionAdd
			},
			{
				path: 'report/option/update',
				name: 'ReportOptionUpdate',
				component: ReportOptionUpdate
			},
			{
				path: 'report/log/list',
				name: 'ReportLogList',
				component: ReportLogList
			},
			{
				path: 'report/log/update',
				name: 'ReportLogUpdate',
				component: ReportLogUpdate
			},
			{
				path: 'agent/list',
				name: 'AgentList',
				component: AgentList
			},
			{
				path: 'agent/add',
				name: 'AgentAdd',
				component: AgentAdd
			},
			{
				path: 'agent/update',
				name: 'AgentUpdate',
				component: AgentUpdate
			},
			{
				path: 'advice/list',
				name: 'AdviceList',
				component: AdviceList
			},
			{
				path: 'advice/update',
				name: 'AdviceUpdate',
				component: AdviceUpdate
			},
			
			{
				path: 'vip-plan/list',
				name: 'VipPlanList',
				component: VipPlanList
			},
			{
				path: 'vip-plan/add',
				name: 'VipPlanAdd',
				component: VipPlanAdd
			},
			{
				path: 'vip-plan/update',
				name: 'VipPlanUpdate',
				component: VipPlanUpdate
			},
			{
				path: 'lexicon/list',
				name: 'LexiconList',
				component: LexiconList
			},
			{
				path: 'lexicon/add',
				name: 'LexiconAdd',
				component: LexiconAdd
			},
			{
				path: 'lexicon/update',
				name: 'LexiconUpdate',
				component: LexiconUpdate
			},
			
			{
				path: 'lexicon/sort-list',
				name: 'LexiconSortList',
				component: LexiconSortList
			},
			{
				path: 'lexicon/sort-add',
				name: 'LexiconSortAdd',
				component: LexiconSortAdd
			},
			{
				path: 'lexicon/sort-update',
				name: 'LexiconSortUpdate',
				component: LexiconSortUpdate
			},
			{
				path: 'truck/type/list',
				name: 'TruckTypeList',
				component: () => import ("../views/truck/type/List")
			},
			{
				path: 'truck/type/add',
				name: 'TruckTypeAdd',
				component: () => import ("../views/truck/type/Add")
			},
			{
				path: 'truck/type/update',
				name: 'TruckTypeUpdate',
				component: () => import ("../views/truck/type/Update")
			},
			{
				path: 'truck/rank/list',
				name: 'TruckRankList',
				component: () => import ("../views/truck/rank/List")
			},
			{
				path: 'truck/rank/add',
				name: 'TruckRankAdd',
				component: () => import ("../views/truck/rank/Add")
			},
			{
				path: 'truck/rank/update',
				name: 'TruckRankUpdate',
				component: () => import ("../views/truck/rank/Update")
			},
			{
				path: 'truck/brand/list',
				name: 'TruckBrandList',
				component: () => import ("../views/truck/brand/List")
			},
			{
				path: 'truck/brand/add',
				name: 'TruckBrandAdd',
				component: () => import ("../views/truck/brand/Add")
			},
			{
				path: 'truck/brand/update',
				name: 'TruckBrandUpdate',
				component: () => import ("../views/truck/brand/Update")
			},
			
			{
				path: 'engineering-car/type/list',
				name: 'EngineeringCarTypeList',
				component: () => import ("../views/engineering-car/type/List")
			},
			{
				path: 'engineering-car/type/add',
				name: 'EngineeringCarTypeAdd',
				component: () => import ("../views/engineering-car/type/Add")
			},
			{
				path: 'engineering-car/type/update',
				name: 'EngineeringCarTypeUpdate',
				component: () => import ("../views/engineering-car/type/Update")
			},
			
			{
				path: 'motorcycle/type/list',
				name: 'MotorcycleTypeList',
				component: () => import ("../views/motorcycle/type/List")
			},
			{
				path: 'motorcycle/type/add',
				name: 'MotorcycleTypeAdd',
				component: () => import ("../views/motorcycle/type/Add")
			},
			{
				path: 'motorcycle/type/update',
				name: 'MotorcycleTypeUpdate',
				component: () => import ("../views/motorcycle/type/Update")
			},
			
			{
				path: 'motorcycle/brand/list',
				name: 'MotorcycleBrandList',
				component: () => import ("../views/motorcycle/brand/List")
			},
			{
				path: 'motorcycle/brand/add',
				name: 'MotorcycleBrandAdd',
				component: () => import ("../views/motorcycle/brand/Add")
			},
			{
				path: 'motorcycle/brand/update',
				name: 'MotorcycleBrandUpdate',
				component: () => import ("../views/motorcycle/brand/Update")
			},
			
			{
				path: 'used/car/tag/list',
				name: 'UsedCarTagList',
				component: () => import ("../views/used-car/tag/UsedCarTagList")
			},
			{
				path: 'used/car/tag/add',
				name: 'UsedCarTagAdd',
				component: () => import ("../views/used-car/tag/UsedCarTagAdd")
			},
			{
				path: 'used/car/tag/update',
				name: 'UsedCarTagUpdate',
				component: () => import ("../views/used-car/tag/UsedCarTagUpdate")
			},
			
			{
				path: 'banner/list',
				name: 'BannerList',
				component: () => import ("../views/banner/List")
			},
			{
				path: 'banner/add',
				name: 'BannerAdd',
				component: () => import ("../views/banner/Add")
			},
			{
				path: 'banner/update',
				name: 'BannerUpdate',
				component: () => import ("../views/banner/Update")
			}
		]
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
]

const router = createRouter({
	history: createWebHashHistory(process.env.BASE_URL),
	routes
})

export default router
