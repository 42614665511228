<template>
	<div class="container">
		<div class="title">
			<div class="text">价位表管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">最小价位</div>
					<div class="col flex-1">最大价位</div>
					<div class="col flex-1">排序号</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{index+1}}
					</div>
					<div class="col flex-1">{{item.tip_name}}</div>
					<div class="col flex-1">{{item.min_price}}</div>
					<div class="col flex-1">{{item.max_price}}</div>
					<div class="col flex-1">{{item.top}}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-title="item.tip_name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filter.size" :page="filter.page" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>

<script>
	let standById = 0;
	import {
		getUsedCarPriceList,
		deleteUsedCarPrice
	} from "@/api/used-car.js";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox
		},
		data() {
			return {
				dataList: [],
				filter: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "UsedCarPriceAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filter.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "UsedCarPriceUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				getUsedCarPriceList(this.filter).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteUsedCarPrice(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
