<template>
	<div class="container">
		<div class="title">
			<div class="text">路由管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="showAddModal">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-2">路径</div>
					<div class="col method">方法</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(route, index) in routes" :key="index">
					<div class="col id">{{ route.id }}</div>
					<div class="col flex-1">{{ route.name }}</div>
					<div class="col flex-2">{{ route.path }}</div>
					<div class="col method">
						<div class="tag" :class="[route.method.toLowerCase()]">
							{{ route.method }}
						</div>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showUpdateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-path="route.path" :data-id="route.id"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator"></div>
		</div>
		<route-add ref="addModal" v-on:addFinish="onAddFinish" />
		<route-update ref="updateModal" :info="standByInfo" v-on:updateFinish="onUpdateFinish" />
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>
<script>
	let self;

	import RouteAdd from "@/components/admin/RouteAdd";
	import RouteUpdate from "@/components/admin/RouteUpdate";
	import DialogBox from "@/components/widgets/DialogBox";

	import {
		getRoutes,
		deleteRoute
	} from "@/api/admin.js";

	let standById = 0;

	export default {
		components: {
			RouteAdd,
			RouteUpdate,
			DialogBox,
		},
		data() {
			return {
				routes: [],
				dialogContent: "",
				standByInfo: null,
			};
		},
		created() {
			self = this;
			this.loadData();
		},
		methods: {
			showAddModal() {
				this.$refs.addModal.show();
			},
			onAddFinish() {
				this.loadData();
			},
			showUpdateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.standByInfo = JSON.parse(JSON.stringify(this.routes[index]))
				this.$refs.updateModal.show();
			},
			onUpdateFinish() {
				this.loadData();
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.path + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				deleteRoute(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
			loadData() {
				getRoutes().then((res) => {
					if (res.status == 200) {
						this.routes = res.data;
					}
				});
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>
