<template>
  <div class="container">
    <div class="title">
      <div class="text">更新颜色信息</div>
    </div>
    <div class="content">
      <div class="row">
        <div class="left">中文名称</div>
        <div class="right">
          <input
            class="input"
            v-model="info.color_name"
            placeholder="请输入..."
          />
        </div>
      </div>
      <div class="row">
        <div class="left">维语名称</div>
        <div class="right">
          <input
            class="input rtl"
            v-model="info.color_name_ug"
            placeholder="كىرگۈزۈڭ..."
          />
        </div>
      </div>
      <div class="row">
        <div class="left">哈语名称</div>
        <div class="right">
          <input
            class="input rtl"
            v-model="info.color_name_kz"
            placeholder="كىرگىزىڭىز..."
          />
        </div>
      </div>
      <div class="row">
        <div class="left">颜色代码</div>
        <div class="right">
          <div class="language">
            <input class="before" type="text" v-model="info.code" />
            <i class="after">维文</i>
            <input class="after" type="color" v-model="info.code" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="left">排序号</div>
        <div class="right">
          <input
            type="number"
            class="right input"
            v-model="info.top"
            @input="inputChange"
            ref="topInput"
            placeholder="数字越大越靠后"
          />
        </div>
      </div>
      <div class="btn">
        <div class="btn-item" @click="cancel">取消</div>
        <div class="btn-item add" type="submit" @click="update">更新</div>
      </div>
    </div>
    <div class="toast" v-show="isShowToast">
      <span>{{ toastText }}</span>
    </div>
  </div>
</template>

<script>
let isLoad = false;
import { updateUsedCarColor } from "@/api/used-car.js";
export default {
  data() {
    return {
      info: {},
      toastText: "请正确输入参数",
      isShowToast: false,
    };
  },
  created() {
    isLoad = false;
    if (this.$route.params.info) {
      this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
    } else {
      this.showToast("获取数据失败");
    }
  },
  methods: {
    update() {
      if (isLoad) {
        this.showToast("请稍等！");
        return;
      }
      if (this.info.color_name == "") {
        this.showToast("请输入颜色名称！");
        return;
      }
      if (this.info.top == "") {
        this.showToast("请输入排序号！");
        return;
      }
      let data = {
        id: this.info.id,
        color_name: this.info.color_name,
        color_name_ug: this.info.color_name_ug,
        color_name_kz: this.info.color_name_kz,
		code: this.info.code ? this.info.code : '#ffffff',
        top: this.info.top * 1,
      };
      isLoad = true;
      updateUsedCarColor(data)
        .then((res) => {
          if (res.status == 200) {
            this.showToast(res.data);
            setTimeout(() => {
              this.$router.back();
            }, 500);
          } else {
            this.showToast(res.message);
          }
          isLoad = false;
        })
        .catch(() => {
          isLoad = false;
          this.showToast("出错了！");
        });
    },
    cancel() {
      this.$router.back();
    },
    inputChange(e) {
      e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode));
    },
    showToast(e) {
      this.toastText = e;
      this.isShowToast = true;
      setTimeout(() => {
        this.isShowToast = false;
      }, 1500);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("~@/assets/style/detail.less");

.container {
  .input-mini {
    width: 50px;
  }
}
</style>