import http from '../utils/Http.js'

// 车年龄
export function getFilterAgesList() {
	return http({
		url: "filter-ages",
		method: "GET"
	})
}

export function updateFilterAge(data) {
	return http({
		url: "filter-age",
		method: "PUT",
		data: data
	})
}

export function addFilterAge(data) {
	return http({
		url: "filter-age",
		method: "POST",
		data: data
	})
}

//里程
export function getFilterMileagesList() {
	return http({
		url: "filter-mileages",
		method: "GET"
	})
}

export function updateFilterMileage(data) {
	return http({
		url: "filter-mileage",
		method: "PUT",
		data: data
	})
}

export function addFilterMileage(data) {
	return http({
		url: "filter-mileage",
		method: "POST",
		data: data
	})
}

// 排量
export function getFilterOutputVolumesList() {
	return http({
		url: "filter-output-volumes",
		method: "GET"
	})
}

export function updateFilterOutputVolume(data) {
	return http({
		url: "filter-output-volume",
		method: "PUT",
		data: data
	})
}

export function addFilterOutputVolume(data) {
	return http({
		url: "filter-output-volume",
		method: "POST",
		data: data
	})
}
