import http from '../utils/Http.js'

export function getAdviceList(data) {
	return http({
		url: 'advices',
		method: 'GET',
		params: data
	})
}

export function getAdvice(id) {
	return http({
		url: 'advice/'+id,
		method: 'GET'
	})
}

export function updateAdvice(data) {
	return http({
		url: 'advice/status',
		method: 'PUT',
		params: data
	})
}