<template>
	<div class="container">
		<div class="title">
			<div class="text">设置</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">应用名称</div>
					<div class="col flex-1">模块名称</div>
					<div class="col flex-1">键</div>
					<div class="col flex-1 center">值</div>
					<div class="col flex-1">字段名称</div>
					<div class="col flex-1">类型</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{item.id}}
					</div>
					<div class="col flex-1">{{item.app_name}}</div>
					<div class="col flex-1">{{item.module_name}}</div>
					<div class="col flex-1">{{item.key}}</div>
					<div class="col flex-1 center avatar">
						<img class="avatar-img no-radius" v-if="item.type==3" :src="item.val" >
						<button v-else type="button" class="min-btn" :data-index="index" @click="showValue">查看</button>
					</div>
					<div class="col flex-1">{{item.label}}</div>
					<div class="col flex-1">
						<span v-if="item.type==1">布尔值</span>
						<span v-if="item.type==2">字符串</span>
						<span v-if="item.type==3">图片</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filter.size" :page="filter.page" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" :title="'请勿泄露!'" v-on:confirm="onConfirmDelete" :buttons="['关闭']"/>
	</div>
</template>

<script>
	let standById = 0;
	import {
		getSettingList,
	} from "@/api/setting.js";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox
		},
		data() {
			return {
				dataList: [],
				filter: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "SettingAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filter.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "SettingUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				getSettingList(this.filter).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
			},
			showValue(e) {
				let index = e.currentTarget.dataset.index;
				let dialogContent = this.dataList[index * 1].val;
				standById = 0;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>