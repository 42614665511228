<template>
	<div class="container">
		<div class="login-box">
			<div class="title">欢迎回来！</div>
			<div class="form">
				<div class="row">
					<div class="icon">
						<i class="iconfont icon-shouji"></i>
					</div>
					<input type="text" v-model="usr" spellcheck="false" autofocus />
				</div>

				<div class="row">
					<div class="icon">
						<i class="iconfont icon-mima"></i>
					</div>
					<input type="password" v-model="pwd" @input="onInput" />
				</div>
				<div class="row flat">
					<div class="switch-area">
						<div class="text">记住密码</div>
						<div class="switch">
							<switcha :on="rememberMe" v-on:changed="onRememberMeSwitchChanged" />
						</div>
					</div>
				</div>
				<div class="row flat">
					<button @click="login">登录</button>
				</div>
				<div class="row flat flex-center">{{ errMsg }}</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		login
	} from "../api/common.js";
	import Switcha from "../components/widgets/Switch.vue";
	export default {
		name: "Login",
		components: {
			Switcha,
		},
		data() {
			return {
				usr: "",
				pwd: "",
				rememberMe: false,
				errMsg: "",
			};
		},
		created() {
			this.checkToken();
			this.usr = localStorage.getItem("usr") || '';
			this.pwd = localStorage.getItem("pwd") || '';
		},
		methods: {
			login() {
				login(this.usr, this.pwd).then((res) => {
					if (res.status === 200) {
						localStorage.setItem("loginData", JSON.stringify(res.data));
						localStorage.setItem("token", res.data.token);
						this.$router.replace("/");
						if (this.rememberMe) {
							localStorage.setItem("usr", this.usr)
							localStorage.setItem("pwd", this.pwd)
						}
					} else {
						this.errMsg = res.message;
						setTimeout(() => {
							this.errMsg = "";
						}, 3000);
					}
				});
			},
			checkToken() {
				let chk =
					localStorage.getItem("loginData") == null ||
					localStorage.getItem("token") == null;

				if (!chk) {
					this.$router.replace("/");
				}
			},
			onRememberMeSwitchChanged() {
				this.rememberMe = !this.rememberMe;
			}
		},
	};
</script>
<style lang="less" scoped>
	@rowHeight: 48px;
	@titleHeight: 80px;

	.container {
		position: fixed;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		background: white url("../assets/img/login_bg.svg") no-repeat;
		background-size: cover;

		.login-box {
			width: 600px;
			height: 400px;
			background: white;
			border-radius: 16px;
			box-shadow: rgba(0, 0, 0, 0.1) 0 4px 16px;

			.title {
				height: @titleHeight;
				line-height: @titleHeight;
				text-align: center;
				font-size: 18px;
			}

			.form {
				padding: 32px 128px;

				.row {
					height: @rowHeight;
					margin-bottom: 16px;
					display: flex;
					background: #f6f7f8;
					border-radius: @rowHeight / 2;
					overflow: hidden;

					.icon {
						width: @rowHeight;
						height: @rowHeight;
						display: flex;
						justify-content: center;
						align-items: center;

						.iconfont {
							font-size: 24px;
						}
					}

					input[type="text"],
					input[type="password"] {
						flex: 1;
						background: transparent;
						border: none;
					}

					.switch-area {
						flex: 1;
						display: flex;
						align-content: center;
						justify-content: space-between;
						align-items: center;
					}

					&.flat {
						background: transparent;
						border-radius: 0;
					}

					button {
						flex: 1;
						border-radius: @rowHeight / 2;
						border: none;
						color: white;
						font-size: 14px;
						background: linear-gradient(-220deg, #f5af19, #f12711);
						background-size: 200% @rowHeight;
						background-position-x: 100%;
						cursor: pointer;
						box-shadow: #f5af1933 0 8px 8px;
						transition: background-position-x 1s;

						&:hover {
							background-position-x: 0;
						}
					}
				}
			}
		}
	}
</style>
