<template>
	<div class="container">
		<div class="title">
			<div class="text">数据字典库</div>
		</div>

		<div class="serach">
			<input type="text" class="keyword" v-model="word" @input="onInput" />
			<button class="serach-btn" @click="loadDictionary">搜索</button>

			<div class="iconfont icon-refresh refresh" @click="refresh"></div>
			<div class="status">
				<div class="status-item">
					<div class="text">维语</div>
					<switcha :on="untranslated_ug" v-on:changed="onTranslateUgChanged" />
				</div>
				<div class="status-item">
					<div class="text">哈语</div>
					<switcha :on="untranslated_kz" v-on:changed="onTranslateKzChanged" />
				</div>
			</div>
		</div>

		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维文</div>
					<div class="col flex-1">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in dictionary" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.value }}</div>
					<div class="col flex-1 rtl-left">{{ item.value_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.value_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<dictionary-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadDictionary" />
	</div>
</template>
<script>
	import {
		getDictionary
	} from "@/api/car";
	import Paginator from "@/components/widgets/Paginator.vue";
	import Switcha from "@/components/widgets/Switch.vue";
	import DictionaryTranslateModal from "@/components/car/DictionaryTranslateModal.vue";
	export default {
		components: {
			Switcha,
			Paginator,
			DictionaryTranslateModal,
		},
		data() {
			return {
				word: "",
				routes: [],
				dictionary: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				untranslated_ug: false,
				untranslated_kz: false,
				infoToTranslate: null,
			};
		},
		created() {
			this.loadDictionary();
		},
		methods: {
			loadDictionary() {
				getDictionary(
					this.curPage,
					this.pageSize,
					this.word,
					this.untranslated_ug,
					this.untranslated_kz
				).then((res) => {
					if (res.status == 200) {
						this.dictionary = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			onTranslateUgChanged() {
				this.untranslated_ug = !this.untranslated_ug;

				this.reloadDictionary();
			},

			onTranslateKzChanged() {
				this.untranslated_kz = !this.untranslated_kz;

				this.reloadDictionary();
			},
			reloadDictionary(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadDictionary();
			},
			refresh() {
				this.word = "";
				this.loadDictionary();
				this.curPage = 0;
			},
			onPageChanged(page) {
				this.reloadDictionary(page);
			},
			onInput: function(e) {
				this.word = e.target.value;
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.dictionary[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>