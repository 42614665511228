import http from '../utils/Http.js'

/**
 * POST 方法 与 GET方法  区别
 *
 * post==> data: obj
 * get==>params: obj
 *
 */

export function login(usr, pwd) {
	let data = {
		usr: usr,
		pwd: pwd
	}

	return http({
		url: 'login',
		method: 'POST',
		data: data
	})
}


export function getSideMenu() {
	return http({
		url: 'side-menu',
		method: 'GET'
	})
}


export function uploadFile(data) {
	return http({
		url: 'upload',
		method: 'POST',
		data: data,
		processData: false,
		contentType: false,
	})
}

// 获取省份，城市
export function getProvince() {
	return http({
		url: 'get-province',
		method: 'GET',
	})
}
export function getCity(code) {
	return http({
		url: 'get-city',
		method: 'GET',
		params: {
			parent_code: code
		}
	})
}
