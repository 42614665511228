<template>
	<div class="container">
		<div class="title">
			<div class="text">更新用户信息</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" :src="info.avatar_url" :data-url="info.avatar_url" @click="openPreview">
				</div>
				<div class="logo-title">头像</div>
			</div>
			<div class="row">
				<div class="left">用户名称</div>
				<div class="right">
					<div class="language">
						<input type="text" class="before" v-model="info.real_name" disabled>
						<span class="after">中文</span>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_ug">
						<span class="after">维文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="uy"
							data-source="real_name" data-target="real_name_ug">翻译</div>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_kz">
						<span class="after">哈文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="ka"
							data-source="real_name" data-target="real_name_kz">翻译</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.mobile_phone" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">身份证号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.id_car_no" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">性别</div>
				<div class="right">
					<select class="select" v-model="info.gender" disabled>
						<option value="男">男</option>
						<option value="女">女</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">认证状态</div>
				<div class="right">
					<select v-model="info.verification_status" class="select">
						<option :value="0">未申请</option>
						<option :value="1">审核通过</option>
						<option :value="2">待审核</option>
						<option :value="3">未通过</option>
					</select>
				</div>
			</div>
			<div class="row" v-if="info.verification_status*1==3">
				<div class="left">选择原因</div>
				<div class="right">
					<select class="select" @change="changeMsgTemplate">
						<option :value="-1">请选择原因</option>
						<option :value="index" v-for="(item,index) in msg_template" :key="index">{{item.title}}</option>
					</select>
				</div>
			</div>
			<div class="row" v-if="info.verification_status*1==3">
				<div class="left">未通过原因</div>
				<div class="right">
					<textarea class="textarea" v-model="template_content" disabled></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">代理状态</div>
				<div class="right">
					<select v-model="info.is_promoter" class="select">
						<option :value="0">不是代理人</option>
						<option :value="1">代理人</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">账号状态</div>
				<div class="right">
					<select v-model="info.status" class="select">
						<option :value="0">关闭</option>
						<option :value="1">正常</option>
						<option :value="2">封号</option>
					</select>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="update">更新</div>
			</div>
		</div>
		<div class="preview" :class="{show:preview.isShow}">
			<img class="preview-img" :style="'transform:rotate('+preview.rotate+'deg)'" :src="preview.url" alt=""
				@click="preview.rotate+=90">
			<div class="preview-btn">
				<i class="iconfont icon-guanbi" @click="preview.isShow=false"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateCustomer
	} from "@/api/customer.js";
	import {
		getMsgTemplateList
	} from "@/api/msg-template";
	import {
		translate
	} from "@/api/translate.js";
	export default {
		data() {
			return {
				info: {
					app_name: "",
					module_name: "",
					key: "",
					val: "",
					label: "",
					type: -1,
					is_promoter:0
				},
				info_orginal: {},
				msg_template: {},
				template_id: -1,
				template_content: "",
				preview: {
					url: "",
					rotate: 0,
					isShow: false
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info))
				this.info_orginal = JSON.parse(decodeURIComponent(this.$route.params.info))

				this.getMsgTemplateData()
			} else {
				this.showToast("获取数据失败")
			}
		},
		methods: {
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (data) {
					isLoad = true;
					updateCustomer(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					if (data == false) {
						this.showToast("没有可更新数据")
					}
				}

			},
			getChangeData() {
				let data = {
					id: this.info.id
				}
				if (this.info.real_name_ug != this.info_orginal.real_name_ug) {
					data = {
						...data,
						real_name_ug: this.info.real_name_ug
					}
				}
				if (this.info.real_name_kz != this.info_orginal.real_name_kz) {
					data = {
						...data,
						real_name_kz: this.info.real_name_kz
					}
				}
				if (this.info.is_promoter != this.info_orginal.is_promoter) {
					data = {
						...data,
						is_promoter: this.info.is_promoter
					}
				}
				if (this.info.verification_status != this.info_orginal.verification_status) {
					if (this.info.verification_status == 3) {
						if (this.template_id == -1) {
							this.showToast("请选择不通过原因");
							return;
						} else {
							data = {
								...data,
								verification_status: this.info.verification_status * 1,
								template_id: this.template_id
							}
						}

					} else {
						data = {
							...data,
							verification_status: this.info.verification_status * 1
						}
					}
					let a = Boolean(this.info.real_name)
					let b = Boolean(this.info.real_name_ug)
					let c = Boolean(this.info.real_name_kz)
					if (this.info.verification_status == 1 && !(a && b && c)) {
						this.showToast("用户名称没有翻译完成");
						return;
					}
				}
				if (this.info.status != this.info_orginal.status) {
					data = {
						...data,
						status: this.info.status
					}
				}
				if (Object.keys(data).length > 1) {
					return data
				} else {
					return false;
				}
			},
			getMsgTemplateData() {
				getMsgTemplateList(1, 20).then(res => {
					if (res.status == 200) {
						this.msg_template = res.data.data
					}
				})
			},
			changeMsgTemplate(e) {
				let index = e.target.value;
				if (index == -1) {
					this.template_id = -1;
					this.template_content = ""
				} else if (index < this.msg_template.length) {
					this.template_id = this.msg_template[index].id
					this.template_content = this.msg_template[index].content
				}
			},
			cancel() {
				this.$router.back()
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				this.preview.rotate = 0;
				this.preview.url = url;
				this.preview.isShow = true;
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
			translateDate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				translate(this.info[source], to).then((res) => {
					if (res.status == 200) {
						this.info[target] = res.data;
					}
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");

	@rightWidth: 350px;
</style>
