<template>
	<div class="container" :class="{ shown: shown, fading: fading }">
		<div class="box" :class="{ fading: fading }">
			<div class="title">基本信息</div>
			<div class="box-body">
				<div class="col-sm-12">
					<div class="form-group">
						<div class="col-sm-12 center">
							<img :src="customer.avatar_url" alt="" class="bg-cover" />
							<div class="control-label">头像</div>
						</div>
					</div>

					<div class="form-group">
						<div class="col-sm-4 text-right">
							<label class="control-label">姓名</label>
						</div>
						<div class="col-sm-8">
							<input type="text" v-model="customer.real_name" disabled class="form-control" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-4 text-right">
							<label class="control-label">性别</label>
						</div>
						<div class="col-sm-8">
							<input type="text" v-model="customer.gender" disabled class="form-control" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-4 text-right">
							<label class="control-label">身份证号码</label>
						</div>
						<div class="col-sm-8">
							<input type="text" v-model="customer.id_car_no" disabled class="form-control" />
						</div>
					</div>

					<div class="form-group">
						<div class="col-sm-4 text-right">
							<label class="control-label">手机号</label>
						</div>
						<div class="col-sm-8">
							<input type="text" v-model="customer.mobile_phone" disabled class="form-control" />
						</div>
					</div>
					<div class="form-group">
						<div class="col-sm-4 text-right">
							<label class="control-label">状态</label>
						</div>
						<div class="col-sm-8">
							<span class="tag" :class="{ selected: item.status == status*1 }"
								v-for="(item, statusIndex) in statusData" :key="statusIndex" :data-status="item.status"
								@click="setStatus">{{ item.title }}</span>
						</div>
					</div>
				</div>
				<div class="bottom">
					<button class="cancel" @click="dismiss">取消</button>
					<button class="confirm" @click="save">保存</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		updateCustomer
	} from "@/api/customer.js";
	export default {
		emits: ["confirm"],
		props: {
			info: {
				type: Object,
				default: null,
			},
		},
		watch: {
			info(val) {
				if (val === null) return;
				this.customer = val;
				this.status = val.status
			},
		},
		data() {
			return {
				status: -1,
				statusData: [{
						"status": 0,
						"title": "关闭"
					},
					{
						"status": 1,
						"title": "正常"
					},
					{
						"status": 2,
						"title": "封号"
					},
				],
				photo: [],
				customer: {},
				shown: false,
				fading: false,
				loading: false,
			};
		},
		methods: {
			show() {
				this.shown = true;
				setTimeout(() => {
					this.fading = true;
				}, 10);
			},
			dismiss() {
				this.fading = false;
				setTimeout(() => (this.shown = false), 400);
			},
			confirm() {
				this.$emit("confirm");
				this.dismiss();
			},
			save() {
				let id = this.info.id;
				let status = this.status;
				let data = {
					id: id,
					status: status
				}
				if (status == -1) return;
				if (this.loading) return;
				this.loading = true;
				
				updateCustomer(data).then((res) => {
					if (res.status == 200) {
						this.$emit("confirm");
						this.dismiss();
					}
					this.loading = false;
				}).catch(()=>{
					this.loading = false;
				})
			},
			setStatus(e){
				let status = e.currentTarget.dataset.status;
				this.status=status*1
			}
		},
	};
</script>
<style lang="less" scoped>
	@titleHeight: 50px;
	@bottomHeight: 66px;
	@buttonHeight: 40px;

	@inputHeight: 36px;

	.container {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 999;
		left: 0;
		top: 0;
		display: none;
		opacity: 0;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.618);
		transition: opacity 400ms;
		user-select: none;

		&.shown {
			display: flex;
		}

		&.fading {
			opacity: 1;
		}

		.box {
			display: flex;
			flex-direction: column;
			width: 620px;
			border-radius: 16px;
			background: white;
			transform: scale(0.1);
			transition: transform 400ms;

			&.fading {
				transform: scale(1);
			}

			.title {
				height: @titleHeight;
				line-height: @titleHeight;
				font-size: 16px;
				text-align: center;
			}
			.tag {
				background-color: #f1f1f1;
				padding: 0 8px;
				margin: 8px 8px;
				border-radius: 5px;
				display: inline-block;
				line-height: 1.5;
			}
			
			& .selected {
				background: linear-gradient(135deg, #f5af19, #f12711);
				color: white;
			}
			.form {
				flex: 1;
				padding: 0 32px 16px 32px;

				.box-body,
				.box-footer {
					padding: 20px 20px;
				}
			}

			.box-body {
				border-top-left-radius: 0;
				border-top-right-radius: 0;
				border-bottom-right-radius: 3px;
				border-bottom-left-radius: 3px;
				padding: 10px;
			}

			.col-sm-12 {
				width: 90%;
			}

			.center {
				text-align: center;
				margin: 0 auto;
			}

			.col-sm-12 {
				position: relative;
				min-height: 1px;
				padding-right: 15px;
				padding-left: 15px;

				.col-sm-4 {
					width: 33.33333333%;
				}

				.col-sm-8 {
					width: 66.66666667%;
				}

				.form-group {
					margin-bottom: 15px;
					display: flex;
				}

				.form-control,
				select {
					display: block;
					width: 100%;
					height: 34px;
					font-size: 14px;
					color: #555;
					text-indent: 10px;
					background-color: #fff;
					background-image: none;
					border: 1px solid #ccc;
					border-radius: 4px;
				}

				.photo {
					display: flex;
					margin: 5px;
					flex-wrap: nowrap;
					flex-direction: row;
				}

				.photo .bg-cover {
					margin-left: 5px;
				}

				.no-radius {
					border-radius: initial !important;
				}

				.business {
					width: 100%;
					height: 230px;
					object-fit: cover;
				}

				.bg-cover {
					border-radius: 100%;
					background-size: contain;
					background-position: center;
					height: 110px;
					width: 110px;
					object-fit: cover;
				}

				.row {
					display: flex;
					flex-direction: column;
					margin-bottom: 16px;

					label {
						margin-bottom: 4px;
					}

					input[type="text"] {
						border: none;
						height: @inputHeight;
						border-radius: @inputHeight / 2;
						padding: 0 16px;
						background: #f7f8f9;
					}

					&.sep {
						align-items: center;
						height: @inputHeight;
						line-height: @inputHeight;
						position: relative;
						text-align: center;
						color: #ccc;

						&::before {
							position: absolute;
							left: 0;
							top: 0;
							z-index: -1;
							content: " ";
							width: 100%;
							height: @inputHeight / 2;
							border-bottom: 1px #e5e5e5 solid;
						}

						.sep-title {
							display: inline-block;
							background: white;
							width: 100px;
						}
					}

					.input-area {
						display: flex;

						input[type="text"] {
							flex: 1;
						}

						button {
							margin-left: 16px;
							border: none;
							width: @inputHeight * 2;
							border-radius: @inputHeight / 2;
							background: linear-gradient(-220deg, #36d1dc, #5b86e5);
							background-size: 200% @titleHeight;
							cursor: pointer;
							color: white;
							text-shadow: #5b86e5 0 1px 4px;
							box-shadow: #36d1dc66 0 4px 16px;
							transition: background-position-x 400ms;

							&:hover {
								background-position-x: 100%;
							}
						}
					}
				}
			}

			.bottom {
				height: @bottomHeight;
				display: flex;

				button {
					flex: 1;
					border: none;
					height: @buttonHeight;
					border-radius: @buttonHeight / 2;
					margin: 0 32px;
					cursor: pointer;

					&.confirm {
						background: linear-gradient(-220deg, #f5af19, #f12711);
						background-size: 200% @titleHeight;
						color: white;
						text-shadow: #f12711 0 1px 4px;
						box-shadow: #f5af1966 0 4px 16px;
						transition: background-position-x 400ms;

						&:hover {
							background-position-x: 100%;
						}
					}

					&.cancel {
						background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
						background-size: 200% @titleHeight;
						color: white;
						text-shadow: #2c3e50 0 1px 4px;
						box-shadow: #bdc3c766 0 4px 16px;
						transition: background-position-x 400ms;

						&:hover {
							background-position-x: 100%;
						}
					}
				}
			}
		}
	}
</style>
