<template>
	<div class="container">
		<div class="title">
			<div class="text">添加配件</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">零件名称</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="info.part_name">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.part_name_ug">
						<i class="after">维文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="part_name" data-target="part_name_ug">翻译</button>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.part_name_kz">
						<i class="after">哈文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="ka"
							data-source="part_name" data-target="part_name_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">标准名称</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="info.standard_name">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.standard_name_ug">
						<i class="after">维文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="standard_name" data-target="standard_name_ug">翻译</button>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.standard_name_kz">
						<i class="after">哈文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="ka"
							data-source="standard_name" data-target="standard_name_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">工厂名称</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="info.factory_name">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.factory_name_ug">
						<i class="after">维文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="factory_name" data-target="factory_name_ug">翻译</button>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.factory_name_kz">
						<i class="after">哈文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="ka"
							data-source="factory_name" data-target="factory_name_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">说明</div>
				<div class="right">
					<div class="language language-textarea">
						<textarea class="before" v-model="info.remark"></textarea>
						<i class="after">中文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" v-model="info.remark_ug"></textarea>
						<i class="after">维文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="remark" data-target="remark_ug">翻译</button>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" v-model="info.remark_kz"></textarea>
						<i class="after">哈文</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="ka"
							data-source="remark" data-target="remark_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">零件号</div>
				<div class="right">
					<input class="input" v-model="info.part_number" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">配件oe号码</div>
				<div class="right">
					<input class="input" v-model="info.part_number_orgibal" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">数量</div>
				<div class="right">
					<input class="input" v-model="info.part_number_orgibal" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">替换号码</div>
				<div class="right">
					<input class="input" v-model="info.replace_number" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">价格</div>
				<div class="right">
					<input class="input" v-model="info.price" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">图号</div>
				<div class="right">
					<input class="input" v-model="info.callout" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">排序</div>
				<div class="right">
					<input class="input" v-model="info.sortBy" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">是否当前vin</div>
				<div class="right">
					<!--  v-on:changed="info.is_vin=!info.is_vin"  -->
					<switcha :on="info.is_vin"/>
				</div>
			</div>
			<div class="row">
				<div class="left">开始日期</div>
				<div class="right">
					<input class="input language" type="date" v-model="info.begin_date" disabled>
				</div>
			</div>
			<div class="row">
				<div class="left">结束日期</div>
				<div class="right">
					<input class="input" type="date" v-model="info.end_date" disabled>
				</div>
			</div>
			<!-- 			<div class="row">
				<div class="left">配件分解图</div>
				<div class="right">
					<img class="img" src="../../assets/logo.png" >
				</div>
			</div> -->
			<div class="row">
				<div class="left">图片</div>
				<div class="right">
					<img class="img" v-for="(item,index) in info.imgs" :key="index" :src="item.url" :data-url="item.url"
						@click="openPreview">
				</div>
			</div>
			<div class="row">
				<div class="left">图片坐标节点</div>
				<div class="right">
					<div class="hotspots" v-for="(item,index) in info.hotspots" :key="index">
						<div class="callout">{{item.callout}}</div>
						<div class="x-y">({{item.topleft_x}},{{item.topleft_y}})</div>
					</div>
					<!-- <input class="input" v-model="info.hotspots"> -->
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="update">更新</div>
			</div>
		</div>
		<div class="preview" :class="{show:preview.isShow}">
			<img class="preview-img" :style="'transform:rotate('+preview.rotate+'deg)'" :src="preview.url"  @click="preview.rotate+=90">
			<div class="preview-btn">
				<i class="iconfont icon-guanbi" @click="preview.isShow=false"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updatePart
	} from "@/api/parts.js";
	import {
		translate
	} from "@/api/translate.js";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				info: {
					part_number: "",
					part_number_orgibal: "",
					part_name: "a",
					part_name_ug: "",
					part_name_kz: "",
					number: 0,
					standard_name: "",
					standard_name_ug: "",
					standard_name_kz: "",
					factory_name: "",
					factory_name_ug: "",
					factory_name_kz: "",
					remark: "",
					remark_ug: "",
					remark_kz: "",
					replace_number: "",
					price: 0,
					callout: 1,
					sortBy: 1,
					is_vin: true,
					begin_date: "2021-12-07",
					end_date: "2021-12-07",
					illustration_url: "",
					imgs: [],
					hotspots: [{
						callout: "385",
						topleft_x: "12",
						topleft_y: "25"
					}, {
						callout: "420",
						topleft_x: "51",
						topleft_y: "18"
					}],
				},
				info_orginal: {},
				preview: {
					url: "",
					rotate: 0,
					isShow: false
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			isLoad = false;
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
				this.info_orginal = JSON.parse(decodeURIComponent(this.$route.params.info));
			} else {
				this.showToast("获取数据失败")
			}
		},
		methods: {
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (data && 0) {
					isLoad = true;
					updatePart(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					if(data==false){
						this.showToast("没有可更新数据")
					}
				}

			},
			cancel() {
				this.$router.back()
			},
			getChangeData() {
				let data = {
					id:this.info.id
				}
				let keys = Object.keys(this.info);
				for (var i = 0; i < keys.length; i++) {
					if(this.info[keys[i]] != this.info_orginal[keys[i]] && typeof(this.info[keys[i]]) != 'object'){
						data[keys[i]] = this.info[keys[i]]
					}
				}
				console.log(data)
				if(Object.keys(data).length>1){
					return data
				}else {
					return false 
				}
			},
			translate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				translate(this.info[source], to).then((res) => {
					if (res.status == 200) {
						this.info[target] = res.data;
					}
				});
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				this.preview.rotate = 0
				this.preview.url = url;
				this.preview.isShow = true;
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>