import http from '../utils/Http.js'
export function getCityList(data) {
    return http({
        url: 'city/list',
        method: 'GET',
        params: data
    })
}
export function translateCity(id, nameUg, nameKz) {
    let data = {
        id: id,
        nameUg: nameUg,
        nameKz: nameKz
    }
    return http({
        url: 'city/translate',
        method: 'PUT',
        data: data,
    })
}
