import http from '../utils/Http.js'
export function getMsgTemplateList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'msg-template/list',
        method: 'GET',
        params: data
    })
}
export function addMsgTemplate(data) {
    return http({
        url: 'msg-template',
        method: 'POST',
        data: data
    })
}
export function updateMsgTemplate(data) {
    return http({
        url: 'msg-template',
        method: 'PUT',
        data: data
    })
}
export function deleteMsgTemplate(id) {
    return http({
        url: 'msg-template',
        method: 'DELETE',
        data: {id:id}
    })
}