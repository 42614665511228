<template>
	<div class="container">
		<div class="title">
			<div class="text">添加员工</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" :src="info.avatar_url || ''" :onerror="defaultImg" />
					<input class="logo-file" type="file" accept="image/*" :value="logoFile" @change="updateLogo" />
					<span class="logo-tip">修改</span>
				</div>
				<div class="logo-title">Logo</div>
			</div>
			<div class="row">
				<div class="left">姓名</div>
				<div class="right">
					<div class="language">
						<input type="text" class="before" v-model="info.real_name">
						<span class="after">中文</span>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_ug">
						<span class="after">维文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="uy"
							data-source="real_name" data-target="real_name_ug">翻译</div>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_kz">
						<span class="after">哈文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="ka"
							data-source="real_name" data-target="real_name_kz">翻译</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">身份证号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.id_number" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.phone_number" placeholder="">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		uploadFile
	} from "@/api/common";
	import {
		addAgent
	} from "@/api/agent";
	import {
		translate
	} from "@/api/translate.js";
	export default {
		data() {
			return {
				info: {
					real_name: "",
					real_name_ug: "",
					real_name_kz: "",
					id_number: "",
					phone_number: "",
					avatar_url: ""
				},
				logoFile:"",
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {

		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.real_name || !this.info.real_name.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.real_name_ug || !this.info.real_name_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.real_name_kz || !this.info.real_name_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				if (!this.info.id_number || this.info.id_number.length != 18) {
					this.showToast("请正确输入身份证号")
					return
				}
				if (!this.info.phone_number || this.info.phone_number.length != 11) {
					this.showToast("请正确输入手号")
					return
				}
				if (!this.info.avatar_url) {
					this.showToast("请上传头像")
					return
				}
				isLoad = true;
				addAgent(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("添加成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			updateLogo(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info.avatar_url = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			translateDate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				translate(this.info[source], to).then((res) => {
					if (res.status == 200) {
						this.info[target] = res.data;
					}
				});
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
