<template>
	<div class="container">
		<div class="title">
			<div class="text">详细页</div>
		</div>
		<div class="content">
			<div class="logo" v-if="getLogo">
				<div class="logo-box">
					<img class="logo-img" :src="getLogo" >
				</div>
				<div class="text">头像</div>
			</div>
			<div class="row">
				<div class="left">名称</div>
				<div class="right">
					<span class="text">{{getName}}</span>
				</div>
			</div>
			<div class="row" v-if="getMobilePhone">
				<div class="left">手机号</div>
				<div class="right">
					<span class="text">{{getMobilePhone}}</span>
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<span class="text" v-if="info.status==0">未读</span>
					<span class="text" v-if="info.status==1">已读</span>
				</div>
			</div>
			<div class="row">
				<div class="left">日期</div>
				<div class="right">
					<span class="text">{{info.created_at}}</span>
				</div>
			</div>
			<div class="row">
				<div class="left">消息内容</div>
				<div class="right">
					<textarea class="textarea" v-model="info.content" disabled></textarea>
					<textarea class="textarea rtl" v-model="info.content_ug" disabled></textarea>
					<textarea class="textarea rtl" v-model="info.content_kz" disabled></textarea>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item back" @click="cancel">返回</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info: {},
				templateId: "",
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		computed:{
			getLogo(){
				if(this.info.customer){
					return this.info.customer.avatar_url
				}else if(this.info.merchant){
					return this.info.merchant.avatar_url
				}if(this.info.car && this.info.car.item.car.used_car){
					return this.info.car.item.car.thumbnail
				}else {
					return ''
				}
			},
			getName(){
				if(this.info.customer){
					return this.info.customer.real_name
				}else if(this.info.merchant){
					return this.info.merchant.merchant_name
				}if(this.info.car && this.info.car.item.car.used_car){
					return this.info.car.item.car.used_car.vin_name
				}else if(this.info.customer_id==0 && this.info.merchant_id==0){
					return '全部用户'
				}
				return ''
			},
			getMobilePhone(){
				if(this.info.customer){
					return this.info.customer.mobile_phone
				}else if(this.info.merchant){
					return this.info.merchant.mobile_phone
				}if(this.info.car && this.info.car.item.car.used_car){
					return this.info.car.item.car.contact_mobile
				}else {
					return ''
				}
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info))
			} else {
				this.showToast("获取数据失败")
			}
			
		},
		methods: {
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>