<template>
	<div class="container">
		<div class="title">
			<div class="text">汽车系列</div>
		</div>
		<div class="serach">
			<input type="text" class="keyword" v-model="word" />
			<button class="serach-btn" @click="loadSeriesList">搜索</button>
			<div class="iconfont icon-refresh refresh" @click="refresh"></div>
			<div class="status">
				<div class="status-item">
					<div class="text">维语</div>
					<switcha :on="untranslated_ug" v-on:changed="onTranslateUgChanged" />
				</div>
				<div class="status-item">
					<div class="text">哈语</div>
					<switcha :on="untranslated_kz" v-on:changed="onTranslateKzChanged" />
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">品牌</div>
					<div class="col flex-2">名称</div>
					<div class="col flex-2">维文</div>
					<div class="col flex-2">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(series, index) in seriesList" :key="index">
					<div class="col id">{{ series.id }}</div>
					<div class="col flex-1">
						<span v-if="series.brand && series.brand.brand_name">{{ series.brand.brand_name }}</span>
					</div>
					<div class="col flex-2">{{ series.series_name }}</div>
					<div class="col flex-2 rtl-left">{{ series.series_name_ug }}</div>
					<div class="col flex-2 rtl-left">{{ series.series_name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<series-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadSeriesList" />
	</div>
</template>
<script>
	import {
		getCarSeries
	} from "@/api/car";
	import Paginator from "@/components/widgets/Paginator.vue";
	import Switcha from "@/components/widgets/Switch.vue";
	import SeriesTranslateModal from "@/components/car/SeriesTranslateModal.vue";
	export default {
		components: {
			Paginator,
			SeriesTranslateModal,
			Switcha
		},
		data() {
			return {
				word: "",
				routes: [],
				seriesList: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
				untranslated_ug: false,
				untranslated_kz: false,
			};
		},
		created() {
			this.loadSeriesList();
		},
		methods: {
			loadSeriesList() {
				getCarSeries(this.curPage, this.pageSize, this.untranslated_ug,
					this.untranslated_kz,this.word).then((res) => {
					if (res.status == 200) {
						this.seriesList = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			onTranslateUgChanged() {
				this.untranslated_ug = !this.untranslated_ug;

				this.reloadSeriesList();
			},

			onTranslateKzChanged() {
				this.untranslated_kz = !this.untranslated_kz;

				this.reloadSeriesList();
			},
			reloadSeriesList(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadSeriesList();
			},
			refresh() {
				this.curPage = 1;
				this.loadSeriesList();
			},
			onPageChanged(page) {
				this.reloadSeriesList(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.seriesList[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>