<template>
	<div class="container">
		<div class="title">
			<div class="text">汽车零部件</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">零件名称</div>
					<div class="col flex-1">零件号</div>
					<div class="col flex-1">配件oe号码</div>
					<div class="col flex-1">数量</div>
					<div class="col flex-1">标准名称</div>
					<div class="col flex-1">工厂名称</div>
					<div class="col flex-1">替换号码</div>
					<div class="col flex-1">价格</div>
					<div class="col flex-1">图号</div>
					<div class="col flex-1">排序</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{item.id}}
					</div>
					<div class="col flex-1">{{item.part_name}}</div>
					<div class="col flex-1">{{item.part_number}}</div>
					<div class="col flex-1">{{item.part_number_orgibal}}</div>
					<div class="col flex-1">{{item.part_number_orgibal}}</div>
					<div class="col flex-1">{{item.standard_name}}</div>
					<div class="col flex-1">{{item.factory_name}}</div>
					<div class="col flex-1">{{item.replace_number}}</div>
					<div class="col flex-1">{{item.price}}</div>
					<div class="col flex-1">{{item.callout}}</div>
					<div class="col flex-1">{{item.sortBy}}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-title="item.part_name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filter.size" :page="filter.page" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>

<script>
	let standById = 0;
	import {
		getPartList,
		deletePart
	} from "@/api/parts";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox
		},
		data() {
			return {
				dataList: [{
					id:1,
					part_number: "",
					part_number_orgibal: "",
					part_name: "a",
					part_name_ug: "",
					part_name_kz: "",
					number: 0,
					standard_name: "",
					standard_name_ug: "",
					standard_name_kz: "",
					factory_name: "",
					factory_name_ug: "",
					factory_name_kz: "",
					remark: "",
					remark_ug: "",
					remark_kz: "",
					replace_number: "",
					price: 0,
					callout: 1,
					sortBy: 1,
					is_vin: true,
					begin_date: "2021-12-07",
					end_date: "2021-12-07",
					illustration_url: "",
					imgs: [{
						url: "http://imagecn.gasgoo.com/moblogo/News/UEditor/600-X/20170212/6362253742040800002904501.jpg"
					}, {
						url: "http://imagecn.gasgoo.com/moblogo/News/UEditor/600-X/20170212/6362253742040800002904501.jpg"
					}, {
						url: "http://imagecn.gasgoo.com/moblogo/News/UEditor/600-X/20170212/6362253742040800002904501.jpg"
					}, {
						url: "http://imagecn.gasgoo.com/moblogo/News/UEditor/600-X/20170212/6362253742040800002904501.jpg"
					}, {
						url: "http://imagecn.gasgoo.com/moblogo/News/UEditor/600-X/20170212/6362253742040800002904501.jpg"
					}],
					hotspots: [{
						callout: "385",
						topleft_x: "12",
						topleft_y: "25"
					}, {
						callout: "420",
						topleft_x: "51",
						topleft_y: "18"
					}, {
						callout: "420",
						topleft_x: "51",
						topleft_y: "18"
					}, {
						callout: "420",
						topleft_x: "51",
						topleft_y: "18"
					}, {
						callout: "420",
						topleft_x: "51",
						topleft_y: "18"
					}],
				}],
				filter: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "PartsPartAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filter.page = page;
				}
				// this.loadData();//加载数据
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "PartsPartUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				getPartList(this.filter).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deletePart(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>