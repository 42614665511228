import http from '../utils/Http.js'
export function getMenuList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'menus',
        method: 'GET',
        params: data
    })
}

export function getTopLevelMenus(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'top-menus',
        method: 'GET',
        params: data
    })
}


export function createMenu(data) {
    return http({
        url: 'menu',
        method: 'POST',
        data: data,
    })
}

export function updateMenu(data) {
    return http({
        url: 'menu',
        method: 'PUT',
        data: data,
    })
}

export function daleteMenu(id) {
    return http({
        url: 'menu',
        method: 'DELETE',
        data: {
            id: id
        },
    })
}