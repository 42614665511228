import http from '../utils/Http.js'

export function getAgentList(page = 1, size) {
	let data = {
		page: page,
		size: size
	}

	return http({
		url: 'elghar-agents',
		method: 'GET',
		params: data
	})
}

export function getAgent(id) {
	return http({
		url: 'elghar-agent/' + id,
		method: 'GET'
	})
}

export function addAgent(data) {

	return http({
		url: 'elghar-agent',
		method: 'POST',
		params: data
	})
}

export function updateAgent(data) {

	return http({
		url: 'elghar-agent',
		method: 'PUT',
		params: data
	})
}

export function deleteAgent(id) {

	return http({
		url: 'elghar-agent/'+id,
		method: 'DELETE'
	})
}

export function getMakePoster(id, lang = 'ug') {

	return http({
		url: 'elghar-agent/make-poster/' + id + ',' + lang,
		method: 'GET'
	})
}
