import http from '../utils/Http'
export function getAbout() {
    return http({
        url: 'about',
        method: 'GET',
    })
}
export function updateAbout(data) {
	return http({
		url: 'about',
		method: 'PUT',
		data: data
	})
}