<template>
	<div class="container">
		<div class="title">
			<div class="text">车辆加装配置</div>
			<div class="meta-functions">
				<div class="function-item add" @click="addAttach">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">图片</div>
					<div class="col flex-1">配置名称</div>
					<div class="col flex-1 rtl-left">维文名称</div>
					<div class="col flex-1 rtl-left">哈文名称</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in attach" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col avatar">
						<img class="avatar-img no-radius" :src="item.icon_url" alt="" />
					</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showModal">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-name="item.name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<add-modal ref="addModal" :info="infoToAttach" v-on:confirm="reloadCarAttach" />
		<update-modal ref="UpdateModal" :info="infoToAttach" v-on:confirm="reloadCarAttach" />
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="deleteConfirm" />
	</div>
</template>
<script>
	import {
		getCarAttachmentList,
		daleteAttach
	} from "@/api/car-attach";
	import AddModal from "@/components/car-attachment/CarAttachAdd.vue";
	import UpdateModal from "@/components/car-attachment/CarAttachUpdate.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	import DialogBox from "@/components/widgets/DialogBox";
	let byId = 0;
	export default {
		components: {
			AddModal,
			UpdateModal,
			Paginator,
			DialogBox,
		},
		data() {
			return {
				attach: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToAttach: null,
				dialogContent: null,
			};
		},
		mounted() {
			this.loadCarAttach();
		},
		methods: {
			loadCarAttach() {
				getCarAttachmentList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.attach = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadCarAttach(page = 1) {
				this.curPage = page;
				this.loadCarAttach();
			},
			onPageChanged(page) {
				this.reloadCarAttach(page);
			},
			addAttach() {
				this.$refs.addModal.show();
			},
			showConfirmDialog(e) {
				let dataset = e.currentTarget.dataset;
				let dialogContent = "确定要删除《" + dataset.name + "》吗？";
				byId = dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			deleteConfirm() {
				if (byId == 0) return;
				daleteAttach(byId).then((res) => {
					if (res.status === 200) {
						this.reloadCarAttach();
					}
				});
			},

			showModal(e) {
				if (typeof e == "undefined") return;
				let index = e.currentTarget.dataset.index;
				let obj = this.attach[index];
				this.infoToAttach = JSON.parse(JSON.stringify(obj));
				this.$refs.UpdateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>