<template>
	<div class="container">
		<div class="title">
			<div class="text">系统日志-详细页</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">数据库名</div>
				<div class="right">
					<div class="text">{{info.goodsName}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">操作员名称</div>
				<div class="right">
					<div class="text">{{info.real_name}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">操作类别</div>
				<div class="right">
					<div class="text">{{info.type}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">序号</div>
				<div class="right">
					<div class="text">{{info.top}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">操作ip</div>
				<div class="right">
					<div class="text">{{info.ip}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">创建时间</div>
				<div class="right">
					<div class="text">{{info.time}}</div>
				</div>
			</div>
			<div class="row">
				<div class="left">详细内容</div>
				<div class="right">
					<textarea class="textarea" v-model="info.detail" disabled></textarea>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="back">返回</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				info: {
					goodsName: "database",
					real_name:"阿里木江",
					type:"修改",
					top:123,
					ip:"",
					time:"2021-12-12",
					detail:"内容"
				}
			}
		},
		created() {
			let data = this.$route.params.info;
			if(data){
				this.info = JSON.parse(decodeURIComponent(data))
			}
		},
		methods: {
			back() {
				this.$router.back()
			}
		}
	}
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
