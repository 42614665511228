import http from '../utils/Http.js'
export function getProvincesList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'province/list',
        method: 'GET',
        params: data
    })
}

export function translateProvinces(id, nameUg, nameKz) {
    let data = {
        id: id,
        nameUg: nameUg,
        nameKz: nameKz
    }
    return http({
        url: 'province/translate',
        method: 'PUT',
        data: data,
    })
}