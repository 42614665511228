<template>
	<div class="container">
		<div class="title">
			<div class="text">添加用户</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<input class="logo-file" type="file" accept="image/*" :value="avatarFile" @change="updateAvatar" />
					<img class="logo-img" :src="user.avatar" />
					<span class="logo-title">修改</span>
				</div>
				<div class="text">头像</div>
			</div>
			<div class="row">
				<div class="left">用户名：</div>
				<div class="right">
					<input type="text" class="input" v-model="user.real_name" placeholder="请输入名字"/>
				</div>
			</div>
			<div class="row">
				<div class="left">手机号：</div>
				<div class="right">
					<input type="text" class="input" v-model="user.usr" placeholder="请输入手机号"/>
				</div>
			</div>
			<div class="row">
				<div class="left">密码：</div>
				<div class="right">
					<input type="password" class="input" v-model="user.pwd" placeholder="请输入密码"/>
				</div>
			</div>
			<div class="row">
				<div class="left">状态：</div>
				<div class="right">
					<span class="tag" :class="{ selected: item.status == user.status }"
						v-for="(item, statusIndex) in status" :key="statusIndex" :data-status="item.status"
						@click="user.status=item.status">{{ item.title }}</span>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;

	import {
		uploadFile
	} from "@/api/common";

	import {
		addUser
	} from "@/api/admin-user";
	export default {
		data() {
			return {
				user: {
					avatar: "https://pic1.zhimg.com/80/v2-82fbd4e910229864eb7b67f1516c7dbd_720w.jpg?source=1940ef5c",
					real_name: "",
					usr: "",
					pwd: "",
					status: 1,
				},
				avatarFile: "",
				toastText: "请正确输入参数",
				isShowToast: false,
				status: [{
						"status": 1,
						"title": "正常"
					},
					{
						"status": 2,
						"title": "审核中"
					},
					{
						"status": 3,
						"title": "禁用"
					},
				],
				//图片剪裁组件参数
				showMyUpload: false,
				params: {
					image: "123.png",
				},
				headers: {
					smail: "*_~",
				},
			};
		},
		methods: {
			addRole(e) {
				//添加角色
				let index = Number(e.currentTarget.dataset.index);
				if (this.user.role.includes(index)) {
					this.user.role.splice(this.user.role.indexOf(index), 1);
				} else {
					this.user.role.push(index);
				}
			},
			cancel() {
				//取消按钮
				this.$router.back();
			},
			add() {
				//添加按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (this.user.avatar.trim().indexOf("http") != 0) {
					this.showToast("请上传用户头像");
					return;
				}
				if (this.user.real_name.trim().length < 2) {
					this.showToast("用户昵称太短了！");
					return;
				}
				if (this.user.usr.trim().length != 11) {
					this.showToast("手机号格式不正确！");
					return;
				}
				if (this.user.pwd.trim().length < 5) {
					this.showToast("密码太短了！");
					return;
				}

				//发送请求
				isLoad = true;
				addUser(this.user).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				})
			},
			updateAvatar(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.user.avatar = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>