<template>
	<div class="container">
		<div class="title">
			<div class="text">用户信息</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.real_name}">
					<label class="text">姓名</label>
					<input class="input" type="text" v-model="filter.real_name" placeholder="请输入用户名称..."/>
				</div>
				<div class="list-item" :class="{on:filterData.mobile_phone}">
					<label class="text">手机号</label>
					<input class="input" type="text" v-model="filter.mobile_phone" placeholder="请输入手机号..."/>
				</div>
				<div class="list-item" :class="{on:filterData.id_car_no}">
					<label class="text">身份证号</label>
					<input class="input" type="text" v-model="filter.id_car_no" placeholder="请输入身份证号..."/>
				</div>
				<div class="list-item" :class="{on:filterData.gender}">
					<label class="text">性别</label>
					<select class="select-mini" v-model="filter.gender">
						<option value="-1">请选择</option>
						<option value="1">男</option>
						<option value="2">女</option>
					</select>
				</div>
				<!-- 选择省份-城市 -->
				<div class="list-item" :class="{on:filterData.city_id*1>=0}">
					<label class="text">城市</label>
					<select class="select" value="-1" v-model="filter.province_id" @change="changeCity">
						<option :value="-1">请选择</option>
						<option :value="index" v-for="(item,index) in provinceList" :key="index">{{item.name}}</option>
					</select>
					<select class="select" value="0" v-model="filter.city_id">
						<option :value="-1">请选择</option>
						<option :value="index" v-for="(item,index) in cityList" :key="index">{{item.name}}</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.verification_status>=0}">
					<label class="text">认证状态</label>
					<select class="select-mini" v-model="filter.verification_status">
						<option :value="-1">请选择</option>
						<option :value="0">未申请</option>
						<option :value="1">审核通过</option>
						<option :value="2">待审核</option>
						<option :value="3">未通过</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.status>=0}">
					<label class="text">账号状态</label>
					<select class="select-mini" v-model="filter.status">
						<option :value="-1">请选择</option>
						<option :value="0">关闭</option>
						<option :value="1">正常</option>
						<option :value="2">封号</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadCustomers">搜索</div>
				</div>
			</div>
		</div>
		
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">头像</div>
					<div class="col flex-1">用户姓名</div>
					<div class="col flex-1">手机号码</div>
					<div class="col flex-1">身份证号码</div>
					<div class="col flex-midle">性别</div>
					<div class="col flex-midle">认证状态</div>
					<div class="col flex-midle">账状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in customers" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col avatar">
						<img class="avatar-img" :src="item.avatar_url || ''" :alt="item.real_name"  :onerror="defaultImg"/>
					</div>
					<div class="col flex-1">{{ item.real_name }}</div>
					<div class="col flex-1">{{ item.mobile_phone }}</div>
					<div class="col flex-1">{{ item.id_car_no }}</div>
					<div class="col flex-midle">{{ item.gender }}</div>
					<div class="col flex-midle">
						<span v-if="item.verification_status==0">未申请</span>
						<span v-if="item.verification_status==1">审核通过</span>
						<span v-if="item.verification_status==2">待审核</span>
						<span v-if="item.verification_status==3">未通过</span>
					</div>
					<div class="col flex-midle">
						<span v-if="item.status==0">关闭</span>
						<span v-if="item.status==1">正常</span>
						<span v-if="item.status==2">封号</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="翻译" :data-index="index"
							@click="showTranslateModal">
							<i class="iconfont icon-fanyi-full"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :notLoadData="true" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
		<customer-modal ref="CustomerModal" :info="infoToCustomer" v-on:confirm="relloadCustomers" />
		<customer-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="relloadCustomers" />
	</div>
</template>
<script>
	let store = null;
	import { useStore } from 'vuex'
	import {
		getCustomerList
	} from "@/api/customer";
	import {
		getProvince,
		getCity
	} from "@/api/common";
	import CustomerModal from "@/components/customer/CustomerModal.vue";
	import CustomerTranslateModal from "@/components/customer/CustomerTranslateModal.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			CustomerModal,
			CustomerTranslateModal,
			Paginator,
		},
		data() {
			return {
				customers: [],
				totalRecords: 0,
				infoToCustomer: null,
				infoToTranslate: null,
				provinceList: [],
				cityList: [],
				filter: {
					real_name: "",
					mobile_phone: "",
					id_car_no: "",
					gender: "-1",
					status: -1,
					verification_status:-1,
					province_id: -1,
					city_id: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				defaultImg:"this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			};
		},
		created() {
			store = useStore();
			if(store.getters.getPagination.name == this.$route.name){
				this.filterData.page = store.getters.getPagination.curPage;
			}
			this.loadCustomers();
			this.getProvinceData()
		},
		methods: {
			loadCustomers() {
				this.customers = []
				this.filterData = this.getFilterData();
				getCustomerList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.customers = res.data.data;
						this.totalRecords = res.data.total;
						
						store.dispatch('updatePagination',{
							name:this.$route.name,
							curPage:this.filterData.page
						})
					}
				});
			},
			getProvinceData() {
				getProvince().then(res => {
					if (res.status == 200) {
						this.provinceList = res.data;
						setTimeout(()=>{
							this.filter.province_id = 30;
							this.getCityData(this.provinceList[30].area_code)
						},300)
					}
				})
			},
			getCityData(code) {
				getCity(code).then(res => {
					if (res.status == 200) {
						this.cityList = res.data
					}
				})
			},
			changeCity(e) {
				let index = e.target.value;
				this.cityList = []
				this.filter.city_id = -1;
				if(index!=-1){
					this.getCityData(this.provinceList[index * 1].area_code)
				}
			},
			getFilterData() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size
				}
				if (this.filter.real_name.trim().length > 1) {
					data = {
						...data,
						real_name: this.filter.real_name.trim()
					}
				}
				if (this.filter.mobile_phone.trim() * 1) {
					data = {
						...data,
						mobile_phone: this.filter.mobile_phone.trim()
					}
				}
				if (this.filter.id_car_no.trim().length > 1) {
					data = {
						...data,
						id_car_no: this.filter.id_car_no.trim()
					}
				}
				if (this.filter.gender * 1 != -1) {
					data = {
						...data,
						gender: this.filter.gender
					}
				}
				if (this.filter.status * 1 != -1) {
					data = {
						...data,
						status: this.filter.status*1
					}
				}
				if (this.filter.verification_status * 1 != -1) {
					data = {
						...data,
						verification_status: this.filter.verification_status*1
					}
				}
				if (this.filter.city_id * 1 != -1) {
					data = {
						...data,
						province_id: this.filter.province_id * 1,
						city_id: this.filter.city_id * 1
					}
				}
				if(Object.values(data).sort().toString()!=Object.values(this.filterData).sort().toString()){
					data.page = 1;
				}
				return data
			},
			relloadCustomers(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadCustomers();
			},
			onPageChanged(page) {
				this.relloadCustomers(page);
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showModal(e) {
				if (typeof e == undefined) return;
				let index = e.currentTarget.dataset.index;
				this.infoToCustomer = JSON.parse(JSON.stringify(this.customers[index]));
				this.$refs.CustomerModal.show();
			},
			toEditPage(e){
				let index = e.currentTarget.dataset.index;
				this.$router.push({
					name:"CustomerUpdate",
					params:{
						info:encodeURIComponent(JSON.stringify(this.customers[index*1]))
					}
				})
			},
			showTranslateModal(e) {
				if (typeof e == undefined) return;
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.customers[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
	
	@operationWidth:140px;
</style>
