<template>
	<div class="container">
		<div class="title">
			<div class="text">词库分类</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">中文名称</div>
					<div class="col flex-1">维文名称</div>
					<div class="col flex-1">哈文名称</div>
					<div class="col flex-1">状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in dataList" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.category_name }}</div>
					<div class="col flex-1">{{ item.category_name_ug }}</div>
					<div class="col flex-1">{{ item.category_name_kz }}</div>
					<div class="col flex-1">
						<span v-if="item.status==0">
							隐藏
						</span>
						<span v-if="item.status==1">
							显示
						</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-title="item.category_name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :notLoadData="true" :page="filterData.page" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>

<script>
	let standById = 0;
	let store = null;
	import { useStore } from 'vuex'
	import {
		getLexiconSortList,
		deleteLexiconSort
	} from "@/api/lexicon";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox,
		},
		data() {
			return {
				dataList: [],
				filter: {
					status: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		mounted() {
			store = useStore();
			if(store.getters.getPagination.name == this.$route.name){
				this.filterData.page = store.getters.getPagination.curPage;
			}
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "LexiconSortAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "LexiconSortUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size
				}
				if(this.filter.status != -1){
					data["status"] = this.filter.status;
				}
				this.filterData = data
				getLexiconSortList(data).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total
						
						store.dispatch('updatePagination',{
							name:this.$route.name,
							curPage:this.filterData.page
						})
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteLexiconSort(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
			rTime(e){
				let json_date = new Date(e).toJSON();
				return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
