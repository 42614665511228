import http from '../utils/Http.js'
export function getUserList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'admins',
        method: 'GET',
        params: data
    })
}
export function addUser(data) {
    return http({
        url: 'admin',
        method: 'POST',
        data: data
    })
}
export function getUser(id) {
    return http({
        url: 'admin/'+id,
        method: 'GET',
    })
}
export function updateUser(id,data) {
    return http({
        url: 'admin/'+id,
        method: 'PUT',
        data: data
    })
}
export function deleteUser(id) {
    return http({
        url: 'admin/'+id,
        method: 'DELETE',
        data: {
			id:id
		}
    })
}