<template>
	<div class="container">
		<div class="row">
			<div class="title">数据统计</div>
			<div class="content">
				<div class="col" @click="toUsedCarPage">
					<div class="left">
						<i class="iconfont icon-qiche"></i>
					</div>
					<div class="right">
						<div class="text">待审核二手车</div>
						<div class="data">{{dash.waitUsedCar}}</div>
					</div>
				</div>
				<div class="col" data-filter="status" @click="toMerchantPage">
					<div class="left">
						<i class="iconfont icon-shenhe1"></i>
					</div>
					<div class="right">
						<div class="text">待审核商家</div>
						<div class="data">{{dash.waitMerchant}}</div>
					</div>
				</div>
				<div class="col" data-filter="all" @click="toMerchantPage">
					<div class="left">
						<i class="iconfont icon-shangjia1"></i>
					</div>
					<div class="right">
						<div class="text">总商家</div>
						<div class="data">{{dash.merchant}}</div>
					</div>
				</div>
				<div class="col" @click="toCustomerPage">
					<div class="left">
						<i class="iconfont icon-yonghuguanli_huaban"></i>
					</div>
					<div class="right">
						<div class="text">普通用户</div>
						<div class="data">{{dash.customer}}</div>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="title">翻译排行</div>
			<div class="content">
				<div class="col">
					<div class="users">
						<div class="user" v-for="(item,index) in rank" :key="index">
							<div class="index">{{index+1}}.</div>
							<img class="avatar" type="image" :src="item.avatar" />
							<div class="name">{{item.real_name}}</div>
							<div class="count">
								<span v-if="item.count<10000">{{item.count}}</span>
								<span v-if="item.count>10000">{{(item.count/10000).toFixed(1)}}w</span>
							</div>
						</div>
						<!-- 占多余的空间 -->
						<div class="user opacity-0">1</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getDash,
		getRank
	} from "@/api/dash.js";
	export default {
		data() {
			return {
				dash: {},
				rank: {}
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			toUsedCarPage() {
				let data = {
					status: 0
				}
				this.$router.push({
					name: "UsedCarList",
					params: {
						filterData: encodeURIComponent(JSON.stringify(data))
					}
				})
			},
			toMerchantPage(e) {
				let filter = e.currentTarget.dataset.filter;
				let data = {
					name: "MerchantList"
				}
				if (filter == 'status') {
					data['params'] = {
						filterData: encodeURIComponent(JSON.stringify({
							verification_status: 0
						}))
					}
				}
				this.$router.push(data)
			},
			toCustomerPage() {
				this.$router.push({
					name: "CustomerList"
				})
			},
			loadData() {
				getDash().then(res => {
					if (res.status == 200) {
						this.dash = res.data
					}
				})
				getRank().then(res => {
					if (res.status == 200) {
						this.rank = res.data
					}
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.container {
		margin: 24px 32px;
		min-height: 200px;

		.row {
			padding: 20px 16px;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 20px;
			box-shadow: 0 2px 3px #dbdbdb;

			.title {
				margin-bottom: 16px;
			}

			.content {
				padding: 0 16px;
				display: flex;
				justify-content: space-between;
				align-items: center;

				.left,
				.right {
					display: inline-block;
					cursor: pointer;
				}

				.left {
					.iconfont {
						width: 60px;
						height: 60px;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 28px;
						color: #2492e4;
						padding: 8px;
						background-color: #f1f1f1;
						box-sizing: border-box;
						border-radius: 100%;
					}
				}

				.right {
					margin-left: 15px;

					.text {
						color: #000;
						font-size: 16px;
						font-weight: bold;
					}

					.data {
						font-weight: bold;
						font-size: 18px;
						color: #f22e00;
						text-align: center;
					}
				}

				.users {
					padding: 5px 10px;
					display: flex;
					justify-content: space-between;
					flex-wrap: wrap;
				}

				.user {
					width: 300px;
					background-color: #f6f7f9;
					border-radius: 5px;
					height: 40px;
					line-height: 40px;
					margin: 10px;
					display: inline-block;
					position: relative;
					color: #484848;

					.index {
						position: absolute;
						left: 8px;
						top: 5px;
						height: 30px;
						line-height: 30px;
					}

					.avatar {
						position: absolute;
						left: 25px;
						top: 5px;
						width: 30px;
						height: 30px;
						border-radius: 100%;
					}

					.name {
						position: absolute;
						left: 70px;
						top: 5px;
						height: 30px;
						line-height: 30px;
					}

					.count {
						position: absolute;
						right: 8px;
						top: 5px;
						height: 30px;
						line-height: 30px;
						color: #f12711;
					}
				}

				.opacity-0 {
					//占多余的空间
					opacity: 0;
					flex-basis: auto;
				}
			}
		}

	}
</style>
