<template>
	<div class="container">
		<div class="title">
			<div class="text">修改商家信息</div>
		</div>
		<div class="content">
			<div class="tips" v-if="contrast.verification_status*1==2">不可修改！</div>
			<div class="row">
				<div class="left">商户名称</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="merchant.merchant_name">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="merchant.merchant_name_ug">
						<i class="after">维语</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="merchant_name" data-target="merchant_name_ug">翻译</button>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="merchant.merchant_name_kz">
						<i class="after">哈语</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="ka"
							data-source="merchant_name" data-target="merchant_name_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">商户地址</div>
				<div class="right">
					<div class="language language-textarea">
						<textarea class="before" v-model="merchant.address"></textarea>
						<i class="after">中文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="merchant.address_ug"></textarea>
						<i class="after">维语</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="address" data-target="address_ug">翻译</button>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="merchant.address_kz"></textarea>
						<i class="after">哈语</i>
						<button class="after translate-btn" type="button" @click="translate" data-to="uy"
							data-source="address" data-target="address_kz">翻译</button>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">姓名</div>
				<div class="right">
					<input type="text" class="input" v-model="merchant.real_name" />
				</div>
			</div>
			<div class="row">
				<div class="left">身份证号码</div>
				<div class="right">
					<input type="text" class="input" v-model="merchant.id_car_no" />
				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<input type="text" class="input" v-model="merchant.mobile_phone" />
				</div>
			</div>
			<div class="row">
				<div class="left">纬度</div>
				<div class="right">
					<input type="text" class="input" v-model="merchant.latitude" />
				</div>
			</div>
			<div class="row">
				<div class="left">经度</div>
				<div class="right">
					<input type="text" class="input" v-model="merchant.longitude" />
				</div>
			</div>
			<div class="row">
				<div class="left">账号状态</div>
				<div class="right">
					<select class="select" v-model="merchant.status" @change="selected($event)">
						<option value="-1">请选择</option>
						<option value="0">关闭</option>
						<option value="1">正常</option>
						<option value="2">封号</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">认证状态</div>
				<div class="right">
					<select class="select" v-model="merchant.verification_status" @change="selected($event)">
						<option :value="-1">请选择</option>
						<option :value="1">审核通过</option>
						<option :value="0">待审核</option>
						<option :value="2">未通过</option>
						<option :value="3">更新待审核</option>
					</select>
				</div>
			</div>
			<div class="row" v-if="merchant.verification_status*1==2">
				<div class="left">选择原因</div>
				<div class="right">
					<select class="select" @change="changeMsgTemplate">
						<option :value="-1">请选择原因</option>
						<option :value="index" v-for="(item,index) in msg_template" :key="index">{{item.title}}</option>
					</select>
				</div>
			</div>
			<div class="row" v-if="merchant.verification_status*1==2">
				<div class="left">未通过原因</div>
				<div class="right">
					<textarea class="textarea" v-model="template_content" disabled></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">商家logo</div>
				<div class="right">
					<img :src="merchant.avatar_url" alt="" class="img radius" :data-url="merchant.avatar_url"
						@click="openPreview" />
				</div>
			</div>
			<div class="row">
				<div class="left">营业执照</div>
				<div class="right">
					<img :src="merchant.business_license" alt="" class="img" :data-url="merchant.business_license"
						:data-tool="true" @click="openPreview" />
				</div>
			</div>
			<div class="row">
				<div class="left">环境图片</div>
				<div class="right">
					<img :src="item" class="img" v-for="(item, index) in photo" :data-url="item" @click="openPreview"
						:key="index" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="dismiss">取消</div>
				<div class="btn-item add" type="submit" @click="save">更新</div>
			</div>
		</div>
		<div class="preview" :class="{show:preview.isShow}">
			<img class="preview-img" :style="'transform:rotate('+preview.rotate+'deg)'" :src="preview.url" alt="">
			<div class="loadingText" v-if="loading">识别中，请稍等...</div>
			<div class="preview-btn">
				<i class="iconfont icon-wulianwang-" v-if="preview.isTool" @click="getOrcTextData"></i>
				<i class="iconfont icon-guanbi" @click="preview.isShow=false"></i>
				<i class="iconfont icon-xuanzhuan" v-if="preview.isTool" @click="preview.rotate+=90"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>
<script>
	import {
		updateMerchant,
		getOrcText
	} from "@/api/merchant.js";
	import {
		getMsgTemplateList
	} from "@/api/msg-template";
	import {
		translate
	} from "@/api/translate.js";

	export default {
		watch: {
			merchant(val) {
				if (val === null) return;
				this.merchant = val;
				var photo = val.main_photo.split(",");
				this.photo = photo;
			},
		},
		data() {
			return {
				msg_template: {},
				template_id: -1,
				template_content: "",
				status: -1,
				photo: [],
				merchant: {},
				contrast: {
					real_name: "",
					address: "",
					address_ug: "",
					address_kz: "",
					merchant_name: "",
					merchant_name_ug: "",
					merchant_name_kz: "",
					verification_status: "",
					status: ""
				},
				preview: {
					url: "",
					isTool: false,
					rotate: 0,
					isShow: false
				},
				previewUrl: "",
				previewTool: false,
				rotate: 0,
				showPreview: false,
				shown: false,
				fading: false,
				loading: false,
				uploadsUrl: "",
				toastText: "请正确输入参数",
				isShowToast: false,
			};
		},
		created() {
			if (this.$route.params.info) {
				this.merchant = JSON.parse(decodeURIComponent(this.$route.params.info));
				this.contrast.real_name = this.merchant.real_name;
				this.contrast.address = this.merchant.address;
				this.contrast.address_ug = this.merchant.address_ug;
				this.contrast.address_kz = this.merchant.address_kz;
				this.contrast.merchant_name = this.merchant.merchant_name;
				this.contrast.merchant_name_ug = this.merchant.merchant_name_ug;
				this.contrast.merchant_name_kz = this.merchant.merchant_name_kz;
				this.contrast.verification_status = this.merchant.verification_status;
				this.contrast.status = this.merchant.status;
				this.getMsgTemplateData()
			}

		},
		methods: {
			show() {
				this.shown = true;
				setTimeout(() => {
					this.fading = true;
				}, 10);
			},
			dismiss() {
				this.$router.back()
			},
			confirm() {
				this.$emit("confirm");
				this.dismiss();
			},
			selected(e) {
				this.status = e.target.value;
			},
			changeMsgTemplate(e) {
				let index = e.target.value;
				if (index == -1) {
					this.template_id = -1;
					this.template_content = ""
				} else if (index < this.msg_template.length) {
					this.template_id = this.msg_template[index].id
					this.template_content = this.msg_template[index].content
				}
			},
			save() {
				if (this.loading) return;
				let data = this.getUpdateData();
				// if(this.contrast.verification_status==2){
				// 	this.showToast("不可修改！")
				// 	return
				// }
				if (data) {
					this.loading = true;
					updateMerchant(data).then((res) => {
						if (res.status == 200) {
							//发送请求
							this.showToast("已更新")
							setTimeout(() => {
								this.$router.back()
							}, 300)
						} else {
							this.showToast("失败")
						}
						this.loading = false;
					}).catch(() => {
						this.showToast("出错了！")
					})
				} else {
					console.log("没更新数据")
				}

			},
			getUpdateData() {
				let data = {
					id: this.merchant.id
				}
				if (this.contrast.real_name != this.merchant.real_name) {
					data = {
						...data,
						real_name: this.merchant.real_name
					}
				}
				if (this.contrast.address != this.merchant.address) {
					data = {
						...data,
						address: this.merchant.address
					}
				}
				if (this.contrast.address_ug != this.merchant.address_ug) {
					data = {
						...data,
						address_ug: this.merchant.address_ug
					}
				}
				if (this.contrast.address_kz != this.merchant.address_kz) {
					data = {
						...data,
						address_kz: this.merchant.address_kz
					}
				}
				if (this.contrast.merchant_name != this.merchant.merchant_name) {
					data = {
						...data,
						merchant_name: this.merchant.merchant_name
					}
				}
				if (this.contrast.merchant_name_ug != this.merchant.merchant_name_ug) {
					data = {
						...data,
						merchant_name_ug: this.merchant.merchant_name_ug
					}
				}
				if (this.contrast.merchant_name_kz != this.merchant.merchant_name_kz) {
					data = {
						...data,
						merchant_name_kz: this.merchant.merchant_name_kz
					}
				}
				if (this.contrast.verification_status != this.merchant.verification_status) {
					if (this.merchant.verification_status == 2) {
						if (this.template_id == -1) {
							this.showToast("请选择不通过原因");
							return false;
						} else {
							data = {
								...data,
								verification_status: this.merchant.verification_status * 1,
								template_id: this.template_id
							}
						}

					} else {
						data = {
							...data,
							verification_status: this.merchant.verification_status * 1
						}
					}
					let a = Boolean(this.merchant.merchant_name)
					let b = Boolean(this.merchant.merchant_name_ug)
					let c = Boolean(this.merchant.merchant_name_kz)
					if (this.merchant.verification_status == 1 && !(a && b && c)) {
						this.showToast("商户名称没有翻译完成");
						return;
					}
					a = Boolean(this.merchant.address)
					b = Boolean(this.merchant.address_ug)
					c = Boolean(this.merchant.address_kz)
					if (this.merchant.verification_status == 1 && !(a && b && c)) {
						this.showToast("商户地址没有翻译完成");
						return;
					}
				}
				if (this.contrast.status != this.merchant.status) {
					data = {
						...data,
						status: this.merchant.status * 1
					}
				}
				if (Object.keys(data).length > 1) {
					return data
				} else {
					return false
				}
			},
			getMsgTemplateData() {
				getMsgTemplateList(1, 20).then(res => {
					if (res.status == 200) {
						this.msg_template = res.data.data
					}
				})
			},
			getOrcTextData() {
				let data = {
					file: "uploads/" + this.preview.url.split("/")[this.preview.url.split("/").length - 1]
				}
				if (this.loading) return;
				this.loading = true;
				getOrcText(data).then(res => {
					this.loading = false;
					if (res.status == 200) {
						let data = res.data;
						this.merchant.merchant_name = data.biz_license_company_name;
						this.merchant.real_name = data.biz_license_owner_name;
						this.merchant.address = data.biz_license_address;
						this.showToast("数据已填入");
						setTimeout(() => {
							this.showPreview = false;
						}, 200)
					} else {
						this.showToast("失败")
					}
				}).catch(err => {
					console.log(err)
					this.showToast("出错了！")
					this.loading = false;
				})
			},
			translate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				translate(this.merchant[source], to).then((res) => {
					if (res.status == 200) {
						this.merchant[target] = res.data;
					}
				});
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				let isTool = e.currentTarget.dataset.tool ? true : false
				this.preview.url = url;
				this.preview.isTool = isTool;
				this.preview.isShow = true;
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	// @rightWidth: 500px;
</style>
