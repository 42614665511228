<template>
	<div class="container">
		<div class="title">
			<div class="text">举报列表</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.status>=0}">
					<label class="text">状态</label>
					<select class="select-mini" v-model="filter.status">
						<option :value="-1">请选择</option>
						<option :value="0">未处理</option>
						<option :value="1">受理中</option>
						<option :value="4">已处理</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadData">搜索</div>
				</div>
			</div>
		</div>
		
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-2">收到举报的人</div>
					<div class="col flex-2">收到举报的二手车</div>
					<div class="col flex-2">联系方式</div>
					<div class="col flex-2">举报描述</div>
					<div class="col flex-1">状态</div>
					<div class="col flex-2">更新时间</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{item.id}}
					</div>
					<div class="col flex-2">
						<span v-if="item.defendant_merchant">{{item.defendant_merchant.merchant_name}}</span>
						<span v-else-if="item.defendant_customer">{{item.defendant_customer.real_name}}</span>
					</div>
					<div class="col flex-2">
						<button type="button" class="min-btn" :data-id="item.used_car_id" @click="toUsedCar">查看</button>
					</div>
					<div class="col flex-2">
						<span v-if="item.defendant_merchant" :data-phone="item.defendant_merchant.mobile_phone" @click="toMerchantList">{{item.defendant_merchant.mobile_phone}}</span>
						<span v-else-if="item.defendant_customer" :data-phone="item.defendant_customer.mobile_phone" @click="toCustomerList">{{item.defendant_customer.mobile_phone}}</span>
					</div>
					<div class="col flex-2">
						{{item.content.name}}
					</div>
					<div class="col flex-1">
						<span v-if="item.status==0">未处理</span>
						<span v-if="item.status==1">受理中</span>
						<span v-if="item.status==2">处罚</span>
						<span v-if="item.status==3">不处罚</span>
						<span v-if="item.status==4">已处理</span>
					</div>
					<div class="col flex-2" :data-time="item.updated_at">
						{{rTime(item.updated_at)}}
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRoportLogList
	} from "@/api/report";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator
		},
		data() {
			return {
				dataList: [],
				filter: {
					status: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created(){
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "ReportOptionAdd"
				});
			},
			reload(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reload(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "ReportLogUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			loadData() {
				if(this.filter.status == -1){
					this.filterData = {
						page: this.filterData.page,
						size: this.filterData.size
					}
				}else {
					this.filterData["status"] = this.filter.status;
				}
				getRoportLogList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						// this.totalRecords = res.data.total
						this.totalRecords = 1
					}
				});
			},
			toUsedCar(e){
				let id = Number(e.currentTarget.dataset.id);
				this.$router.push({
					name: "UsedCarUpdate",
					params: {
						id: id
					}
				});
			},
			toMerchantList(e){
				let phone = e.currentTarget.dataset.phone;
				let data = {
					'mobile_phone':phone
				}
				this.$router.push({
					name: "MerchantList",
					params: {
						filterData:encodeURIComponent(JSON.stringify(data))
					}
				});
			},
			rTime(e){
				if(!e) return '-'
				let json_date = new Date(e).toJSON();
				return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
