import http from '../utils/Http.js'

export function getVipPlanList() {
	return http({
		url: 'vip-plans',
		method: 'GET'
	})
}

export function getVipPlan(id) {
	return http({
		url: 'vip-plan/'+id,
		method: 'GET'
	})
}

export function addVipPlan(data) {
	return http({
		url: 'vip-plan',
		method: 'POST',
		params: data
	})
}

export function updateVipPlan(data) {
	return http({
		url: 'vip-plan',
		method: 'PUT',
		params: data
	})
}