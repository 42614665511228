import http from '../utils/Http.js'
export function getHelpList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'help/list',
        method: 'GET',
        params: data
    })
}
export function addHelp(data) {
    return http({
        url: 'help',
        method: 'POST',
        data: data
    })
}
export function updateHelp(data) {
    return http({
        url: 'help',
        method: 'PUT',
        data: data
    })
}
export function deleteHelp(id) {
    return http({
        url: 'help',
        method: 'DELETE',
        data: {id:id}
    })
}