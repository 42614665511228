<template>
  <div class="container" :class="{ shown: shown, fading: fading }">
    <div class="box" :class="{ fading: fading }">
      <div class="title">基本信息</div>
      <div class="box-body">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">联系人</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.contact_name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">联系电话</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.contact_mobile"
                class="form-control"
              />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">所属城市</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.city_name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">所在地址</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.area_name"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">看车地址</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.address"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">车辆配置亮点</label>
            </div>
            <div class="col-sm-8">
              <textarea
                class="form-control"
                v-model="usedCar.content"
              ></textarea>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">历史用途</label>
            </div>
            <div class="col-sm-8">
              <textarea
                class="form-control"
                v-model="usedCar.use_history"
              ></textarea>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">车况描述</label>
            </div>
            <div class="col-sm-8">
              <textarea
                class="form-control"
                v-model="usedCar.car_describe"
              ></textarea>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">转让价格</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.transfer_price"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">过户次数</label>
            </div>
            <div class="col-sm-8">
              <input
                type="text"
                v-model="usedCar.transfer_count"
                class="form-control"
              />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">状态</label>
            </div>
            <div class="col-sm-8">
              <select
                class="form-control"
                @change="selected($event)"
                v-model="usedCar.status"
              >
                <option value="-1">请选择</option>
                <option value="0">待审核</option>
                <option value="1">审核通过</option>
                <option value="2">未通过审核</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">封面</label>
            </div>
            <div class="col-sm-8">
              <img :src="usedCar.thumbnail" alt="" class="bg-cover" />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">车辆照片</label>
            </div>
            <div class="col-sm-8 photo">
              <img
                :src="uploadsUrl + item.photo_url"
                class="bg-cover no-radius"
                v-for="(item, index) in picture"
                :key="index"
              />
            </div>

            <div class="col-sm-8 photo">
              <img
                :src="uploadsUrl + item.photo_url"
                class="bg-cover no-radius"
                v-for="(item, index) in photo"
                :key="index"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <button class="cancel" @click="dismiss">取消</button>
        <button class="confirm" @click="save">保存</button>
      </div>
    </div>
  </div>
</template>
<script>
import { apply, getUsedCar } from "@/api/used-car.js";
export default {
  emits: ["confirm"],
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      status: "-1",
      photo: [],
      picture: [],
      usedCar: {},
      shown: false,
      fading: false,
      loading: false,
    };
  },
  beforeCreate() {
    this.uploadsUrl = "http://0.0.0.0:8123/";
  },
  watch: {
    info(val) {
      if (val == null) return;
      this.loadUsedCar();
    },
  },
  methods: {
    show() {
      this.shown = true;
      setTimeout(() => {
        this.fading = true;
      }, 10);
    },
    loadUsedCar() {
      let car = this.info;
      if (Object.keys(car).length > 0) {
        let id = car.id;
        console.log(id);
        getUsedCar(id).then((res) => {
          if (res.status == 200) {
            console.log(res.data);
            let list = res.data;
            console.log(list.picture);
            this.picture = list.picture;
            this.photo = list.photo;
            this.usedCar = list.car;
          }
        });
      }
    },
    dismiss() {
      this.fading = false;
      setTimeout(() => (this.shown = false), 400);
    },
    confirm() {
      this.$emit("confirm");
      this.dismiss();
    },
    selected(e) {
      this.status = e.target.value;
    },
    save() {
      let id = this.info.id;
      let status = this.status;
      if (status == "-1") return;
      if (this.loading) return;
      this.loading = true;
      apply(id, status).then((res) => {
        if (res.status == 200) {
          this.$emit("confirm");
          this.dismiss();
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@titleHeight: 50px;
@bottomHeight: 66px;
@buttonHeight: 40px;

@inputHeight: 36px;

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.618);
  transition: opacity 400ms;
  user-select: none;

  &.shown {
    display: flex;
  }

  &.fading {
    opacity: 1;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 1024px;
    border-radius: 16px;
    background: white;
    transform: scale(0.1);
    transition: transform 400ms;

    &.fading {
      transform: scale(1);
    }

    .title {
      height: @titleHeight;
      line-height: @titleHeight;
      font-size: 16px;
      text-align: center;
    }
    .form {
      flex: 1;
      padding: 0 32px 16px 32px;

      .box-body,
      .box-footer {
        padding: 20px 20px;
      }
    }
    .box-body {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      padding: 10px;
    }
    .col-sm-12 {
      width: 90%;
    }
    .col-sm-12 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      .col-sm-4 {
        width: 33.33333333%;
      }
      .col-sm-8 {
        width: 66.66666667%;
      }
      .form-group {
        margin-bottom: 15px;
        display: flex;
      }
      .form-control,
      select {
        display: block;
        width: 100%;
        height: 34px;
        font-size: 14px;
        color: #555;
        text-indent: 10px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .photo {
        display: flex;
        margin: 5px;
        display: flex;
        margin: 5px;
        flex-wrap: wrap;
        flex-direction: row;
        flex: 4;
      }
      .photo .bg-cover {
        margin-left: 5px;
        margin-bottom: 10px;
        display: flex;
        flex: 1;
      }
      .no-radius {
        border-radius: initial !important;
      }
      .bg-cover {
        border-radius: 100%;
        background-size: contain;
        background-position: center;
        height: 110px;
        width: 110px;
        object-fit: cover;
      }
      .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        label {
          margin-bottom: 4px;
        }
        input[type="text"] {
          border: none;
          height: @inputHeight;
          border-radius: @inputHeight / 2;
          padding: 0 16px;
          background: #f7f8f9;
        }

        &.sep {
          align-items: center;
          height: @inputHeight;
          line-height: @inputHeight;
          position: relative;
          text-align: center;
          color: #ccc;
          &::before {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            content: " ";
            width: 100%;
            height: @inputHeight / 2;
            border-bottom: 1px #e5e5e5 solid;
          }

          .sep-title {
            display: inline-block;
            background: white;
            width: 100px;
          }
        }

        .input-area {
          display: flex;
          input[type="text"] {
            flex: 1;
          }
          button {
            margin-left: 16px;
            border: none;
            width: @inputHeight * 2;
            border-radius: @inputHeight / 2;
            background: linear-gradient(-220deg, #36d1dc, #5b86e5);
            background-size: 200% @titleHeight;
            cursor: pointer;
            color: white;
            text-shadow: #5b86e5 0 1px 4px;
            box-shadow: #36d1dc66 0 4px 16px;
            transition: background-position-x 400ms;

            &:hover {
              background-position-x: 100%;
            }
          }
        }
      }
    }
    .bottom {
      height: @bottomHeight;
      display: flex;

      button {
        flex: 1;
        border: none;
        height: @buttonHeight;
        border-radius: @buttonHeight / 2;
        margin: 0 32px;
        cursor: pointer;

        &.confirm {
          background: linear-gradient(-220deg, #f5af19, #f12711);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #f12711 0 1px 4px;
          box-shadow: #f5af1966 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }

        &.cancel {
          background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #2c3e50 0 1px 4px;
          box-shadow: #bdc3c766 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }
      }
    }
  }
}
</style>