<template>
  <div class="container" :class="{ shown: shown, fading: fading }">
    <div class="box" :class="{ fading: fading }">
      <div class="title">基本信息</div>
      <div class="box-body">
        <div class="col-sm-12">
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">车类型</label>
            </div>
            <div class="col-sm-8">
              <select
                v-if="info.length > 0"
                v-model="parent_id"
                class="form-control"
              >
                <option value="0">顶级分类</option>
                <option
                  v-for="(item, index) in info"
                  :value="item.id"
                  :key="index"
                  :data-index="item.id"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">车型名称</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="name" />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">维文名称</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="name_ug" />
            </div>
          </div>
          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">哈文名称</label>
            </div>
            <div class="col-sm-8">
              <input type="text" class="form-control" v-model="name_kz" />
            </div>
          </div>

          <div class="form-group">
            <div class="col-sm-4 text-right">
              <label class="control-label">图标路径</label>
            </div>
            <div class="col-sm-8 photo">
              <div class="file" @click="choseImage">点击上传图片</div>
              <div class="preview"><img :src="photo" v-if="photo" /></div>
              <input
                ref="imageInput"
                class="hide"
                type="file"
                @change="changeImage($event)"
                accept="image/gif,image/jpeg,image/jpg,image/png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <button class="cancel" @click="dismiss">取消</button>
        <button class="confirm" @click="save">保存</button>
      </div>
    </div>
  </div>
</template>
<script>
import { createCarType } from "@/api/car-type";
import { uploadFile } from "@/api/common";
export default {
  emits: ["confirm"],
  props: {
    info: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      shown: false,
      fading: false,
      loading: false,
      name: "",
      name_ug: "",
      name_kz: "",
      photo: "",
      parent_id: 0,
    };
  },
  methods: {
    changeImage(e) {
      var that = this;
      let file = e.target.files[0];
      if (file) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          this.formData = new FormData();
          this.formData.append("image", file, file.name); // 添加到请求体
          uploadFile(this.formData).then((res) => {
            console.log(typeof res);
            that.photo = res.data.url;
          });
        };
      }
    },
    choseImage() {
      this.$refs.imageInput.click();
    },
    show() {
      this.shown = true;
      setTimeout(() => {
        this.fading = true;
      }, 10);
    },
    dismiss() {
      this.fading = false;
      setTimeout(() => (this.shown = false), 400);
    },
    save() {
      if (this.loading) return;
      this.loading = true;
      let data = {
        name: this.name,
        name_ug: this.name_ug,
        name_kz: this.name_kz,
        photo: this.photo,
        parent_id: this.parent_id,
      };
      createCarType(data).then((res) => {
        if (res.status == 200) {
          this.$emit("confirm");
          this.dismiss();
        }
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="less" scoped>
@titleHeight: 50px;
@bottomHeight: 66px;
@buttonHeight: 40px;

@inputHeight: 36px;

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.618);
  transition: opacity 400ms;
  user-select: none;

  &.shown {
    display: flex;
  }

  &.fading {
    opacity: 1;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 620px;
    border-radius: 16px;
    background: white;
    transform: scale(0.1);
    transition: transform 400ms;

    &.fading {
      transform: scale(1);
    }

    .title {
      height: @titleHeight;
      line-height: @titleHeight;
      font-size: 16px;
      text-align: center;
    }
    .form {
      flex: 1;
      padding: 0 32px 16px 32px;

      .box-body,
      .box-footer {
        padding: 20px 20px;
      }
    }
    .box-body {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
      padding: 10px;
    }
    .col-sm-12 {
      width: 90%;
    }
    .col-sm-12 {
      position: relative;
      min-height: 1px;
      padding-right: 15px;
      padding-left: 15px;
      .col-sm-4 {
        width: 33.33333333%;
      }
      .col-sm-8 {
        width: 66.66666667%;
      }
      .file {
        width: 120px;
        height: 120px;
        line-height: 120px;
        text-align: center;
        cursor: pointer;
        border: 1px dashed #dedede;
        border-radius: 6px;
      }
      .preview {
        width: 120px;
        height: 120px;
        border-radius: 6px;
        margin-left: 15px;
      }
      .preview img {
        width: 100%;
        height: 100%;
      }
      .hide {
        display: none;
      }
      .form-group {
        margin-bottom: 15px;
        display: flex;
      }
      .form-control,
      select {
        display: block;
        width: 100%;
        height: 34px;
        font-size: 14px;
        color: #555;
        text-indent: 10px;
        background-color: #fff;
        background-image: none;
        border: 1px solid #ccc;
        border-radius: 4px;
      }
      .photo {
        display: flex;
        margin: 5px;
        flex-wrap: nowrap;
        flex-direction: row;
      }
      .photo .bg-cover {
        margin-left: 5px;
      }
      .no-radius {
        border-radius: initial !important;
      }
      .bg-cover {
        border-radius: 100%;
        background-size: contain;
        background-position: center;
        height: 110px;
        width: 110px;
        object-fit: cover;
      }
      .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        label {
          margin-bottom: 4px;
        }
        input[type="text"] {
          border: none;
          height: @inputHeight;
          border-radius: @inputHeight / 2;
          padding: 0 16px;
          background: #f7f8f9;
        }

        &.sep {
          align-items: center;
          height: @inputHeight;
          line-height: @inputHeight;
          position: relative;
          text-align: center;
          color: #ccc;
          &::before {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            content: " ";
            width: 100%;
            height: @inputHeight / 2;
            border-bottom: 1px #e5e5e5 solid;
          }

          .sep-title {
            display: inline-block;
            background: white;
            width: 100px;
          }
        }

        .input-area {
          display: flex;
          input[type="text"] {
            flex: 1;
          }
          button {
            margin-left: 16px;
            border: none;
            width: @inputHeight * 2;
            border-radius: @inputHeight / 2;
            background: linear-gradient(-220deg, #36d1dc, #5b86e5);
            background-size: 200% @titleHeight;
            cursor: pointer;
            color: white;
            text-shadow: #5b86e5 0 1px 4px;
            box-shadow: #36d1dc66 0 4px 16px;
            transition: background-position-x 400ms;

            &:hover {
              background-position-x: 100%;
            }
          }
        }
      }
    }
    .bottom {
      height: @bottomHeight;
      display: flex;

      button {
        flex: 1;
        border: none;
        height: @buttonHeight;
        border-radius: @buttonHeight / 2;
        margin: 0 32px;
        cursor: pointer;

        &.confirm {
          background: linear-gradient(-220deg, #f5af19, #f12711);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #f12711 0 1px 4px;
          box-shadow: #f5af1966 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }

        &.cancel {
          background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #2c3e50 0 1px 4px;
          box-shadow: #bdc3c766 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }
      }
    }
  }
}
</style>