<template>
	<div class="container">
		<div class="title">
			<div class="text">更新价位</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input type="text" class="input" v-model="info.tip_name" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">维语名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.tip_name_ug" placeholder="كىرگۈزۈڭ...">
				</div>
			</div>
			<div class="row">
				<div class="left">哈语名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.tip_name_kz" placeholder="كىرگىزىڭىز...">
				</div>
			</div>
			<div class="row">
				<div class="left">最小价位</div>
				<div class="right">
					<input type="number" class="input" @keypress="inputChange" v-model="info.min_price" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">最大价位</div>
				<div class="right">
					<input type="number" class="input" @keypress="inputChange" v-model="info.max_price" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.top" @keypress="inputChange" placeholder="数字越大越靠后">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="add">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateUsedCarPrice
	} from "@/api/used-car.js";
	export default {
		data() {
			return {
				info: {},
				info_orginal: {},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
				this.info_orginal = JSON.parse(decodeURIComponent(this.$route.params.info));
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (data) {
					isLoad = true;
					updateUsedCarPrice(this.info).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					if(data==false){
						this.showToast("数据没变")
					}
				}

			},
			getChangeData() {
				let data = {
					id: this.info.id
				}
				if (this.info.tip_name != this.info_orginal.tip_name) {
					data = {
						...data,
						tip_name: this.info.tip_name
					}
				}
				if (this.info.tip_name_ug != this.info_orginal.tip_name_ug) {
					data = {
						...data,
						tip_name_ug: this.info.tip_name_ug
					}
				}
				if (this.info.tip_name_kz != this.info_orginal.tip_name_kz) {
					data = {
						...data,
						tip_name_kz: this.info.tip_name_kz
					}
				}
				// 需要优化
				if (this.info.min_price != this.info_orginal.min_price) {
					if (this.info.min_price != "") {
						data = {
							...data,
							min_price: this.info.min_price * 1
						}
					} else {
						this.showToast("请输入最小价位！")
						return
					}
				}
				if (this.info.max_price != this.info_orginal.max_price) {

					if (this.info.max_price != "") {
						data = {
							...data,
							max_price: this.info.max_price * 1
						}
					} else {
						this.showToast("请输入最大价位！")
						return
					}
				}
				if (this.info.top != this.info_orginal.top) {
					if (this.info.top != "") {
						data = {
							...data,
							top: this.info.top * 1
						}
					} else {
						this.showToast("请输入排序号！")
						return
					}
				}
				if (Object.keys(data).length > 1) {
					return data
				} else {
					return false;
				}
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>