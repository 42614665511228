<template>
	<router-view />
</template>

<style lang="less">
	@import url('./assets/fonts/ug.css');

	body {
		margin: 0;
	}

	a {
		text-decoration: none;
		color: #50332c;
	}

	input,
	textarea,
	select,
	option,
	button {
		outline: none;
	}

	input.rtl {
		direction: rtl;
		font-family: 'ekran';
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	#app {
		position: fixed;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		font-family: Avenir, Helvetica, Arial, sans-serif;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		color: #50332c;
		font-size: 14px;
	}
</style>
