<template>
	<div class="container">
		<div class="title">
			<div class="text">更新帮助信息</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">类型</div>
				<div class="right">
					<select class="select" v-model="help.category">
						<option :value="-1">请选择</option>
						<option :value="1">商务</option>
						<option :value="2">帮助</option>
						<option :value="3">关于我们</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">标题</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="help.title">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="help.title_ug">
						<i class="after">维语</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="help.title_kz">
						<i class="after">哈语</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">链接</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="help.url" placeholder="https://">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before" type="text" v-model="help.url_ug" placeholder="https://">
						<i class="after">维语</i>
					</div>
					<div class="language">
						<input class="before" type="text" v-model="help.url_kz" placeholder="https://">
						<i class="after">哈语</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">排序</div>
				<div class="right">
					<input type="number" class="input" v-model="help.top" @keypress="inputChange" placeholder="请输入排序号(越大越靠后)">
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<switcha :on="help.status==1" v-on:changed="help.status=help.status?0:1" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateHelp
	} from "@/api/help";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				help: {},
				help_orginal: {},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.help) {
				this.help = JSON.parse(decodeURIComponent(this.$route.params.help))
				this.help_orginal = JSON.parse(decodeURIComponent(this.$route.params.help))
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (Object.getOwnPropertyNames(data).length > 0) {
					//发送请求
					isLoad = true;
					updateHelp(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					this.showToast("没更改")
					isLoad = false;
				}

			},
			getChangeData() {
				let data = {
					id: this.help.id
				}
				if (this.help.category != this.help_orginal.category) {
					if (this.help.category == -1) {
						this.showToast("请选择链接类别")
						return
					}
					data = {
						...data,
						category: this.help.category
					}
				}
				if (this.help.title != this.help_orginal.title) {
					data = {
						...data,
						title: this.help.title
					}
				}
				if (this.help.title_ug != this.help_orginal.title_ug) {
					data = {
						...data,
						title_ug: this.help.title_ug.trim()
					}
				}
				if (this.help.title_kz != this.help_orginal.title_kz) {
					data = {
						...data,
						title_kz: this.help.title_kz.trim()
					}
				}
				if (this.help.url != this.help_orginal.url) {
					data = {
						...data,
						url: this.help.url.trim()
					}
				}
				if (this.help.url_ug != this.help_orginal.url_ug) {
					data = {
						...data,
						url_ug: this.help.url_ug.trim()
					}
				}
				if (this.help.url_kz != this.help_orginal.url_kz) {
					data = {
						...data,
						url_kz: this.help.url_kz.trim()
					}
				}
				if (this.help.top != this.help_orginal.top) {
					if(this.help.top==""){
						this.showToast("请输入排序号")
						return
					}else {
						data = {
							...data,
							top: this.help.top*1
						}
					}
				}
				if (this.help.status != this.help_orginal.status) {
					data = {
						...data,
						status: this.help.status
					}
				}
				
				
				return data;
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>