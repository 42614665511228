<template>
	<div class="container">
		<div class="title">
			<div class="text">车型管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="addCreate">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">图片</div>
					<div class="col flex-1">车型名称</div>
					<div class="col flex-1 rtl-left">维文名称</div>
					<div class="col flex-1 rtl-left">哈文名称</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in carType" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col avatar">
						<img class="avatar-img no-radius" :src="item.photo" :onerror="defaultImg" alt="" />
					</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showModal">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="item.id" :data-name="item.name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<add-modal ref="AddModal" :info="type" v-on:confirm="reloadCarType" />
		<update-modal ref="UpdateModal" :info="infoTo" :type="type" v-on:confirm="reloadCarType" />
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="deleteConfirm" />
	</div>
</template>
<script>
	import {
		getCarType,
		getCarTypeList,
		daleteType
	} from "@/api/car-type";
	import AddModal from "@/components/car-type/CarTypeAdd.vue";
	import UpdateModal from "@/components/car-type/CarTypeUpdate.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	import DialogBox from "@/components/widgets/DialogBox";
	let byId = 0;
	export default {
		components: {
			AddModal,
			UpdateModal,
			Paginator,
			DialogBox,
		},
		data() {
			return {
				carType: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				dialogContent: null,
				infoTo: null,
				type: [],
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			}
		},
		mounted() {
			this.loadCarType();
			this.loadCarTypeList();
		},
		methods: {
			loadCarTypeList() {
				getCarType().then((res) => {
					if (res.status == 200) {
						this.type = res.data;
					}
				});
			},
			loadCarType() {
				getCarTypeList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.carType = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadCarType(page = 1) {
				this.curPage = page;
				this.loadCarType();
			},
			onPageChanged(page) {
				this.reloadCarType(page);
			},
			addCreate() {
				this.$refs.AddModal.show();
			},
			showConfirmDialog(e) {
				let dataset = e.currentTarget.dataset;
				let dialogContent = "确定要删除《" + dataset.name + "》吗？";
				byId = dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			deleteConfirm() {
				if (byId == 0) return;
				daleteType(byId).then((res) => {
					if (res.status === 200) {
						this.reloadCarType();
					}
				});
			},
			showModal(e) {
				if (typeof e == "undefined") return;
				let index = e.currentTarget.dataset.index;
				let obj = this.carType[index];
				this.infoTo = JSON.parse(JSON.stringify(obj));
				this.$refs.UpdateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>
