<template>
	<div class="container">
		<div class="title">
			<div class="text">酒店列表翻译</div>
		</div>
		<div class="filter">
			<div class="city-list">
				<div class="city-item" :class="{ on: filterIndex == -1 }" data-index="-1" @click="switchCity">
					全部
				</div>
				<div class="city-item" :class="{ on: filterIndex == cityIndex }" v-for="(city, cityIndex) in cities"
					:key="cityIndex" :data-index="cityIndex" @click="switchCity">
					{{ city.city_name }}({{ city.count }})
				</div>
			</div>
			<div class="status">
				<div class="status-item">
					<div class="text">维语</div>
					<switcha :on="untranslated_ug" v-on:changed="onTranslateUgChanged" />
				</div>
				<div class="status-item">
					<div class="text">哈语</div>
					<switcha :on="untranslated_kz" v-on:changed="onTranslateKzChanged" />
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-2">地址</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(hotel, index) in hotels" :key="index">
					<div class="col id">{{ hotel.id }}</div>
					<div class="col flex-1">{{ hotel.hotel_name }}</div>
					<div class="col flex-2">{{ hotel.address }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<translate-modal ref="translateModal" :info="infoToTranslate" :ug="untranslated_ug" :kz="untranslated_kz"
			v-on:confirm="reloadHotels" />
	</div>
</template>
<script>
	import {
		getCities,
		getHotels
	} from "@/api/hotel";
	import Switcha from "@/components/widgets/Switch.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	import TranslateModal from "@/components/hotel/TranslateModal.vue";

	export default {
		components: {
			Switcha,
			Paginator,
			TranslateModal,
		},
		data() {
			return {
				routes: [],
				cities: [],
				filterIndex: -1,
				untranslated_ug: false,
				untranslated_kz: false,
				hotels: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
			};
		},
		created() {
			this.loadCities();
			this.loadHotels();
		},
		methods: {
			loadCities() {
				getCities().then((res) => {
					if (res.status == 200) {
						this.cities = res.data;
					}
				});
			},
			loadHotels() {
				let curCity =
					this.filterIndex == -1 ? "" : this.cities[this.filterIndex].city_name;
				getHotels(
					this.curPage,
					curCity,
					this.untranslated_ug,
					this.untranslated_kz,
					this.pageSize
				).then((res) => {
					if (res.status == 200) {
						this.hotels = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadHotels(page = 1) {
				this.curPage = page;
				this.loadHotels();
			},
			onPageChanged(page) {
				this.reloadHotels(page);
			},
			onTranslateUgChanged() {
				this.untranslated_ug = !this.untranslated_ug;

				this.reloadHotels();
			},

			onTranslateKzChanged() {
				this.untranslated_kz = !this.untranslated_kz;

				this.reloadHotels();
			},
			switchCity(e) {
				let index = Number(e.currentTarget.dataset.index);
				this.filterIndex = index;

				this.reloadHotels();
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.hotels[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/table.less");

	.filter {
		min-height: @titleHeight;
		background: white;
		margin: 16px 32px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		padding: 0 16px;

		.city-list {
			flex: 1;
			display: flex;
			flex-wrap: wrap;

			.city-item {
				padding: 16px;
				cursor: pointer;
				color: #999;

				&.on {
					color: #f12711;
				}

				&:hover {
					color: #f12711;
				}
			}
		}

		.status {
			display: flex;

			.status-item {
				margin-left: 32px;
				display: flex;
				align-items: center;

				.text {
					margin-right: 16px;
				}
			}
		}
	}
</style>
