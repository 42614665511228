import http from '../utils/Http.js'

export function getCities() {
    return http({
        url: 'hotel/cities',
        method: 'GET'
    })
}

export function getHotels(page = 1, city = '', untranslatedUg = false, untranslatedKz = false, size) {
    let data = {
        page: page,
        size: size,
        city: city,
        untranslated_ug: untranslatedUg,
        untranslated_kz: untranslatedKz
    }
    return http({
        url: 'hotels',
        method: 'GET',
        params: data
    })
}


export function translateHotelListInfo(id, hotel_name_ug, hotel_name_kz, address_ug, address_kz) {
    let data = {
        id: id,
        name_ug: hotel_name_ug,
        name_kz: hotel_name_kz,
        address_ug: address_ug,
        address_kz: address_kz
    }

    return http({
        url: 'translate-list-info',
        method: 'PUT',
        data: data
    })
}