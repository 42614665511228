<template>
	<div class="container">
		<div class="title">
			<div class="text">更新模版消息</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">标题</div>
				<div class="right">
					<input type="text" class="right input" v-model="msgTemplate.title" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">中文内容</div>
				<div class="right">
					<textarea class="textarea" v-model="msgTemplate.content" placeholder="请输入..."></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">维语内容</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="msgTemplate.content_ug" placeholder="كىرگۈزۈڭ..."></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">哈语内容</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="msgTemplate.content_kz" placeholder="كىرگىزىڭىز..."></textarea>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateMsgTemplate
	} from "@/api/msg-template";
	export default {
		data() {
			return {
				msgTemplate: {},
				templateId: "",
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.msgTemplate) {
				this.msgTemplate = JSON.parse(decodeURIComponent(this.$route.params.msgTemplate))
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (this.msgTemplate.title == undefined || this.msgTemplate.title == "" || this.msgTemplate.title ==
					null) {
					this.showToast("请输入排序号")
					return
				}
				isLoad = true;
				updateMsgTemplate(this.msgTemplate).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>