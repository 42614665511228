import http from '../utils/Http.js'

export function getMessageList(data) {
    return http({
        url: 'message/list',
        method: 'GET',
        params: data
    })
}

export function sendMessage(data) {
    return http({
        url: 'message/sender',
        method: 'POST',
        params: data
    })
}