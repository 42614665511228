<template>
	<div class="container">
		<div class="title">
			<div class="text">用户管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">头像</div>
					<div class="col flex-1">用户名</div>
					<div class="col flex-1">手机号</div>
					<div class="col flex-1">状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(user, userIndex) in users" :key="userIndex">
					<div class="col id">{{userIndex+1}}</div>
					<div class="col avatar">
						<img class="avatar-img" :src="user.avatar" :onerror="defaultImg">
					</div>
					<div class="col flex-1">{{user.real_name}}</div>
					<div class="col flex-1">{{user.usr}}</div>
					<div class="col flex-1">
						<span v-if="user.status==1">正常</span>
						<span v-if="user.status==2">审核中</span>
						<span v-if="user.status==3">禁用</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-id="user.id" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="角色" :data-id="user.id" @click="toEditPage">
							<i class="iconfont icon-view"></i>
						</div>

					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>
<script>
	import {
		getUserList
	} from "@/api/admin-user";
	import Paginator from "@/components/widgets/Paginator.vue";

	export default {
		components: {
			Paginator
		},
		data() {
			return {
				users: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push(
					"/admin/user/add/"
				);
			},
			loadData() {
				getUserList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.users = res.data.data;
						this.totalRecords = res.data.total
					}
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let id = e.currentTarget.dataset.id
				this.$router.push(
					"/admin/user/update/" + id
				);
			},
		},
	}
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
