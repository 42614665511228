<template>
	<div class="container">
		<div class="title">
			<div class="text">菜单管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="addMenu">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">图标</div>
					<div class="col flex-1">菜单名称</div>
					<div class="col flex-2">URL地址</div>
					<div class="col flex-1">排序</div>
					<div class="col flex-1">父节点</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(menu, index) in menus" :key="index">
					<div class="col id">{{ menu.id }}</div>
					<div class="col flex-1">
						<i class="iconfont" :class="menu.icon"></i>
					</div>
					<div class="col flex-1">{{ menu.name }}</div>
					<div class="col flex-2">{{ menu.path }}</div>
					<div class="col flex-1">{{ menu.sort_by }}</div>

					<div class="col flex-1">{{ menu.type }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showModal">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="menu.id" :data-name="menu.name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<add-modal ref="addModal" :menus="infoToMenus" v-on:confirm="reloadMenus" />
		<update-modal ref="UpdateModal" :menus="infoToMenus" :info="infoToMenu" v-on:confirm="reloadMenus" />
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="deleteConfirm" />
	</div>
</template>
<script>
	import {
		getMenuList,
		getTopLevelMenus,
		daleteMenu
	} from "@/api/menu";
	import AddModal from "@/components/admin/MenuAdd.vue";
	import UpdateModal from "@/components/admin/MenuUpdate.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	import DialogBox from "@/components/widgets/DialogBox";
	let byId = 0;
	export default {
		components: {
			AddModal,
			UpdateModal,
			Paginator,
			DialogBox,
		},
		data() {
			return {
				menus: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToMerchants: null,
				dialogContent: null,
				infoToMenus: [],
				infoToMenu: {},
			};
		},
		mounted() {
			this.loadMenus();
		},
		methods: {
			loadMenus() {
				getMenuList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.menus = res.data.data;
						this.totalRecords = res.data.total;
					}
					if(this.infoToMenus.length==0){
						this.loadTopMesnus();
					}
				});
			},
			reloadMenus(page = 1) {
				this.curPage = page;
				this.loadMenus();
			},
			onPageChanged(page) {
				this.reloadMenus(page);
			},
			loadTopMesnus() {
				getTopLevelMenus()
					.then((res) => {
						if (res.status === 200) {
							this.infoToMenus = res.data.data;
						}
					})
					.catch((err) => console.log(err));
			},
			addMenu() {
				this.$refs.addModal.show();
			},
			showConfirmDialog(e) {
				let dataset = e.currentTarget.dataset;
				let dialogContent = "确定要删除《" + dataset.name + "》吗？";
				byId = dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			deleteConfirm() {
				daleteMenu(byId).then((res) => {
					if (res.status === 200) {
						byId = 0;
						this.reloadMenus();
					}
				});
			},
			showModal(e) {
				if (typeof e == "undefined") return;
				let index = e.currentTarget.dataset.index;
				let obj = this.menus[index];
				this.infoToMenus = JSON.parse(JSON.stringify(this.infoToMenus));
				this.infoToMenu = JSON.parse(JSON.stringify(obj));
				this.$refs.UpdateModal.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>
