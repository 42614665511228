import http from '../utils/Http.js'
export function getDash() {//获取统计数据
    return http({
        url: 'dash',
        method: 'GET',
    })
}
export function getRank() {//获取翻译排行榜
    return http({
        url: 'rank',
        method: 'GET',
    })
}