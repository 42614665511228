<template>
  <div class="container">
    <div class="title">
      <div class="text">权限管理</div>
      <div class="meta-functions">
        <div class="function-item add" @click="toAddPage">
			<i class="iconfont icon-add refresh"></i>
			<span>添加</span>
		</div>
      </div>
    </div>
    <div class="table">
      <div class="table-header">
        <div class="row">
          <div class="col id">#</div>
          <div class="col flex-1">名称</div>
          <div class="col flex-3">相关路由</div>
          <div class="col operation">操作</div>
        </div>
      </div>
      <div class="table-body">
        <div
          class="row"
          v-for="(permission, index) in permissions"
          :key="index"
        >
          <div class="col id">{{ permission.id }}</div>
          <div class="col flex-1">{{ permission.name }}</div>
          <div class="col flex-3 routes">
            <div
              class="route-item"
              v-for="(routeName, routeNameIndex) in permission.routes"
              :key="routeNameIndex"
            >
              {{ routeName }}
            </div>
          </div>
          <div class="col operation">
            <div
              class="operation-item"
              title="编辑"
              :data-id="permission.id"
              @click="toEditPage"
            >
              <i class="iconfont icon-bianji"></i>
            </div>
            <div
              class="operation-item"
              title="删除"
              :data-name="permission.name"
              :data-id="permission.id"
              @click="showConfirmDialog"
            >
              <i class="iconfont icon-shanchu"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="paginator"></div>
    </div>
    <dialog-box
      ref="dialogBox"
      :content="dialogContent"
      v-on:confirm="onConfirmDelete"
    />
  </div>
</template>
<script>
let self;
let standById = 0;
import { getPermissions, deletePermission } from "@/api/admin";

import DialogBox from "@/components/widgets/DialogBox";
export default {
  components: {
    DialogBox,
  },
  data() {
    return {
      permissions: [],
      dialogContent: "",
    };
  },
  created() {
    self = this;
    this.loadData();
  },
  methods: {
    toAddPage() {
      this.$router.push("/admin/permission/add");
    },
    loadData() {
      getPermissions().then((res) => {
        if (res.status == 200) {
          this.permissions = res.data;
        }
      });
    },
    toEditPage(e) {
      this.$router.push(
        "/admin/permission/update/" + e.currentTarget.dataset.id
      );
    },
    showConfirmDialog(e) {
      let dialogContent =
        "确定要删除《" + e.currentTarget.dataset.name + "》吗？";
      standById = e.currentTarget.dataset.id;
      this.dialogContent = dialogContent;
      this.$refs.dialogBox.show();
    },
    onConfirmDelete() {
      if (standById == 0) return;
      deletePermission(standById).then((res) => {
        if (res.status == 200) {
          self.loadData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>