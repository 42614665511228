<template>
  <div class="container">
    <div class="title">
      <div class="text">添加权限</div>
      <!-- <div class="meta-functions">
        <div class="function-item back" @click="goBack">返回</div>
      </div> -->
    </div>
    <div class="form">
      <div class="row-x">
        <div class="col f1">
          <div class="col-title">所有路由</div>
          <select size="10">
            <option
              v-for="(route, routeIndex) in routes"
              :key="routeIndex"
              :value="route.id"
              :data-index="routeIndex"
              @dblclick="selectThis"
              :disabled="route.disabled"
            >
              {{ route.name }}
            </option>
          </select>
        </div>
        <div class="col sep">
          <i class="iconfont icon-qiehuan"></i>
        </div>
        <div class="col f1">
          <div class="col-title">已选路由</div>
          <select size="10">
            <option
              v-for="(selectedRoute, selectedRouteIndex) in selectedRoutes"
              :key="selectedRouteIndex"
              :value="selectedRoute.id"
              :data-index="selectedRouteIndex"
              @dblclick="unselectThis"
            >
              {{ selectedRoute.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="row-x">
        <div class="col f1">
          <div class="row">
            <label for="name">权限名称</label>
            <input
              type="text"
              id="name"
              v-model="name"
              autocomplete="off"
              spellcheck="false"
              autofocus
            />
          </div>
        </div>
        <div class="col sep"></div>
        <div class="col f1">
          <div class="col-title">操作</div>
          <div class="operations">
            <button type="button" class="cencel" @click="goBack">取消</button>
            <button type="button" class="mutual" @click="reset">重置</button>
            <button type="button" class="confirm" @click="add">保存</button>
          </div>
        </div>
      </div>
    </div>
    <div class="hint">{{ hint }}</div>
  </div>
</template>
<script>
let self;

import { getRoutes, addPermission } from "@/api/admin";

export default {
  data() {
    return {
      name: "",
      routes: [],
      selectedRoutes: [],
      hint: "",
    };
  },
  created() {
    self = this;
    this.loadRoutes();
  },
  methods: {
    goBack() {
      this.$router.back();
    },
    reset() {
      this.selectedRoutes = [];
      this.name = "";
      this.routes.forEach((route) => {
        route.disabled = false;
      });
    },
    selectThis(e) {
      let index = Number(e.currentTarget.dataset.index);
      this.routes[index].disabled = true;
      let itemToMove = JSON.parse(JSON.stringify(this.routes[index]));
      this.selectedRoutes.push(itemToMove);
    },
    unselectThis(e) {
      let index = Number(e.currentTarget.dataset.index);
      let id = Number(e.currentTarget.value);
      let newArr = [];

      for (let i = 0; i < this.selectedRoutes.length; i++) {
        const selectedRoute = this.selectedRoutes[i];
        if (i == index) {
          continue;
        }
        newArr.push(selectedRoute);
      }
      this.selectedRoutes = newArr;

      for (let j = 0; j < this.routes.length; j++) {
        const route = this.routes[j];
        if (route.id == id) {
          route.disabled = false;
          break;
        }
      }
    },
    loadRoutes() {
      getRoutes().then((res) => {
        if (res.status == 200) {
          let routes = [];
          res.data.forEach((element) => {
            element["disabled"] = false;
            routes.push(element);
          });
          self.routes = routes;
        }
      });
    },
    showHint(hintText, duration = 2000) {
      this.hint = hintText;
      setTimeout(() => {
        this.hint = "";
      }, duration);
    },
    add() {
      if (this.selectedRoutes.length == 0) {
        this.showHint("请选择路由");
        return;
      }

      if (this.name == "") {
        this.showHint("请填写权限名称");
        return;
      }

      let routeIds = [];

      this.selectedRoutes.forEach((route) => {
        routeIds.push(route.id);
      });

      addPermission(this.name, routeIds).then((res) => {
        if(res.status == 200){
          this.goBack()
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@titleHeight: 40px;
@inputHeight: 36px;
.container {
  .title {
    padding: 24px 32px;
    height: @titleHeight;
    display: flex;
    .text {
      flex: 1;
      display: flex;
      align-items: center;
      font-size: 16px;
    }

    .meta-functions {
      display: flex;
      .function-item {
        background: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 0 16px;
        border-radius: @titleHeight / 2;

        &.add {
          background: linear-gradient(-220deg, #f5af19, #f12711);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #f12711 0 1px 4px;
          box-shadow: #f5af1966 0 4px 16px;
          transition: background-position-x 400ms;
        }

        &.back {
          background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
          background-size: 200% 50px;
          color: white;
          text-shadow: #2c3e50 0 1px 4px;
          box-shadow: #bdc3c766 0 4px 16px;
          transition: background-position-x 400ms;
        }

        &:hover {
          background-position-x: 100%;
        }
      }
    }
  }

  .form {
    padding: 0 32px;
    .row {
      display: flex;
      flex-direction: column;
      margin-bottom: 16px;

      label {
        margin-bottom: 4px;
      }
      input[type="text"] {
        border: #bdc3c7 1px solid;
        height: @inputHeight;
        border-radius: @inputHeight / 2;
        padding: 0 16px;
      }
    }
    .row-x {
      display: flex;
      margin-bottom: 16px;
      .col {
        &.f1 {
          flex: 1;
          display: flex;
          flex-direction: column;

          .col-title {
            margin-bottom: 4px;
          }

          select {
            border: #bdc3c7 1px solid;
            min-height: 400px;
            border-radius: 16px;
            padding: 16px;

            option {
              height: @inputHeight;
              display: flex;
              align-items: center;
            }
          }

          .operations {
            display: flex;
            height: @inputHeight;
            button {
              flex: 1;
              border: none;
              color: white;
              cursor: pointer;
              border-radius: @inputHeight / 2;

              &.cencel {
                background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
                background-size: 200% @titleHeight;
                color: white;
                text-shadow: #2c3e50 0 1px 4px;
                box-shadow: #bdc3c766 0 4px 16px;
                transition: background-position-x 400ms;

                &:hover {
                  background-position-x: 100%;
                }
              }

              &.mutual {
                background: linear-gradient(-220deg, #36d1dc, #5b86e5);
                background-size: 200% @titleHeight;
                color: white;
                text-shadow: #5b86e5 0 1px 4px;
                box-shadow: #36d1dc66 0 4px 16px;
                transition: background-position-x 400ms;

                &:hover {
                  background-position-x: 100%;
                }
              }

              &.confirm {
                background: linear-gradient(-220deg, #f5af19, #f12711);
                background-size: 200% @titleHeight;
                color: white;
                text-shadow: #f12711 0 1px 4px;
                box-shadow: #f5af1966 0 4px 16px;
                transition: background-position-x 400ms;

                &:hover {
                  background-position-x: 100%;
                }
              }

              &:nth-child(2) {
                margin: 0 32px;
              }
            }
          }
        }
        &.sep {
          width: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
          .iconfont {
            font-size: 24px;
          }
        }
      }
    }
  }

  .hint {
    text-align: center;
  }
}
</style>