import http from '../utils/Http.js'
export function getVinList(data) {
	// let data = {
	// 	page: page,
	// 	size: size,
	// 	untranslated_ug: untranslatedUg,
	// 	untranslated_kz: untranslatedKz
	// }
	return http({
		url: 'vin/list',
		method: 'GET',
		params: data
	})
}

export function translateVin(id, nameUg, nameKz) {
	let data = {
		id: id,
		vin_name_ug: nameUg,
		vin_name_kz: nameKz
	}
	return http({
		url: 'vin/translate',
		method: 'PUT',
		data: data,
	})
}
