import http from '../utils/Http'


export function getRegion(page = 1, size, table, untranslatedUg = false, untranslatedKz = false) {
    let data = {
        page: page,
        size: size,
        table: table,
        untranslated_ug: untranslatedUg,
        untranslated_kz: untranslatedKz
    }
    return http({
        url: 'regions',
        method: 'GET',
        params: data,
    })
}

export function translateRegionInfo(id, nameUg, nameKz, table) {
    let data = {
        id: id,
        nameUg: nameUg,
        nameKz: nameKz,
        table: table
    }
    return http({
        url: 'region',
        method: 'POST',
        data: data,
    })
}