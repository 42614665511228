import http from '../utils/Http.js'



//region 路由

export function getRoutes() {
    return http({
        url: 'routes',
        method: 'GET'
    })
}

export function addRoute(name, path, method) {
    let data = {
        name: name,
        path: path,
        method: method
    }

    return http({
        url: 'route',
        method: 'POST',
        data: data
    })
}

export function updateRoute(id, name, path, method) {
    let data = {
        id: id,
        name: name,
        path: path,
        method: method
    }

    return http({
        url: 'route',
        method: 'PUT',
        data: data
    })

}

export function deleteRoute(id) {
    let data = {
        id: id
    }
    return http({
        url: 'route',
        method: 'DELETE',
        data: data
    })
}
//endregion


//region 权限

export function getPermission(id) {
    return http({
        url: 'permission/' + id,
        method: 'GET'
    })
}

export function getPermissions() {
    return http({
        url: 'permissions',
        method: 'GET'
    })
}

export function addPermission(name, routeIds) {
    let data = {
        name: name,
        routeIds: routeIds
    }

    return http({
        url: 'permission',
        method: 'POST',
        data: data
    })
}

export function updatePermission(id, name, routeIds) {
    let data = {
        id: id,
        name: name,
        routeIds: routeIds
    }

    return http({
        url: 'permission',
        method: 'PUT',
        data: data
    })
}


export function deletePermission(id) {
    let data = {
        id: id
    }

    return http({
        url: 'permission',
        method: 'DELETE',
        data: data
    })
}

//endregion

// 角色

export function getRole(id) {
    return http({
        url: 'role/' + id,
        method: 'GET'
    })
}

export function getRoles() {
    return http({
        url: 'roles',
        method: 'GET'
    })
}

export function addRole(name, roleIds) {
	
    let data = {
        name: name,
        roleIds: roleIds
    }

    return http({
        url: 'role',
        method: 'POST',
        data: data
    })
}

export function updateRole(id, name, roleIds) {
    let data = {
        id: id,
        name: name,
        roleIds: roleIds
    }

    return http({
        url: 'role',
        method: 'PUT',
        data: data
    })
}


export function deleteRole(id) {
    let data = {
        id: id
    }

    return http({
        url: 'role',
        method: 'DELETE',
        data: data
    })
}
//endregion