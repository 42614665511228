<template>
	<div class="container">
		<div class="title">
			<div class="text">帮助管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-2">标题</div>
					<div class="col flex-1 center">中文链接</div>
					<div class="col flex-1 center">维语链接</div>
					<div class="col flex-1 center">哈语链接</div>
					<div class="col flex-1 center">类型</div>
					<div class="col flex-1 center">排序</div>
					<div class="col flex-1 center">状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(help,helpIndex) in helpList" :key="helpIndex">
					<div class="col id">
						{{help.id}}
					</div>
					<div class="col flex-2">{{help.title}}</div>
					<div class="col flex-1 center"><a :href="help.url" target="_black"><button type="button" class="min-btn">跳转</button></a></div>
					<div class="col flex-1 center"><a :href="help.url_ug" target="_black"><button type="button" class="min-btn">跳转</button></a></div>
					<div class="col flex-1 center"><a :href="help.url_kz" target="_black"><button type="button" class="min-btn">跳转</button></a></div>
					<div class="col flex-1 center">
						<span v-if="help.category==1">商务</span>
						<span v-if="help.category==2">帮助</span>
						<span v-if="help.category==3">关于</span>
					</div>
					<div class="col flex-1 center">{{help.top}}</div>
					<div class="col flex-1 center">
						<span v-if="help.status==1">正常</span>
						<span v-if="help.status==0">禁用</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="helpIndex" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="help.id" :data-title="help.title"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>

<script>
	let standById = 0;
	import {
		getHelpList,
		deleteHelp
	} from "@/api/help";
	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
			DialogBox
		},
		data() {
			return {
				helpList: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push(
					"/help/add/"
				);
			},
			reloadUser(page) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "HelpUpdated",
					params: {
						help: encodeURIComponent(JSON.stringify(this.helpList[index]))
					}
				});
			},
			loadData() {
				getHelpList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.helpList = res.data.data;
						this.totalRecords = res.data.total
					}
				});
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteHelp(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>