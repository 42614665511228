<template>
	<div class="container">
		<div class="title">
			<div class="text">更新配件分类</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">所属分类</div>
				<div class="right">
					<select v-model="info.parent_id" class="select">
						<option :value="-1">请选择</option>
						<option :value="0">分类1</option>
						<option :value="1">分类2</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input class="input" v-model="info.category_name" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">维语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.category_name_ug" placeholder="كىرگۈزۈڭ...">
				</div>
			</div>
			<div class="row">
				<div class="left">哈语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.category_name_kz" placeholder="كىرگىزىڭىز...">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateCategory
	} from "@/api/parts.js";
	export default {
		data() {
			return {
				info: {},
				info_orginal:{},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			isLoad = false;
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info))
				this.info_orginal = JSON.parse(decodeURIComponent(this.$route.params.info))
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.color_name) {
					this.showToast("请输入排量名称！")
					return
				}
				let data = this.getChangeData()
				if(data && 0){//移除0后正常使用
					isLoad = true;
					updateCategory(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					if(data==false){
						this.showToast("没有可更新数据")
					}
				}
				
			},
			cancel() {
				this.$router.back()
			},
			getChangeData() {
				let data = {
					id:this.info.id
				}
				let keys = Object.keys(this.info);
				for (var i = 0; i < keys.length; i++) {
					if(this.info[keys[i]] != this.info_orginal[keys[i]] && typeof(this.info[keys[i]]) != 'object'){
						data[keys[i]] = this.info[keys[i]]
					}
				}
				console.log(data)
				if(Object.keys(data).length>1){
					return data
				}else {
					return false 
				}
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>