import http from '../utils/Http.js'
export function getCustomerList(data) {
    return http({
        url: 'customer/list',
        method: 'GET',
        params: data
    })
}

export function updateCustomer(data) {
	// let data = {
	// 	id: id,
	// 	real_name_ug: nameUg,
	// 	real_name_kz: nameKz
	// }
	return http({
		url: 'customer',
		method: 'PUT',
		data: data,
	})
}