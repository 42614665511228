import http from '../utils/Http.js'
export function getCarSecurityConfigList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'car/security/config',
        method: 'GET',
        params: data
    })
}

export function translateCarSecurityConfig(id, nameUg, nameKz) {
    let data = {
        id: id,
        nameUg: nameUg,
        nameKz: nameKz
    }
    return http({
        url: 'car/security/config',
        method: 'POST',
        data: data,
    })
}