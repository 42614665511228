<template>
	<div class="container">
		<div class="title">
			<div class="text">添加词汇</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">分类</div>
				<div class="right">
					<select class="select" v-model="info.category_id">
						<option :value="-1">请选择分类</option>
						<option :value="item.id" v-for="(item,index) in categoryList" :key="index">{{item.category_name}}</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<textarea class="textarea" v-model="info.info_tag"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">维文名称</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="info.info_tag_ug"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">哈文名称</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="info.info_tag_kz"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<select class="select" v-model="info.status">
						<option :value="0">隐藏</option>
						<option :value="1">显示</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.info_sort" @keypress="inputChange" ref="topInput"
						placeholder="数字越大越靠前" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addLexicon,
		getLexiconSortList
	} from "@/api/lexicon";
	export default {
		data() {
			return {
				info: {
					category_id:-1,
					category_name:"sdfs",
					info_tag: "",
					info_tag_ug: "",
					info_tag_kz: "",
					status:1,
					sort: 0
				},
				categoryList:[],
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		mounted() {
			this.getCategoryList()
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.info_tag || !this.info.info_tag.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.info_tag_ug || !this.info.info_tag_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.info_tag_kz || !this.info.info_tag_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				if(this.info.category_id==-1){
					this.showToast("请选择分类")
					return
				}
				this.info.sort = Number(this.info.sort)
				isLoad = true;
				addLexicon(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("添加成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			getCategoryList(){
				getLexiconSortList().then(res=>{
					if(res.status == 200){
						this.categoryList = res.data.data;
					}
				})
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode));
			},
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
