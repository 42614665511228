import http from '../utils/Http.js'
export function getCarTypeList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'car-types',
        method: 'GET',
        params: data
    })
}

export function getCarType() {
    return http({
        url: 'car-type/list',
        method: 'GET',
    })
}

export function createCarType(data) {
    return http({
        url: 'car-type',
        method: 'POST',
        data: data,
    })
}

export function updateCarType(data) {
    return http({
        url: 'car-type',
        method: 'PUT',
        data: data,
    })
}

export function daleteType(id) {
    return http({
        url: 'car-type',
        method: 'DELETE',
        data: {
            id: id
        },
    })

}