import http from '../utils/Http'

// 零件表，接口路径是假的
export function getPartList(data) {
    return http({
        url: 'part/list',
        method: 'GET',
        params: data
    })
}

export function updatePart(data) {
	return http({
		url: 'part',
		method: 'PUT',
		data: data
	})
}
export function deletePart(id) {
    return http({
        url: 'part',
        method: 'DELETE',
		data:{id:id}
    })
}

// 分类表，接口路径是假的
export function getCategoryList(data) {
    return http({
        url: 'parts-categorys',
        method: 'GET',
        params: data
    })
}
export function addCategory(data) {
	return http({
		url: 'parts-category',
		method: 'POST',
		data: data
	})
}
export function updateCategory(data) {
	return http({
		url: 'parts-category',
		method: 'PUT',
		data: data
	})
}
export function deleteCategory(id) {
    return http({
        url: 'parts-category',
        method: 'DELETE',
		data:{id:id}
    })
}
