<template>
	<div class="container">
		<div class="title">
			<div class="text">商家信息</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.merchant_name}">
					<label class="text">名称</label>
					<input class="input" type="text" v-model="filter.merchant_name" placeholder="请输入商家名称..." />
				</div>
				<div class="list-item" :class="{on:filterData.mobile_phone}">
					<label class="text">手机号</label>
					<input class="input" type="text" v-model="filter.mobile_phone"
						placeholder="请输入手机号..." />
				</div>
				<!-- 选择省份-城市 -->
				<div class="list-item" :class="{on:filterData.city_id*1>=0}">
					<label class="text">城市</label>
					<select class="select" value="-1" v-model="filter.province_id" @change="changeCity">
						<option :value="-1">请选择</option>
						<option :value="index" v-for="(item,index) in provinceList" :key="index">{{item.name}}</option>
					</select>
					<select class="select" value="0" v-model="filter.city_id">
						<option :value="-1">请选择</option>
						<option :value="index" v-for="(item,index) in cityList" :key="index">{{item.name}}</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.verification_status*1>=0}">
					<label class="text">认证状态</label>
					<select class="select" v-model="filter.verification_status">
						<option :value="-1">请选择</option>
						<option :value="1">审核通过</option>
						<option :value="0">待审核</option>
						<option :value="2">未通过</option>
						<option :value="3">更新待审核</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.status*1>=0}">
					<label class="text">账号状态</label>
					<select class="select" v-model="filter.status">
						<option :value="-1">请选择</option>
						<option :value="0">关闭</option>
						<option :value="1">正常</option>
						<option :value="2">封号</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadMerchants">搜索</div>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">头像</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">手机号码</div>
					<div class="col flex-2">地址</div>
					<div class="col flex-1">认证状态</div>
					<div class="col flex-1">账号状态</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(merchant, index) in merchants" :key="index">
					<div class="col id">{{ merchant.id }}</div>
					<div class="col avatar">
						<img class="avatar-img" :src="merchant.avatar_url" :alt="merchant.merchant_name" />
					</div>
					<div class="col flex-1">{{ merchant.merchant_name }}</div>
					<div class="col flex-1">{{ merchant.mobile_phone }}</div>
					<div class="col flex-2">{{ merchant.address }}</div>
					<div class="col flex-1">
						<span v-if="merchant.verification_status==1">审核通过</span>
						<span v-if="merchant.verification_status==0">待审核</span>
						<span v-if="merchant.verification_status==2">未通过</span>
						<span v-if="merchant.verification_status==3">更新待审核</span>
					</div>
					<div class="col flex-1">
						<span v-if="merchant.status==0">关闭</span>
						<span v-if="merchant.status==1">正常</span>
						<span v-if="merchant.status==2">封号</span>
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :notLoadData="true" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
	</div>
</template>
<script>
	let store = null;
	import { useStore } from 'vuex'
	import {
		getMerchantList
	} from "@/api/merchant";
	import {
		getProvince,
		getCity
	} from "@/api/common";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
		},
		data() {
			return {
				merchants: [],
				totalRecords: 0,
				infoToMerchants: null,
				filter: {
					merchant_name: "",
					mobile_phone: "",
					verification_status: -1,
					status: -1,
					province_id: -1,
					city_id: -1,
				},
				provinceList: [],
				cityList: [],
				filterData: {
					page: 1,
					size: 20,
				}
			};
		},
		created() {
			store = useStore();
			if(store.getters.getPagination.name == this.$route.name){
				this.filterData.page = store.getters.getPagination.curPage;
			}
			let data = this.$route.params.filterData;
			if (data) {
				data = JSON.parse(decodeURIComponent(data));
				this.filterData = {
					...data,
					page: 1,
					size: 20,
				};
				let keys = Object.keys(data);
				for (var i = 0; i < keys.length; i++) {
					if (this.filter[keys[i]]) {
						this.filter[keys[i]] = data[keys[i]]
					}
				}
			}
			this.reloadMerchants()
			this.getProvinceData()
		},
		methods: {
			loadMerchants() {
				this.filterData = this.getFilter()
				getMerchantList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.merchants = res.data.data;
						this.totalRecords = res.data.total;
						store.dispatch('updatePagination',{
							name:this.$route.name,
							curPage:this.filterData.page
						})
					}
				});
			},
			getProvinceData() {
				getProvince().then(res => {
					if (res.status == 200) {
						this.provinceList = res.data;
						setTimeout(() => {
							this.filter.province_id = 30;
							this.getCityData(this.provinceList[30].area_code)
						}, 300)
					}
				})
			},
			getCityData(code) {
				getCity(code).then(res => {
					if (res.status == 200) {
						this.cityList = res.data
					}
				})
			},
			changeCity(e) {
				let index = e.target.value;
				this.cityList = []
				this.filter.city_id = -1;
				if (index != -1) {
					this.getCityData(this.provinceList[index * 1].area_code)
				}
			},
			reloadMerchants(page = 1) {
				this.filterData.page = page;
				this.loadMerchants();
			},
			onPageChanged(page) {
				this.filterData.page = page;
				this.loadMerchants();
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index;
				this.$router.push({
					name: "MerchantUpdate",
					params: {
						info: encodeURIComponent(JSON.stringify(this.merchants[index]))
					}
				});
			},
			getFilter() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size
				}
				if (this.filter.merchant_name.trim().length > 1) {
					data = {
						...data,
						merchant_name: this.filter.merchant_name.trim()
					}
				}
				if (this.filter.mobile_phone.trim().length) {
					data = {
						...data,
						mobile_phone: this.filter.mobile_phone.trim()
					}
				}
				if (this.filter.verification_status * 1 != -1) {
					data = {
						...data,
						verification_status: this.filter.verification_status * 1
					}
				}
				if (this.filter.status * 1 != -1) {
					data = {
						...data,
						status: this.filter.status * 1
					}
				}
				if (this.filter.city_id * 1 != -1) {
					data = {
						...data,
						province_id: this.filter.province_id * 1,
						city_id: this.filter.city_id * 1
					}
				}
				if (Object.values(data).sort().toString() != Object.values(this.filterData).sort().toString()) {
					data.page = 1;
				}
				return data
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
