<template>
	<div class="home">
		<view class="iconfont icon-zhankai side-toggle" :class="{toggle:!showSide}" @click="toggleSide">
		</view>
		<div class="side" :class="{hide:!showSide}">
			<div class="user-info">
				<div class="avatar">
					<img :src="adminInfo.avatar" />
				</div>
				<div class="usr">{{ adminInfo.real_name }}</div>
				<div class="level">{{ adminInfo.role_name }}</div>
			</div>
			<div class="nav">
				<div class="item-frame" v-for="(menu, index) in menus" :key="index">
					<router-link :to="menu.path" class="nav-item" v-if="menu.type == 0" @click="toggleSideMuneExpand"
						:data-index="index">
						<div class="row">
							<div class="icon">
								<i class="iconfont" :class="[[menu.icon]]"></i>
							</div>
							<div class="text">{{ menu.name }}</div>
							<div class="breach"></div>
						</div>
					</router-link>
					<div class="nav-item" :class="{ expanded: menu.expanded, collapsed: !menu.expanded }"
						v-if="menu.type == 1">
						<div class="row" @click="toggleSideMuneExpand" :data-index="index">
							<div class="icon">
								<i class="iconfont" :class="[[menu.icon]]"></i>
							</div>
							<div class="text">{{ menu.name }}</div>
							<div class="chevron">
								<i class="iconfont icon-chevron-right"></i>
							</div>
						</div>
						<div class="rows">
							<router-link :to="childMenu.path" class="child-item"
								v-for="(childMenu, cmIndex) in menu.children" :key="cmIndex">
								<div class="icon"></div>
								<div class="icon">
									<i class="iconfont icon-weixuanzhong"></i>
								</div>
								<div class="text">{{ childMenu.name }}</div>
								<div class="breach"></div>
							</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="main">
			<div class="top">
				<div class="top-left">
					<div class="top-item" @click="setMenus(0)" :class="{selected:menuIndex==0}">
						<div class="text">常用</div>
					</div>
					<div class="top-item" @click="setMenus(1)" :class="{selected:menuIndex==1}">
						<div class="text">汽车</div>
					</div>
					<div class="top-item" @click="setMenus(2)" :class="{selected:menuIndex==2}">
						<div class="text">房产</div>
					</div>
					<div class="top-item" @click="setMenus(3)" :class="{selected:menuIndex==3}">
						<div class="text">处理</div>
					</div>
					<div class="top-item" @click="setMenus(4)" :class="{selected:menuIndex==4}">
						<div class="text">应用</div>
					</div>
					<div class="top-item" @click="setMenus(5)" :class="{selected:menuIndex==5}">
						<div class="text">全部</div>
					</div>
				</div>
				<div class="top-right">
					<div class="top-item setInfoList">
						<i class="iconfont icon-shezhi"></i>
						<div class="lists">
							<div class="list" @click="updateUserInfo" title="修改信息">
								<i class="iconfont icon-shezhi"></i>
								<span>修改信息</span>
							</div>
						</div>
					</div>
					<div class="top-item" title="安全退出" @click="logout">
						<i class="iconfont icon-log-out"></i>
					</div>
				</div>
				
			</div>
			<div class="land">
				<router-view />
			</div>
		</div>
	</div>
</template>

<script>
	let menus = []
	import {
		getSideMenu
	} from "@/api/common.js";
	export default {
		name: "Home",
		components: {},
		data() {
			return {
				adminInfo: null,
				menus: [],
				menuList: [],
				menuIndex:0,
				usedCarMenus:[],
				expended: false,
				showSide:true,
				navIndex:0
			};
		},
		created() {
			this.checkToken();
			this.loadAdminInfo();
			this.loadMenu();
		},
		methods: {
			checkToken() {
				let chk =
					localStorage.getItem("loginData") == null ||
					localStorage.getItem("token") == null;

				if (chk) {
					this.$router.replace("/login");
				}
			},
			updateUserInfo() {
				this.$router.push("/admin/user/update-info");
			},
			logout() {
				localStorage.removeItem("loginData");
				localStorage.removeItem("token");
				this.$router.replace("/login");
			},
			loadAdminInfo() {
				this.adminInfo = JSON.parse(localStorage.getItem("loginData"));
			},
			loadMenu() {
				getSideMenu().then((res) => {
					if (res.status === 200) {
						menus = res.data;
						this.setMenus(0)
					}
				});
			},
			setMenus(e){
				for (let i = 0; i < this.menus.length; i++) {
					this.menus[i].expanded = false;
				}
				this.menuIndex = e;
				let arr = [];
				if(e==0){//常用功能
					let ids = [1,24,16,18,31,59,32,51,73];
					menus.forEach(item=>{
						if(ids.includes(item.id)){
							arr.push(item)
						}
					})
					this.menus = arr;
				}else if(e==1){//汽车
					let ids = [18,62,66,68,46];
					menus.forEach(item=>{
						if(ids.includes(item.id)){
							arr.push(item)
						}
					})
					this.menus = arr;
				}else if(e==2){//房产
					let ids = [];
					menus.forEach(item=>{
						if(ids.includes(item.id)){
							arr.push(item)
						}
					})
					this.menus = arr;
				}else if(e==3){//处理
					let ids = [7,41,14,23];
					menus.forEach(item=>{
						if(ids.includes(item.id)){
							arr.push(item)
						}
					})
					this.menus = arr;
				}else if(e==4){//应用
					let ids = [2,58,56,51,40,73,31];
					menus.forEach(item=>{
						if(ids.includes(item.id)){
							arr.push(item)
						}
					})
					this.menus = arr;
				}else if(e==5){//全部
					this.menus = menus;
				}
				this.showSide = true;
			},
			toggleSideMuneExpand(e) {
				let index = Number(e.currentTarget.dataset.index);
				this.menus[index].expanded = !this.menus[index].expanded;
				// if (this.menus[index].expanded) {
				// 	for (let i = 0; i < this.menus.length; i++) {
				// 		if (this.menus[i].expanded && i != index) {
				// 			this.menus[i].expanded = false;
				// 		}
				// 	}
				// }
			},
			toggleSide(){
				if(this.showSide){
					for (let i = 0; i < this.menus.length; i++) {
						this.menus[i].expanded = false;
					}
				}
				this.showSide = !this.showSide
			}
		},
	};
</script>
<style lang="less" scoped>
	@sideWidth: 240px;
	@topHeight: 60px;

	@sideNavItemHeight: 40px;
	.home {
		position: fixed;
		display: flex;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		background: white;
		
		.side-toggle {
			position: fixed;
			left: 10px;
			bottom: 20px;
			z-index: 99;
			font-size: 30px;
			color: #f5af19;
			transform: scale(-1);
			
			&.toggle {
				color: #f12711;
				transform: scale(1);
			}
		}
		
		.side {
			width: @sideWidth;
			background: white;
			user-select: none;
			height: 100%;
			overflow-y: scroll;
			overflow-x: hidden;
			padding-bottom: 50px;
			box-sizing: border-box;
			transition: all 200ms;
			
			&.hide {
				width: 0;
				overflow-y: hidden;
				opacity: 0;
			}

			.user-info {
				height: @sideWidth / 1.2;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;

				.avatar {
					width: @sideWidth / 3;
					height: @sideWidth / 3;

					img {
						width: 100%;
						height: 100%;
						border-radius: 50%;
					}
				}

				.usr {
					font-size: 16px;
					padding: 8px 0 4px 0;
					white-space: nowrap;
					overflow-x: hidden;
				}

				.level {
					color: #ccc;
					white-space: nowrap;
					overflow-x: hidden;
				}
			}

			.nav {
				.nav-item {
					cursor: pointer;

					&.collapsed {
						height: @sideNavItemHeight;
						overflow: hidden;
					}

					&.router-link-exact-active {
						color: #f12711;

						.breach {
							&::after {
								content: "◄";
								color: #efefef;
								transform: scale(1.1) translateX(4px);
							}
						}
					}

					.row {
						height: @sideNavItemHeight;
						display: flex;
						white-space: nowrap;

						.icon {
							width: @sideNavItemHeight;
							display: flex;
							justify-content: center;
							align-items: center;

							.iconfont {
								font-size: 24px;
							}
						}

						.text {
							flex: 1;
							display: flex;
							align-items: center;
							font-size: 16px;
						}

						.breach {
							width: @sideNavItemHeight;
							height: @sideNavItemHeight;
							display: flex;
							justify-content: flex-end;
							align-items: center;
						}

						.chevron {
							width: @sideNavItemHeight;
							height: @sideNavItemHeight;
							display: flex;
							justify-content: center;
							align-items: center;

							.iconfont {
								transition: transform 400ms;
							}
						}
					}

					&.expanded {
						.row {
							.chevron {
								.iconfont {
									transform: rotate(90deg);
								}
							}
						}
					}

					.rows {
						.child-item {
							display: flex;
							white-space: nowrap;
							overflow-x: hidden;

							&.router-link-exact-active {
								color: #f12711;

								.breach {
									&::after {
										content: "◄";
										color: #efefef;
										transform: scale(1.1) translateX(4px);
									}
								}
							}

							.icon {
								width: @sideNavItemHeight;
								display: flex;
								justify-content: center;
								align-items: center;

								.iconfont {
									font-size: 16px;
								}
							}

							.text {
								flex: 1;
								display: flex;
								align-items: center;
								font-size: 16px;
							}

							.breach {
								width: @sideNavItemHeight;
								height: @sideNavItemHeight;
								display: flex;
								justify-content: flex-end;
								align-items: center;
							}
						}
					}
				}
			}
		}

		.main {
			flex: 1;
			display: flex;
			flex-direction: column;

			.top {
				height: @topHeight;
				background: #fff;
				display: flex;
				justify-content: space-between;
				padding: 0 32px;
				box-sizing: border-box;
				font-size: 15px;
				
				
				.top-left {
					display: flex;
					align-items: center;
					white-space: nowrap;
				}
				.top-right {
					display: flex;
					align-items: center;
					white-space: nowrap;
					
					.top-item:hover {
						color: #f12711;
						border-bottom: none;
						box-sizing: border-box;
						opacity: 1;
					}
				}
				
				.top-item {
					height: @topHeight;
					cursor: pointer;
					display: flex;
					margin-right: 20px;
					align-items: center;
					position: relative;
					font-weight: bold;
					opacity: 0.8;
					padding: 0 16px;
					transition: all 100ms;
					
					&:nth-last-child(1){
						margin-right: 0;
					}
				
					.iconfont {
						font-size: 18px;
					}
				
					&:hover {
						color: #f12711;
						border-bottom: 3px solid #f12711;
						box-sizing: border-box;
						opacity: 0.9;
					}
					
					&.selected {
						color: #f12711;
						border-bottom: 3px solid #f12711;
						box-sizing: border-box;
						opacity: 1;
					}
					
					&:hover .lists {
						opacity: 1;
						height:25px;
						box-shadow: 0 0 2px #c0c0c0;
					}
					
					.lists {
						position: absolute;
						height: 0;
						opacity: 0;
						overflow: hidden;
						transition: all 0.2s;
						left: 16px;
						top: 50px;
						z-index: 99;
						padding: 5px 5px;
						border-radius: 5px;
						background-color: #fff;
					
						.list {
							height: 25px;
							line-height: 25px;
							font-size: 12px;
							color: #50332c;
							position: relative;
							
							.list-item {
								position: absolute;
								left: 100%;
								top: 0;
								width: auto;
							}
						}
					
						.list .iconfont {
							margin-right: 3px;
							font-size: 12px;
						}
					
						.list span {
							display: inline-block;
						}
					
						.list:hover {
							color: #f12711;
						}
					}
				}
				
			}

			.land {
				flex: 1;
				background: #efefef;
				border-radius: 16px 0 0 0;
				overflow-y: scroll;
			}
		}
	}
</style>
