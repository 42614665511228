<template>
  <div class="container" :class="{ shown: shown, fading: fading }">
    <div class="box" :class="{ fading: fading }">
      <div class="title">区域翻译</div>
      <div class="form">
        <div class="row">
          <label for="modelName">名称</label>
          <input
            type="text"
            id="Name"
            v-model="name"
            spellcheck="false"
            autocomplete="false"
          />
        </div>

        <div class="ug-area" v-if="ug || !(ug || kz)">
          <div class="row sep">
            <div class="sep-title">维吾尔语</div>
          </div>

          <div class="row">
            <label for="nameUg">名称</label>
            <div class="input-area">
              <input
                class="rtl"
                type="text"
                id="nameUg"
                v-model="name_ug"
                spellcheck="false"
                autocomplete="false"
              />
              <button
                type="button"
                @click="translate"
                data-to="uy"
                data-source="name"
                data-target="name_ug"
              >
                翻译
              </button>
            </div>
          </div>
        </div>
        <div class="kz-area" v-if="kz || !(ug || kz)">
          <div class="row sep">
            <div class="sep-title">哈萨克语</div>
          </div>

          <div class="row">
            <label for="nameKz">名称</label>
            <div class="input-area">
              <input
                class="rtl"
                type="text"
                id="nameKz"
                v-model="name_kz"
                spellcheck="false"
                autocomplete="false"
              />
              <button
                type="button"
                @click="translate"
                data-to="ka"
                data-source="name"
                data-target="name_kz"
              >
                翻译
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <button class="cancel" @click="dismiss">取消</button>
        <button class="confirm" @click="save">保存</button>
      </div>
    </div>
  </div>
</template>
<script>
import { translate } from "@/api/translate.js";
import { translateCity } from "@/api/city.js";

export default {
  emits: ["confirm"],
  props: {
    info: {
      type: Object,
      default: null,
    },
    ug: {
      type: Boolean,
      default: false,
    },
    kz: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    info(val) {
      if (val === null) return;
      this.name = val.name;
      this.name_ug = val.name_ug;
      this.name_kz = val.name_kz;
    },
  },
  data() {
    return {
      name: "",
      name_ug: "",
      name_kz: "",
      shown: false,
      fading: false,
      loading: false,
    };
  },
  methods: {
    show() {
      this.shown = true;
      setTimeout(() => {
        this.fading = true;
      }, 10);
    },
    dismiss() {
      this.fading = false;
      setTimeout(() => (this.shown = false), 400);
    },
    confirm() {
      this.$emit("confirm");
      this.dismiss();
    },
    save() {
      let id = this.info.id;
      let nameUg = this.name_ug === null ? "" : this.name_ug.trim();
      let nameKz = this.name_kz === null ? "" : this.name_kz.trim();
      if (this.loading) return;
      this.loading = true;
      translateCity(id, nameUg, nameKz).then(
        (res) => {
          if (res.status == 200) {
            this.$emit("confirm");
            this.dismiss();
          }
          this.loading = false;
        }
      );
    },
    translate(e) {
      let source = e.currentTarget.dataset.source;
      let target = e.currentTarget.dataset.target;
      let to = e.currentTarget.dataset.to;
        translate(this[source], to).then((res) => {
          if (res.status == 200) {
            this[target] = res.data;
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
@titleHeight: 50px;
@bottomHeight: 66px;
@buttonHeight: 40px;

@inputHeight: 36px;

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.618);
  transition: opacity 400ms;
  user-select: none;

  &.shown {
    display: flex;
  }

  &.fading {
    opacity: 1;
  }

  .box {
    display: flex;
    flex-direction: column;
    width: 620px;
    min-height: 400px;
    border-radius: 16px;
    background: white;
    transform: scale(0.1);
    transition: transform 400ms;

    &.fading {
      transform: scale(1);
    }

    .title {
      height: @titleHeight;
      line-height: @titleHeight;
      font-size: 16px;
      text-align: center;
    }
    .form {
      flex: 1;
      padding: 0 32px 16px 32px;

      .row {
        display: flex;
        flex-direction: column;
        margin-bottom: 16px;

        label {
          margin-bottom: 4px;
        }
        input[type="text"] {
          border: none;
          height: @inputHeight;
          border-radius: @inputHeight / 2;
          padding: 0 16px;
          background: #f7f8f9;
        }

        &.sep {
          align-items: center;
          height: @inputHeight;
          line-height: @inputHeight;
          position: relative;
          text-align: center;
          color: #ccc;
          &::before {
            position: absolute;
            left: 0;
            top: 0;
            z-index: -1;
            content: " ";
            width: 100%;
            height: @inputHeight / 2;
            border-bottom: 1px #e5e5e5 solid;
          }

          .sep-title {
            display: inline-block;
            background: white;
            width: 100px;
          }
        }

        .input-area {
          display: flex;
          input[type="text"] {
            flex: 1;
          }
          button {
            margin-left: 16px;
            border: none;
            width: @inputHeight * 2;
            border-radius: @inputHeight / 2;
            background: linear-gradient(-220deg, #36d1dc, #5b86e5);
            background-size: 200% @titleHeight;
            cursor: pointer;
            color: white;
            text-shadow: #5b86e5 0 1px 4px;
            box-shadow: #36d1dc66 0 4px 16px;
            transition: background-position-x 400ms;

            &:hover {
              background-position-x: 100%;
            }
          }
        }
      }
    }
    .bottom {
      height: @bottomHeight;
      display: flex;

      button {
        flex: 1;
        border: none;
        height: @buttonHeight;
        border-radius: @buttonHeight / 2;
        margin: 0 32px;
        cursor: pointer;

        &.confirm {
          background: linear-gradient(-220deg, #f5af19, #f12711);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #f12711 0 1px 4px;
          box-shadow: #f5af1966 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }

        &.cancel {
          background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #2c3e50 0 1px 4px;
          box-shadow: #bdc3c766 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }
      }
    }
  }
}
</style>