<template>
	<div class="container">
		<div class="title">
			<div class="text">二手车信息</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.id}">
					<label class="text">汽车id</label>
					<input class="input input-mini" type="number" v-model="filter.id" />
				</div>
				<div class="list-item" :class="{on:filterData.car_title}">
					<label class="text">标题</label>
					<input class="input" type="text" v-model="filter.car_title" />
				</div>
				<div class="list-item" :class="{on:filterData.vin_code}">
					<label class="text">车架号</label>
					<input class="input" type="text" v-model="filter.vin_code" />
				</div>
				<div class="list-item" :class="{on:filterData.min_price && filterData.max_price }">
					<label class="text">报价</label>
					<input class="input price" type="number" v-model="filter.min_price"
						@keypress="inputChange" />-<input class="input price" type="number" v-model="filter.max_price"
						@keypress="inputChange" />
				</div>
				<div class="list-item" :class="{on:filterData.car_type>=1}">
					<label class="text">分类</label>
					<select class="select" @change="onSelectCayType" v-model="selectCarType">
						<option :value="-1">请选择</option>
						<option v-for="(item,index) in carType" :key="index" :value="item.id">{{item.name}}</option>
					</select>
					<select class="select" @change="onSelectCayTypeChild" v-model="filter.car_type">
						<option :value="-1">请选择</option>
						<option v-for="(item,index) in carTypeChild" :key="index" :value="item.id">{{item.name}}
						</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.min_time && filterData.max_time}">
					<label class="text">日期</label>
					<input class="input date" type="date" v-model="filter.min_time" />-<input class="input date"
						type="date" v-model="filter.max_time" />
				</div>
				<div class="list-item" :class="{on:filterData.status>=0}">
					<label class="text">状态</label>
					<select class="select-mini" v-model="filter.status">
						<option value="-1">请选择</option>
						<option value="0">审 核 中</option>
						<option value="1">已通过审核</option>
						<option value="2">未通过审核</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadCars">搜索</div>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">封面</div>
					<div class="col flex-2">标题</div>
					<div class="col price">报价</div>
					<div class="col flex-1">过户次数</div>
					<div class="col flex-1">上牌日期</div>
					<div class="col flex-1">排量</div>
					<div class="col flex-1">所在城市</div>
					<div class="col flex-1">状态</div>
					<div class="col flex-2">日期</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(car, index) in cars" :key="index">
					<div class="col id">{{ car.id }}</div>
					<div class="col avatar">
						<img class="cover-img" :onerror="defaultImg" :src="car.main_photo" />
					</div>
					<div class="col flex-2">
						<span class="tag">{{car.car_type_name}}</span>
						<span>{{car.car_title}}</span>
					</div>
					<div class="col price">
						<span>{{ car.total_price }}</span>
					</div>
					<div class="col flex-1">{{ car.transfer_count }}</div>
					<div class="col flex-1">{{ car.on_plate_date }}</div>
					<div class="col flex-1">
						<span>{{ car.output_volume }}</span>
					</div>
					<div class="col flex-1">
						<span>{{ car.city_name }}</span>
					</div>
					<div class="col flex-1">
						<span v-if="car.status==0">审核中</span>
						<span v-if="car.status==1">已通过</span>
						<span v-if="car.status==2">未通过</span>
						<span v-if="car.status==3">已销售</span>
					</div>
					<div class="col flex-2">{{ car.created_at }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-id="car.id" :data-index="index"
							@click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="翻译" :data-id="car.id" :data-index="index"
							@click="toTranslatePage">
							<i class="iconfont icon-fanyi-full"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="car.id"
							:data-title="car.vin?car.vin.vin_name:'id:'+car.id" @click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :notLoadData="true" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
		<used-car-update-modal ref="UsedCarUpdateModal" :info="infoToCars" v-on:confirm="showModal" />
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>
<script>
	let standById = 0;
	let store = null;
	import {
		useStore
	} from 'vuex'

	import {
		getUsedCarList,
		deleteUsedCar,
		getCarTypeList,
	} from "@/api/used-car";
	import UsedCarUpdateModal from "@/components/used-car/UsedCarUpdateModal.vue";
	import Paginator from "@/components/widgets/Paginator.vue";
	import DialogBox from "@/components/widgets/DialogBox";

	export default {
		components: {
			UsedCarUpdateModal,
			Paginator,
			DialogBox
		},
		data() {
			return {
				cars: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToCars: null,
				filterIndex: -1,
				filter: {
					id: "",
					car_title: "",
					vin_code: "",
					min_price: "",
					max_price: "",
					min_time: "2021-12-03",
					max_time: "",
					status: "-1",
					car_type: -1,
				},
				carType: [],
				selectCarType: -1,
				carTypeChild: [],
				filterData: {
					page: 1,
					size: 20,
				},
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				dialogContent: "",
			};
		},
		created() {
			let data = this.$route.params.filterData;
			if (data) {
				data = JSON.parse(decodeURIComponent(data));
				this.filterData = {
					...data,
					page: 1,
					size: 20,
				};
				let keys = Object.keys(data);
				for (var i = 0; i < keys.length; i++) {
					if (this.filter[keys[i]]) {
						this.filter[keys[i]] = data[keys[i]]
					}
				}
			}
			store = useStore();
			if (store.getters.getPagination.name == this.$route.name) {
				this.filterData.page = store.getters.getPagination.curPage;
			}
			this.loadCars()
		},
		methods: {
			toEditPage(e) {
				let id = e.currentTarget.dataset.id;
				this.$router.push({
					name: "UsedCarUpdate",
					params: {
						id: id
					}
				});
			},
			toTranslatePage(e) {
				this.$router.push(
					"/used/car/translate/" + e.currentTarget.dataset.id
				);
			},
			loadCars() {
				this.cars = []
				this.filterData = this.getFilterData();
				getUsedCarList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.cars = res.data.data;
						this.totalRecords = res.data.total;

						store.dispatch('updatePagination', {
							name: this.$route.name,
							curPage: this.filterData.page
						})
					}
					if (this.carType.length == 0) {
						this.getCarTypeListData(0);
					}
				});
			},
			getCarTypeListData(e) {
				getCarTypeList(e).then(res => {
					if (res.status == 200) {
						if (e == 0) {
							this.carType = res.data;
						} else {
							this.carTypeChild = res.data
						}
					}
				})
			},
			onSelectCayType() {
				this.filter.car_type = -1;
				this.carTypeChild = []
				if (this.selectCarType != -1) {
					this.getCarTypeListData(this.selectCarType)
				}
			},
			onSelectCayTypeChild() {},
			getFilterData() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size
				}
				if (this.filter.id != "" && this.filter.id > 0) {
					data = {
						...data,
						id: this.filter.id
					}
				}
				if (this.filter.car_title.trim().length > 1) {
					data = {
						...data,
						car_title: this.filter.car_title.trim()
					}
				}
				if (this.filter.vin_code.trim().length > 1) {
					data = {
						...data,
						vin_code: this.filter.vin_code.trim()
					}
				}
				if ((this.filter.min_price != "" && this.filter.max_price != "") && (this.filter
						.min_price * 1 >= 0 && this.filter.max_price * 1 >= 0)) {
					data = {
						...data,
						min_price: this.filter.min_price,
						max_price: this.filter.max_price,
					}
				}
				if (this.filter.min_time != "" && this.filter.max_time != "") {
					data = {
						...data,
						min_time: this.filter.min_time,
						max_time: this.filter.max_time,
					}
				}
				if (this.filter.status * 1 != -1) {
					data = {
						...data,
						status: this.filter.status * 1
					}
				}
				if (this.filter.car_type * 1 != -1) {
					data = {
						...data,
						car_type: this.filter.car_type * 1
					}
				}
				if (Object.values(data).sort().toString() != Object.values(this.filterData).sort().toString()) {
					data.page = 1;
				}
				return data
			},
			reloadCars(page = 1) {
				this.filterData.page = page;
				this.loadCars();
			},
			onPageChanged(page) {
				this.filterData.page = page;
				this.loadCars();
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showModal(e) {
				if (typeof e == undefined) return;
				let index = e.currentTarget.dataset.index;
				this.infoToCars = JSON.parse(JSON.stringify(this.cars[index]));
				this.$refs.UsedCarUpdateModal.show();
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.title + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteUsedCar(standById).then((res) => {
					if (res.status == 200) {
						self.loadCars();
					}
				});
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");

	@operationWidth: 150px;

	.container {
		.table .tag {
			border: 1px solid #333;
			padding: 0 5px;
			box-sizing: border-box;
			border-radius: 3px;
			margin-right: 5px;
		}

		.cover-img {
			width: 50px;
			max-height: 80px;
			overflow: hidden;
			border-radius: 3px;
		}


	}
</style>
