<template>
	<div class="container">
		<div class="title">
			<div class="text">城市管理</div>
		</div>
		<div class="filter">
			<div class="list">
				<!-- 选择省份-城市 -->
				<div class="list-item" :class="{on:filterData.parent_code*1>=0}">
					<label class="text">省份</label>
					<select class="select" value="-1" v-model="filterData.parent_code" @change="changeParentCode">
						<option :value="item.area_code" v-for="(item,index) in provinceList" :key="index">{{item.name}}
						</option>
					</select>
				</div>
				<div class="list-item opacity-0">
					<div class="search-btn" @click="loadCity">搜索</div>
				</div>
			</div>
		</div>

		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维文</div>
					<div class="col flex-1">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in city" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
		<city-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadCity" />
	</div>
</template>
<script>
	import {
		getCityList
	} from "@/api/city";
	import {
		getProvince,
		getCity
	} from "@/api/common";
	import Paginator from "@/components/widgets/Paginator.vue";
	import CityTranslateModal from "@/components/city/CityTranslateModal.vue";
	export default {
		components: {
			Paginator,
			CityTranslateModal,
		},
		data() {
			return {
				routes: [],
				city: [],
				provinceList: [],
				cityList: [],
				filterData: {
					page: 1,
					size: 20,
					parent_code: 650000000000 //新疆省的代码
				},
				totalRecords: 0,
				infoToTranslate: null,
			};
		},
		created() {
			this.getProvinceData()
		},
		methods: {
			loadCity() {
				getCityList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.city = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			changeParentCode() {
				this.filterData.page = 1;
				this.loadCity()
			},
			getProvinceData() {
				getProvince().then(res => {
					if (res.status == 200) {
						this.provinceList = res.data;
					}
					if(this.city.length==0){
						this.loadCity()
					}
				})
			},
			getCityData(code) {
				getCity(code).then(res => {
					if (res.status == 200) {
						this.cityList = res.data
					}
				})
			},
			reloadCity(page = 0) {
				if (page > 0) {
					this.filterData.page = page
				}
				this.loadCity();
			},
			onPageChanged(page) {
				this.reloadCity(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.city[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");

	@inputWidth: 120px;

	.opacity-0 {
		height: 0;
		overflow: hidden;
		opacity: 0;
	}
</style>
