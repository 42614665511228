import http from "../utils/Http.js"

export function getRoportLogList(data) {
	return http({
		url: 'report-logs',
		method: 'GET',
		params: data
	})
}

export function updateRoportLog(e) {
	let data = {
		id: e.id,
		punish: e.status == 2
	}
	return http({
		url: 'report-log/finish',
		method: 'PUT',
		data: data
	})
}

export function startHandleRoportLog(id) {
	return http({
		url: 'report-log/start-handle/'+id,
		method: 'GET'
	})
}


export function getRoportOptionList() {
	return http({
		url: 'report-options',
		method: 'GET'
	})
}

export function getRoportOption(id) {
	return http({
		url: 'report-option/' + id,
		method: 'GET'
	})
}

export function addRoportOption(e) {
	let data = {
		name: e.name,
		name_ug: e.nameUg,
		name_kz: e.nameKz
	}
	return http({
		url: 'report-option',
		method: 'POST',
		data: data
	})
}

export function updateRoportOption(data) {
	return http({
		url: 'report-option',
		method: 'PUT',
		data: data
	})
}
