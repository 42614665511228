<template>
	<div class="container">
		<div class="title">
			<div class="text">添加</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文标题</div>
				<div class="right">
					<input type="text" class="input" v-model="info.name" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">维文标题</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_ug" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">哈文标题</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_kz" placeholder="">
				</div>
			</div>
			<!-- <div class="row">
				<div class="left">排序</div>
				<div class="right">
					<input type="number" class="input" v-model="info.top" @keypress="inputChange" placeholder="请输入排序号(越大越靠后)">
				</div>
			</div> -->
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addRoportOption
	} from "@/api/report";
	export default {
		data() {
			return {
				info: {
					name: "",
					name_ug: "",
					name_kz: ""
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {

		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.name || !this.info.name.trim()) {
					this.showToast("请输入中文标题")
					return
				}
				if (!this.info.name_ug || !this.info.name_ug.trim()) {
					this.showToast("请输入维文标题")
					return
				}
				if (!this.info.name_kz || !this.info.name_kz.trim()) {
					this.showToast("请输入哈文标题")
					return
				}
				// if (this.info.top == undefined || this.info.top == "" || this.info.top == null) {
				// 	this.showToast("请输入排序号")
				// 	return
				// }
				// if(this.info.top*1>=0){
				// 	this.info.top = this.info.top*1
				// }else {
				// 	this.showToast("请正确输入排序号")
				// 	return
				// }
				this.info.name = this.info.name.trim()
				this.info.name_ug = this.info.name_ug.trim()
				this.info.name_kz = this.info.name_kz.trim()
				isLoad = true;
				addRoportOption(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("添加成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>