import {
	createStore
} from 'vuex'

export default createStore({
	state: {
		pagination: {
			name: "",
			curPage: 1
		}
	},
	mutations: {
		setPagination(state, value) {
			state.pagination = value
		},
		
	},
	actions: {
		updatePagination(context, value) {
			context.commit('setPagination', value)
		}
	},
	getters: {
		getPagination(state){
			return state.pagination
		}
	},
	modules: {
	}
})
