<template>
	<div class="container">
		<div class="title">
			<div class="text">帮助管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-2">中文名称</div>
					<div class="col flex-2">维文名称</div>
					<div class="col flex-2">哈文名称</div>
					<div class="col flex-1 center">热度</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{item.id}}
					</div>
					<div class="col flex-2">
						{{item.name}}
					</div>
					<div class="col flex-2 rtl-left">
						{{item.name_ug}}
					</div>
					<div class="col flex-2 rtl-left">
						{{item.name_kz}}
					</div>
					<div class="col flex-1 center">
						{{item.hit_count}}
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getRoportOptionList
	} from "@/api/report";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator
		},
		data() {
			return {
				dataList: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created(){
			this.loadData()
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name:"ReportOptionAdd"
				});
			},
			reloadUser(page) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "ReportOptionUpdate",
					params: {
						id: this.dataList[index].id
					}
				});
			},
			loadData() {
				getRoportOptionList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data;
						// this.totalRecords = res.data.total
						this.totalRecords = 1
					}
				});
			}
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>