<template>
	<div class="container">
		<div class="title">
			<div class="text">二手车-详细信息</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img v-if="info.merchant" :src="info.merchant.avatar_url" alt="" class="logo-img"
						:data-url="info.merchant.avatar_url" @click="openPreview" />
					<img v-else-if="info.customer" :src="info.customer.avatar_url" alt="" class="logo-img"
						:data-url="info.customer.avatar_url" @click="openPreview" />
				</div>
				<div class="logo-title" v-if="info.customer">
					{{ info.customer.real_name }}
				</div>
				<div class="logo-title" v-if="info.merchant">
					{{ info.customer.merchant_name }}
				</div>
			</div>
			<div class="row">
				<div class="left">用户类型</div>
				<div class="right">
					<div class="text" v-if="info.merchant">
						<span>商家</span>
						<span class="badge" v-if="info.merchant.verification_status == 0">待审核</span>
						<span class="badge badge-success" v-if="info.merchant.verification_status == 1">已经通过认证</span>
						<span class="badge badge-yellow" v-if="info.merchant.verification_status == 2">未通过认证</span>
						<span class="badge badge-red" v-if="info.merchant.verification_status == 3">更新待审核</span>
					</div>
					<div class="text" v-else-if="info.customer">
						<span>普通用户</span>
						<span class="badge" v-if="info.customer.verification_status == 0">未申请认证</span>
						<span class="badge badge-success" v-if="info.customer.verification_status == 1">已经通过认证</span>
						<span class="badge badge-yellow" v-if="info.customer.verification_status == 2">待审核认证</span>
						<span class="badge badge-red" v-if="info.customer.verification_status == 3">未通过认证</span>
					</div>

				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<div class="text" v-if="info.merchant">
						{{ info.merchant.mobile_phone }}
					</div>
					<div class="text" v-else-if="info.customer">
						{{ info.customer.mobile_phone }}
					</div>
				</div>
			</div>
			<!-- 车辆信息 -->
			<div v-if="info && info.car">
				<div class="row">
					<div class="left">中文标题</div>
					<div class="right">
						<div class="text">{{info.car.car_title}}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">维文标题</div>
					<div class="right">
						<div class="text rtl">{{info.car.car_title_ug}}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">哈文标题</div>
					<div class="right">
						<div class="text rtl">{{info.car.car_title_kz}}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">分类</div>
					<div class="right">
						<select class="select" @change="onSelectCayType" v-model="info.car.car_type">
							<option v-for="(item,index) in carType" :key="index" :value="item.id">{{item.name}}</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="left">子分类</div>
					<div class="right">
						<select class="select" v-model="info.car.car_type_child_id">
							<option :value="-1">请选择</option>
							<option v-for="(item,index) in carTypeChild" :key="index" :value="item.id">{{item.name}}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="left">车架号</div>
					<div class="right">
						<div class="text">{{ info.car.vin_code }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">品牌</div>
					<div class="right">
						<div class="text">{{ info.car.brand_name }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">车系</div>
					<div class="right">
						<!-- <select v-if="info.car.series_id && info.car.series_id != -1" class="select"
							v-model="info.car.series_id" @change="onSelectSeriesId">
							<option :value="-1">新添加</option>
							<option v-for="(item, index) in carSeries" :key="index" :value="item.id">{{item.name}}
							</option>
						</select> -->
						<div>
							<select class="select" v-model="info.car.series_id" @change="onSelectSeriesId">
								<option :value="-1">新添加</option>
								<option v-for="(item, index) in carSeries" :key="index" :value="item.id">{{item.name}}
								</option>
							</select>
							<div class="language">
								<input class="before" type="text" v-model="info.car.series_name" />
								<i class="after">中文</i>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="info.car.series_name_ug" />
								<i class="after">维文</i>
								<div class="after translate-btn" type="button" @click="translateData" data-to="uy"
									data-source="series_name" data-target="series_name_ug">翻译</div>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="info.car.series_name_kz" />
								<i class="after">哈文</i>
								<div class="after translate-btn" type="button" @click="translateData" data-to="ka"
									data-source="series_name" data-target="series_name_kz">翻译</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-if="!info.car.series_id || info.car.series_id == -1">
					<div class="left">制造商</div>
					<div class="right">
						<div v-if="info.car.series_id && info.car.series_id != -1" class="text">
							{{ manufacturer.manufacturer_name }}
						</div>
						<div v-else>
							<div class="language">
								<input class="before" type="text" v-model="manufacturer.manufacturer_name" />
								<i class="after">中文</i>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="manufacturer.manufacturer_name_ug" />
								<i class="after">维文</i>
								<div class="after translate-btn" type="button" @click="translateManufacturer"
									data-to="uy" data-source="manufacturer_name" data-target="manufacturer_name_ug">翻译
								</div>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="info.car.manufacturer_name_kz" />
								<i class="after">哈文</i>
								<div class="after translate-btn" type="button" @click="translateManufacturer"
									data-to="ka" data-source="manufacturer_name" data-target="manufacturer_name_kz">翻译
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row"
					v-if="(info.car.series_id && info.car.series_id != -1) || (info.car.model_id && info.car.model_id != -1)">
					<div class="left" v-if="info.car.series_id && info.car.series_id != -1">车型</div>
					<div class="right" v-if="info.car.series_id && info.car.series_id != -1">
						<!-- <select v-if="info.car.model_id && info.car.model_id !=-1" class="select"
							v-model="info.car.model_id">
							<option :value="-1">新添加</option>
							<option v-for="(item, index) in carModel" :key="index" :value="item.id">{{item.name}}
							</option>
						</select> -->
						<div>
							<select class="select" v-model="info.car.model_id" @change="onSelectModelId">
								<option :value="-1">新添加</option>
								<option v-for="(item, index) in carModel" :key="index" :value="item.id">{{item.name}}
								</option>
							</select>
							<div class="language">
								<input class="before" type="text" v-model="info.car.model_name" />
								<i class="after">中文</i>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="info.car.model_name_ug" />
								<i class="after">维文</i>
								<div class="after translate-btn" type="button" @click="translateData" data-to="uy"
									data-source="model_name" data-target="model_name_ug">翻译</div>
							</div>
							<div class="language">
								<input class="before rtl" type="text" v-model="info.car.model_name_kz" />
								<i class="after">哈文</i>
								<div class="after translate-btn" type="button" @click="translateData" data-to="ka"
									data-source="model_name" data-target="model_name_kz">翻译</div>
							</div>

						</div>
					</div>
				</div>
				<div class="brand-btn" v-if="!info.car.model_id || info.car.model_id == -1">
					<div class="mini-btn" @click="updateBrand">添加车系/车型</div>
				</div>
				<div class="brand-btn" v-else>
					<div class="mini-btn" @click="updateBrand">更新车系/车型</div>
				</div>
				<div class="row">
					<div class="left">标签</div>
					<div class="right">
						<select class="select" v-model="info.car.car_tag_id">
							<option :value="-1">无标签</option>
							<option v-for="(item,index) in tagList" :key="index" :value="item.id">{{item.tag_name}}
							</option>
						</select>
					</div>
				</div>
				<div class="row">
					<div class="left">年款</div>
					<div class="right">
						<div class="text">{{ info.car.model_year }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">变速箱</div>
					<div class="right">
						<div class="text" v-if="info.car.gear_box_type==0">手动挡</div>
						<div class="text" v-if="info.car.gear_box_type==1">自动挡</div>
					</div>
				</div>
				<div class="row">
					<div class="left">挡位数</div>
					<div class="right">
						<div class="text">{{ info.car.gear_count }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">驱动方式</div>
					<div class="right">
						<div class="text">{{ info.car.drive_type }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">气缸数</div>
					<div class="right">
						<div class="text" v-if="info.car.cylinder_count > 0">
							{{ info.car.cylinder_count }}
						</div>
						<div class="text" v-else>无</div>
					</div>
				</div>
				<div class="row">
					<div class="left">最大功率</div>
					<div class="right">
						<div class="text">{{ info.car.max_power }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">进气形式</div>
					<div class="right">
						<div class="text">{{ info.car.air_inlet_mode }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">新车价格</div>
					<div class="right">
						<div class="text">{{ info.car.price }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">过户次数</div>
					<div class="right">
						<div class="text">{{ info.car.transfer_count }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">报价</div>
					<div class="right">
						<div class="text">{{ info.car.total_price }}</div>
					</div>
				</div>
				<div class="row" v-if="info.car.cost_price">
					<div class="left">结账价格</div>
					<div class="right">
						<div class="text">{{ info.car.cost_price }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">首付金额</div>
					<div class="right">
						<div class="text">{{ info.car.first_payment_amount }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">所在城市</div>
					<div class="right">
						<div class="text">{{ info.car.city_name }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">所在地址</div>
					<div class="right">
						<div class="text">{{ info.car.address }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">上牌时间</div>
					<div class="right">
						<div class="text">{{ info.car.on_plate_date }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">表显里程</div>
					<div class="right">
						<div class="text">{{ info.car.mileage }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">注册地</div>
					<div class="right">
						<div class="text">{{ info.car.registered_region }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">年检日期</div>
					<div class="right">
						<div class="text">{{ info.car.yearly_inspection_date }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">保险日期</div>
					<div class="right">
						<div class="text">{{ info.car.insurance_date }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">本车排量</div>
					<div class="right">
						<div class="text">{{ info.car.output_volume }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">排放标准</div>
					<div class="right">
						<div class="text">{{ info.car.output_standard }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">燃料类型</div>
					<div class="right">
						<div class="text">{{ info.car.fuel_name }}</div>
					</div>
				</div>
				<div class="row">
					<div class="left">颜色</div>
					<div class="right">
						<div class="text">{{ info.car.color_name }}</div>
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">外观图片</div>
					<div class="right" v-if="info.car.appearance_photos.length">
						<img class="img" v-for="(item, index) in info.car.appearance_photos.split(',')" :key="index"
							:src="item" :data-url="item" @click="openPreview" />
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">内饰图片</div>
					<div class="right" v-if="info.car.inside_photos.length">
						<img class="img" v-for="(item, index) in info.car.inside_photos.split(',')" :key="index"
							:src="item" :data-url="item" @click="openPreview" />
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">发动机和后备箱图片</div>
					<div class="right" v-if="info.car.engine_photos.length">
						<img class="img" v-for="(item, index) in info.car.engine_photos.split(',')" :key="index"
							:src="item" :data-url="item" @click="openPreview" />
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">其他图片</div>
					<div class="right" v-if="info.car.other_photos != null">
						<img class="img" v-for="(item, index) in info.car.other_photos.split(',')" :key="index"
							:src="item" :data-url="item" @click="openPreview" />
					</div>
				</div>
				<div class="row flex-start" v-if="info.car.video && info.car.video.indexOf('http')==0">
					<div class="left">视频</div>
					<div class="right">
						<video class="video" controls="controls" :poster="info.car.thumbnail">
							<source :src="info.car.video" type="video/mp4" />
						</video>
						<div class="text center" v-if="info.car.has_watermark == 0">
							<div>请检查视频能否正常播放，是否加过水印！</div>
							<button class="mini-btn" type="button" @click="initVideoData">
								加水印
							</button>
							<button class="mini-btn gray" type="button" @click="notInvitVideo">暂不处理</button>
							<b class="red" v-if="isInit">加水印中...</b>
						</div>
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">加装配置(汽车亮点)</div>
					<div class="right">
						<div class="attachment" v-for="(item, index) in info.car.attachments" :key="index">
							<img class="attachment-logo" :src="item.icon_url" :onerror="defaultImg" />
							<div class="attachment-text">{{ item.name }}</div>
						</div>
					</div>
				</div>
				<div class="row flex-start">
					<div class="left">车况描述</div>
					<div class="right">
						<textarea class="textarea" disabled="disabled" v-model="describe">
            </textarea>
						<div class="text center">
							<a class="link" @click="toTranslatePage">需翻译？进入翻译页面</a>
						</div>
					</div>
				</div>
				<div class="row">
					<div class="left">状态</div>
					<div class="right">
						<select class="select" v-model="info.car.status">
							<option :value="0">待审核</option>
							<option :value="1">审核通过</option>
							<option :value="2">未通过审核</option>
							<option :value="3">已销售</option>
						</select>
					</div>
				</div>
				<div class="row" v-if="info.car.status * 1 == 2">
					<div class="left">选择原因</div>
					<div class="right">
						<select class="select" @change="changeMsgTemplate">
							<option :value="-1">请选择原因</option>
							<option :value="index" v-for="(item, index) in msg_template" :key="index">
								{{ item.title }}
							</option>
						</select>
					</div>
				</div>
				<div class="row" v-if="info.car.status * 1 == 2">
					<div class="left">未通过原因</div>
					<div class="right">
						<textarea class="textarea" v-model="template_content" disabled></textarea>
					</div>
				</div>
				<div class="row">
					<div class="left">阅读次数</div>
					<div class="right">
						<div class="text">{{ info.car.viewCount }}</div>
					</div>
				</div>
			</div>

			<div class="btn">
				<div class="btn-item" @click="dismiss">取消</div>
				<div class="btn-item add" type="submit" @click="update">更新</div>
			</div>
		</div>
		<div class="preview" :class="{ show: preview.isShow }">
			<img class="preview-img" :style="'transform:rotate(' + preview.rotate + 'deg)'" :src="preview.url" alt=""
				@click="preview.rotate += 90" />
			<div class="preview-btn">
				<i class="iconfont icon-guanbi" @click="preview.isShow = false"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>
<script>
	let isLoad = false;
	import {
		updateUsedCar,
		getUsedCar,
		initVideo,
		addSeries,
		addModel,
		getCarTypeList,
		getSeriesOrModel,
		getUsedCarTagList
	} from "@/api/used-car.js";
	import {
		getMsgTemplateList
	} from "@/api/msg-template";
	import {
		translate
	} from "@/api/translate.js";

	export default {
		emits: ["confirm"],
		data() {
			return {
				info: "",
				id: 0,
				describe: "",
				status: -1,
				contrast: {
					status: "",
				},
				manufacturer: {
					manufacturer_name: "",
					manufacturer_name_ug: "",
					manufacturer_name_kz: "",
				},
				msg_template: {},
				template_id: -1,
				template_content: "",
				carType: [],
				carTypeChild: [],
				carSeries: [],
				carModel: [],
				tagList: [],
				isInit: false,
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				toastText: "请正确输入参数",
				isShowToast: false,
				preview: {
					url: "",
					rotate: 0,
					isShow: false,
				},
			};
		},
		mounted() {
			this.loadUsedCar();
			this.getMsgTemplateData();
			this.getUsedCarTagListData();
		},
		methods: {
			loadUsedCar() {
				let id = this.$route.params.id;
				getUsedCar(id).then((res) => {
					if (res.status == 200) {
						this.info = JSON.parse(JSON.stringify(res.data));
						this.status = res.data.car.status;
						if (!this.info.car.car_tag_id) {
							this.info.car["car_tag_id"] = -1;
						}
					}
					if (this.carType.length == 0) {
						this.getCarTypeListData(0);
						this.getCarTypeListData(this.info.car.car_type)
						this.getSeriesOrModelData(0)
						if (this.info.car.series_id >= 0) {
							this.getSeriesOrModelData(1)
						}
					}
				});
			},
			dismiss() {
				this.$router.back();
			},
			confirm() {
				this.$emit("confirm");
				this.dismiss();
			},
			selected(e) {
				this.status = e.target.value;
			},
			changeMsgTemplate(e) {
				let index = e.target.value;
				if (index == -1) {
					this.template_id = -1;
					this.template_content = "";
				} else if (index < this.msg_template.length) {
					this.template_id = this.msg_template[index].id;
					this.template_content = this.msg_template[index].content;
				}
			},
			update() {
				if (isLoad) return;
				if (!this.info.car.car_tag_id) {
					this.showToast("标签数据没加载完成!");
					return
				}
				let data = this.getUpdateData();
				if (data) {
					isLoad = true;
					updateUsedCar(data)
						.then((res) => {
							if (res.status == 200) {
								//发送请求
								this.showToast("已更新");
								setTimeout(() => {
									this.$router.back();
								}, 300);
							} else {
								this.showToast("失败");
							}
							isLoad = false;
						})
						.catch(() => {
							isLoad = false;
							this.showToast("出错了！");
						});
				} else {
					if (data == false) {
						this.showToast("没有可更新数据");
					}
				}
			},
			getUpdateData() {
				let data = {
					id: this.info.car.id,
					car_tag_id: this.info.car.car_tag_id
				};
				if (this.info.car.car_type_child_id != -1) {
					data["car_type"] = this.info.car.car_type;
					data["car_type_child_id"] = this.info.car.car_type_child_id;
				}
				if (this.info.car.series_id != -1) {
					data["series_id"] = this.info.car.series_id
				}
				if (this.info.car.model_id != -1) {
					data["model_id"] = this.info.car.model_id
				}
				if (this.status != this.info.car.status) {
					data["status"] = this.info.car.status * 1
					if (this.info.car.status == 2) {
						if (this.template_id == -1) {
							this.showToast("请选择不通过原因");
							return;
						} else {
							data["template_id"] = this.template_id
						}
					} else if (this.info.car.status == 1) {
						if (this.info.car.car_type_child_id == -1) {
							this.showToast("请选择子分类");
							return;
						}
						if (this.info.car.series_id == -1) {
							this.showToast("请选择车系");
							return;
						}
						if (this.info.car.model_id == -1) {
							this.showToast("请选择车型");
							return;
						}

						if (this.info.car.video && !this.info.car.has_watermark) {
							this.showToast("视频没加水印");
							return;
						}
						if (!this.info.car.series_id) {
							this.showToast("请填写车系");
							return
						}
						if (!this.info.car.model_id) {
							this.showToast("请填写车型");
							return
						}
					}
				}

				if (Object.keys(data).length > 1) {
					return data;
				} else {
					return false;
				}
			},
			getSeriesOrModelData(type) {
				let data = {
					id: this.info.car.brand_id,
					type: type
				}
				if (type) {
					data.id = this.info.car.series_id
				}
				getSeriesOrModel(data).then(res => {
					if (res.status == 200) {
						if (type == 0) {
							this.carSeries = res.data;
						} else {
							this.carModel = res.data;
						}
					}
				}).catch(err => {
					console.log("获取车型，车系出错")
					console.log(err)
				})
			},
			onSelectSeriesId() {
				this.info.car.model_id = -1;
				this.carModel = [];
				this.info.car.model_name = '';
				this.info.car.model_name_ug = '';
				this.info.car.model_name_kz = '';
				if (this.info.car.series_id != -1) {
					this.getSeriesOrModelData(this.info.car.series_id)
					this.carSeries.forEach(item => {
						if (item.id == this.info.car.series_id) {
							this.info.car.series_name = item.name;
							this.info.car.series_name_ug = item.name_ug || '';
							this.info.car.series_name_kz = item.name_kz || '';
						}
					})
				} else {
					this.info.car.series_name = '';
					this.info.car.series_name_ug = '';
					this.info.car.series_name_kz = '';
				}

			},
			onSelectModelId() {
				if (this.info.car.model_id != -1) {
					this.carModel.forEach(item => {
						if (item.id == this.info.car.model_id) {
							this.info.car.model_name = item.name;
							this.info.car.model_name_ug = item.name_ug || '';
							this.info.car.model_name_kz = item.name_kz || '';
						}
					})
				} else {
					this.info.car.model_name = '';
					this.info.car.model_name_ug = '';
					this.info.car.model_name_kz = '';
				}
			},
			getMsgTemplateData() {
				getMsgTemplateList(1, 20).then((res) => {
					if (res.status == 200) {
						this.msg_template = res.data.data;
					}
				});
			},
			getUsedCarTagListData() {
				getUsedCarTagList().then(res => {
					if (res.status == 200) {
						this.tagList = res.data.data
					}
				}).catch(err => {
					console.log("获取车俩标签列表出错")
					console.log(err)
				})
			},
			initVideoData() {
				let data = {
					id: this.$route.params.id,
					url: this.info.car.video,
				};
				this.isInit = true;
				initVideo(data)
					.then((res) => {
						if (res.status == 200) {
							this.info.car.has_watermark = 1;
							if (res.data.url) {
								this.info.car.video = res.data.url;
							} else {
								this.info.car.video + "?timestamp=" + new Date().getTime();
							}
							this.showToast("已完成！");
						} else {
							this.showToast("失败!");
						}
						this.isInit = false;
					})
					.catch((err) => {
						this.showToast("出错了!");
						console.log(err);
						this.isInit = false;
					});
			},
			notInvitVideo() {
				this.info.car.has_watermark = 1;
			},
			toTranslatePage() {
				let id = this.$route.params.id;
				this.$router.push("/used/car/translate/" + id);
			},
			translateData(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				let from = "cn"
				if (!source) {
					source = e.currentTarget.dataset.source_ug;
					from = "uy"
					if (!this.info.car[source]) {
						source = e.currentTarget.dataset.source_kz;
						from = "ka"
					}
				}
				if (this.info.car[source]) {
					translate(this.info.car[source], to, from).then((res) => {
						if (res.status == 200) {
							this.info.car[target] = res.data;
						} else {
							this.showToast(res.message)
						}
					}).catch(err => {
						this.showToast("出错了!")
						console.log(err)
					})
				} else {
					this.showToast("没有翻译内容")
				}
			},
			translateManufacturer(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				let from = "cn"
				if (!source) {
					source = e.currentTarget.dataset.source_ug;
					from = "uy"
					if (!this.manufacturer[source]) {
						source = e.currentTarget.dataset.source_kz;
						from = "ka"
					}
				}
				if (this.manufacturer[source]) {
					translate(this.manufacturer[source], to, from).then((res) => {
						if (res.status == 200) {
							this.manufacturer[target] = res.data;
						} else {
							this.showToast(res.message)
						}
					}).catch(err => {
						this.showToast("出错了!")
						console.log(err)
					})
				} else {
					this.showToast("没有翻译内容")
				}
			},
			async updateBrand() {
				let data = {
					id: this.info.car.id,
					brand_id: this.info.car.brand_id
				}
				if (!this.info.car.series_id || this.info.car.series_id == -1) { //添加车系
					if (!this.manufacturer.manufacturer_name) {
						this.showToast("请输入制造商中文名称")
						return;
					} else if (!this.manufacturer.manufacturer_name_ug) {
						this.showToast("请输入制造商维文名称")
						return;
					} else if (!this.manufacturer.manufacturer_name_kz) {
						this.showToast("请输入制造商哈文名称")
						return;
					} else if (!this.info.car.series_name) {
						this.showToast("请输入车系中文名称")
						return;
					} else if (!this.info.car.series_name_ug) {
						this.showToast("请输入车系维文名称")
						return;
					} else if (!this.info.car.series_name_kz) {
						this.showToast("请输入车系哈文名称")
						return;
					}
					data = {
						...data,
						manufacturer_name: this.manufacturer.manufacturer_name,
						manufacturer_name_ug: this.manufacturer.manufacturer_name_ug,
						manufacturer_name_kz: this.manufacturer.manufacturer_name_kz,
						series_name: this.info.car.series_name,
						series_name_ug: this.info.car.series_name_ug,
						series_name_kz: this.info.car.series_name_kz,
					}
					addSeries(data).then(res => {
						if (res.status == 200) {
							this.showToast("添加车系成功");
							this.loadUsedCar()
						} else {
							this.showToast(res.message);
							console.log("添加车系/车型失败")
						}
					}).catch(err => {
						this.showToast("出错了")
						console.log("添加车系/车型出错");
						console.log(err)
					})
				} else if (!this.info.car.model_id || this.info.car.model_id == -1) { //添加车型
					if (!this.info.car.model_name) {
						this.showToast("请输入车系中文名称")
						return;
					} else if (!this.info.car.model_name_ug) {
						this.showToast("请输入车系维文名称")
						return;
					} else if (!this.info.car.model_name_kz) {
						this.showToast("请输入车系哈文名称")
						return;
					}
					data = {
						...data,
						series_id: this.info.car.series_id,
						model_name: this.info.car.model_name,
						model_name_ug: this.info.car.model_name_ug,
						model_name_kz: this.info.car.model_name_kz,
					}
					addModel(data).then(res => {
						if (res.status == 200) {
							this.showToast("添加车型成功");
							this.loadUsedCar()
						} else {
							console.log("添加车系/车型失败")
						}
					}).catch(err => {
						console.log("添加车系/车型出错");
						console.log(err)
					})
				} else { //更新车系/车型
					data = {
						...data,
						series_id: this.info.car.series_id,
						series_name: this.info.car.series_name || '',
						series_name_ug: this.info.car.series_name_ug || '',
						series_name_kz: this.info.car.series_name_kz || '',
						model_id: this.info.car.model_id,
						model_name: this.info.car.model_name || '',
						model_name_ug: this.info.car.model_name_ug || '',
						model_name_kz: this.info.car.model_name_kz || '',
					}
					let isEmpty = false;
					Object.values(data).forEach((val) => {
						if (!val) {
							isEmpty = true;
						}
					})
					if (isEmpty) {
						this.showToast("有空内容，请先填写");
						return
					}
					console.log(data)
				}
			},
			// 获取汽车分类（车型）
			getCarTypeListData(e) {
				getCarTypeList(e).then(res => {
					if (res.status == 200) {
						if (e == 0) { //分类
							this.carType = res.data;
						} else { //子分类
							this.carTypeChild = res.data
						}
					}
				})
			},
			onSelectCayType() {
				this.carTypeChild = [];
				this.info.car.car_type_child_id = -1;
				this.getCarTypeListData(this.info.car.car_type)
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				this.preview.rotate = 0;
				this.preview.url = url;
				this.preview.isShow = true;
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	@rightWidth: 450px;

	.container {
		.attachment {
			display: inline-block;
			width: 100px;
			height: 80px;
			text-align: center;
			margin: 10px 15px;
			overflow: hidden;

			.attachment-logo {
				width: 50px;
				height: 50px;
			}

			.attachment-text {
				color: #585858;
				height: 30px;
				line-height: 30px;
				white-space: nowrap;
				font-size: 13px;
			}
		}

		.link {
			text-decoration: underline;
			color: #5e9ec0;
			cursor: pointer;
		}

		.red {
			color: red;
			display: block;
			line-height: 1.8;
		}

		.mini-btn {
			color: #fff;
			border: none;
			line-height: 1.8;
			padding: 0 15px;
			margin: 5px 5px;
			border-radius: 8px;
			background-color: #5e9ec0;
			display: inline-block;
			opacity: 0.8;
			cursor: pointer;
		}

		.gray {
			background-color: gray;
		}

		.badge {
			font-size: 12px;
			line-height: 1;
			display: inline-block;
			padding: 3px 6px;
			color: #333;
			border-radius: 100px;
			background-color: rgba(0, 0, 0, 0.15);
			margin-left: 5px;

			&.badge-success {
				color: #fff;
				background-color: #4cd964;
			}

			&.badge-red {
				color: #fff;
				background-color: #dd524d;
			}

			&.badge-yellow {
				color: #fff;
				background-color: #f0ad4e;
			}
		}

		.brand-btn {
			width: @rightWidth;
			margin-left: @leftWidth;
			text-align: center;

			.mini-btn {
				width: 40%;
				line-height: 2;
			}
		}
	}
</style>
