import http from '../utils/Http.js'

export function translate(text, to, from = 'cn') {
    let data = {
        text: text,
        from: from,
        to: to
    }
    return http({
        url: 'translate',
        method: 'POST',
        data: data
    })
}



export function gTranslate(text, to, from = 'zh') {
    let data = {
        text: text,
        from: from,
        to: to
    }
    return http({
        url: 'g-translate',
        method: 'POST',
        data: data
    })
}