<template>
	<div class="container">
		<div class="title">
			<div class="text">更新设置</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">应用名称</div>
				<div class="right">
					<input class="input" v-model="info.app_name" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">模块名称</div>
				<div class="right">
					<input class="input" v-model="info.module_name" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">备注</div>
				<div class="right">
					<input class="input" v-model="info.label" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">类型</div>
				<div class="right">
					<select v-model="info.type" class="select" @change="selectChange">
						<option :value="1">布尔值</option>
						<option :value="2">字符串</option>
						<option :value="3">图片</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">键</div>
				<div class="right">
					<input class="input" v-model="info.key" placeholder="请输入...">
				</div>
			</div>
			<div class="row" v-if="info.type==1">
				<div class="left">布尔值</div>
				<div class="right">
					<switcha :on="switchVal" v-on:changed="switchChange" />
				</div>
			</div>
			<div class="row">
				<div class="left">值</div>
				<div class="right">
					<input :disabled="info.type!=2" class="input" v-model="info.val" placeholder="请输入...">
				</div>
			</div>
			<div class="row" v-if="info.type==3">
				<div class="left">图片</div>
				<div class="right">
					<div class="logo-box">
						<img class="logo-img" :src="info.val || ''" :onerror="defaultImg" />
						<input class="logo-file" type="file" accept="image/*" :value="file" @change="updateLogo" />
						<span class="logo-tip">修改</span>
					</div>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateSetting
	} from "@/api/setting.js";
	import {
		uploadFile
	} from "@/api/common";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				info: {
					app_name: "",
					module_name: "",
					key: "",
					val: "",
					label: "",
					type: 2
				},
				info_orginal: {},
				file: "",
				switchVal: false,
				toastText: "请正确输入参数",
				isShowToast: false,
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info))
				this.info_orginal = JSON.parse(decodeURIComponent(this.$route.params.info))
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (data) {
					isLoad = true;
					updateSetting(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					if (data == false) {
						this.showToast("没有可更新数据")
					}
				}

			},
			getChangeData() {
				let data = {
					id: this.info.id
				}
				if (this.info.app_name != this.info_orginal.app_name) {
					data = {
						...data,
						app_name: this.info.app_name
					}
				}
				if (this.info.module_name != this.info_orginal.module_name) {
					data = {
						...data,
						module_name: this.info.module_name
					}
				}
				if (this.info.key != this.info_orginal.key) {
					data = {
						...data,
						key: this.info.key
					}
				}
				if (this.info.val != this.info_orginal.val) {
					data = {
						...data,
						val: this.info.val
					}
				}
				if (this.info.label != this.info_orginal.label) {
					data = {
						...data,
						label: this.info.label
					}
				}
				if (this.info.type != this.info_orginal.type) {
					if (this.info.type == -1) {
						this.showToast("请选择类型")
						return
					} else {
						data = {
							...data,
							type: this.info.type
						}
					}
				}
				if (Object.keys(data).length > 1) {
					return data
				} else {
					return false;
				}
			},
			updateLogo(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info.val = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			selectChange(){
				if(this.info.type==1){
					this.info.val = 0
				}else if(this.info.type==2){
					this.info.val = ""
				}else if(this.info.type==3){
					this.info.val = "https://izpak-admin.elghar.cn/assets/img/default.png"
				}
			},
			switchChange() {
				this.switchVal = !this.switchVal;
				if (this.switchVal) {
					this.info.val = 1
				} else {
					this.info.val = 0
				}
			},
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");

	.right {
		.logo-box {
			position: relative;
			width: 100px;
			height: 80px;
			margin-bottom: 8px;


			.logo-img {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				border-radius: 5px;
				background-color: #f1f1f1;
			}

			.logo-tip {
				position: absolute;
				text-align: center;
				left: 0px;
				bottom: 0;
				width: 100%;
				line-height: 1.5;
				background-color: #fff;
				color: #000;
				opacity: 0.4;
				font-size: 10px;
			}

			.logo-file {
				position: absolute;
				left: 0;
				top: 0;
				width: 80px;
				height: 80px;
				z-index: 2;
				display: inline-block;
				opacity: 0;
			}
		}

	}
</style>
