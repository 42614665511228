import http from '../utils/Http.js'
export function getMerchantList(data) {
	return http({
		url: 'merchants',
		method: 'GET',
		params: data
	})
}

export function updateMerchant(data) {
	return http({
		url: 'merchant',
		method: 'PUT',
		data: data
	})
}

export function getOrcText(data) {
	return http({
		url: 'merchant/ocr-business-license',
		method: 'PUT',
		data: data
	})
}

