<template>
	<div class="container">
		<div class="title">
			<div class="text">更新举报选型</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" v-if="info.defendant_merchant" :src="info.defendant_merchant.avatar_url" />
					<img class="logo-img" v-else-if="info.defendant_customer"
						:src="info.defendant_customer.avatar_url" />
				</div>
				<div class="logo-title">
					<span class="text" v-if="info.defendant_merchant">{{info.defendant_merchant.merchant_name}}</span>
					<span class="text" v-else-if="info.defendant_customer">{{info.defendant_customer.real_name}}</span>
				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<input class="input" type="text" disabled v-if="info.defendant_merchant"
						:value="info.defendant_merchant.mobile_phone" />
					<input class="input" type="text" disabled v-else-if="info.defendant_customer"
						:value="info.defendant_customer.mobile_phone" />
				</div>
			</div>
			<div class="row">
				<div class="left">举报描述</div>
				<div class="right">
					<input class="input" type="text" disabled :value="info.content.name" />
				</div>
			</div>
			<div class="row">
				<div class="left">举报人名称</div>
				<div class="right">
					<input class="input" type="text" disabled v-if="info.reporter_customer"
						:value="info.reporter_customer.real_name" />
					<input class="input" type="text" disabled v-else-if="info.reporter_wechat_customer"
						:value="info.reporter_wechat_customer.nick_name" />
				</div>
			</div>
			<div class="row">
				<div class="left">举报人手机号</div>
				<div class="right">
					<input class="input" type="text" disabled v-if="info.reporter_customer"
						:value="info.reporter_customer.mobile_phone" />
					<input class="input" type="text" disabled v-else-if="info.reporter_wechat_customer"
						:value="info.reporter_wechat_customer.mobile_phone" />
				</div>
			</div>
			<div class="row">
				<div class="left">时间</div>
				<div class="right">
					<input class="input" type="text" disabled :value="rTime(info.updated_at)" />
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<select class="select" v-model="info.status">
						<option :value="0" disabled>未处理</option>
						<option :value="1" disabled>受理中</option>
						<option :value="2">处罚</option>
						<option :value="3">不处罚</option>
						<option :value="4" disabled>已处理</option>
					</select>
				</div>
			</div>
			<div class="btn" v-if="info.id">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateRoportLog,
		startHandleRoportLog
	} from "@/api/report";
	export default {
		data() {
			return {
				info: {},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
				if (this.info.status == 0) {
					this.startHandleRoportLogData()
				}
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			startHandleRoportLogData() {
				startHandleRoportLog(this.info.id).then(res => {
					if (res.status == 200) {
						this.info.status == 1
					}
				})
			},
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = {
					id: this.info.id,
					status: this.info.status
				}
				//发送请求
				isLoad = true;
				updateRoportLog(data).then(res => {
					if (res.status == 200) {
						this.showToast("更新成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			rTime(e){
				let json_date = new Date(e).toJSON();
				return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");

	@leftWidth: 110px;
</style>
