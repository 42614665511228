<template>
	<div class="container">
		<div class="title">
			<div class="text">翻译二手车信息</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddLexicon">
					<i class="iconfont icon-add refresh"></i>
					<span>添加词汇</span>
				</div>
			</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">车况描述</div>
				<div class="right">
					<textarea class="textarea" v-model="describe" disabled="disabled"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">词库分类</div>
				<div class="right">
					<div class="buttons">
						<div class="button-mini" :class="{selected:lexiconSortId==item.id}" :data-id="item.id"
							:data-index="index" @click="getLexiconListData" v-for="(item,index) in lexiconSortList"
							:key="index">
							{{item.category_name}}
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">词汇</div>
				<div class="right">
					<div class="buttons">
						<!-- <div class="checkbox" v-for="(item,index) in lexiconList" :key="index">
							<label id="box">{{item.info_tag}}12</label>
							<input type="checkbox" name="box" id="box" :value="item.id" />
						</div> -->
						<div class="button-mini" :class="{selected:lexiconIds.includes(item.id)}"
							v-for="(item,index) in lexiconList[lexiconListIndex]" :key="index" :data-id="item.id"
							:data-index="index" @click="changeContent">
							{{item.info_tag}}
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">添加描述</div>
				<div class="right">
					<div class="text">
						{{content.car_describe}}
					</div>
				</div>
			</div>
			<!-- 			<div class="row">
				<div class="left">维文描述</div>
				<div class="right">
					<div class="text rtl">
						{{content.car_describe_ug}}
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">哈语描述</div>
				<div class="right">
					<div class="text rtl">
						{{content.car_describe_kz}}
					</div>
				</div>
			</div> -->
			<div class="row">
				<div class="left">补充描述</div>
				<div class="right">
					<div class="language language-textarea">
						<textarea class="before" type="text" v-model="desc.car_desc"></textarea>
						<i class="after">中文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="desc.car_desc_ug"></textarea>
						<i class="after">维文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="desc.car_desc_kz"></textarea>
						<i class="after">哈文</i>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="left">地址</div>
				<div class="right">
					<div class="language language-textarea">
						<textarea class="before" type="text" v-model="usedCar.address"></textarea>
						<i class="after">中文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="usedCar.address_ug"></textarea>
						<i class="after">维文</i>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="uy"
							data-source="address" data-target="address_ug">翻译</div>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" type="text" v-model="usedCar.address_kz"></textarea>
						<i class="after">哈文</i>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="ka"
							data-source="address" data-target="address_kz">翻译</div>
					</div>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="dismiss">取消</div>
				<div class="btn-item add" type="submit" @click="save">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let contentArr = [];

	import {
		translate
	} from "@/api/translate.js";
	import {
		getCarTranslate,
		updateCarTranslate
	} from "@/api/used-car.js";
	import {
		getLexiconSortList,
		getLexiconList
	} from "@/api/lexicon";
	export default {
		data() {
			return {
				usedCar: {
					id: "",
					car_describe: "",
					car_describe_ug: "",
					car_describe_kz: "",
					address: "",
					address_ug: "",
					address_kz: "",
				},
				content: {
					car_describe: "",
					car_describe_ug: "",
					car_describe_kz: "",
				},
				desc: {
					car_desc: "",
					car_desc_ug: "",
					car_desc_kz: "",
				},
				address: {
					address: "",
					address_ug: "",
					address_kz: "",
				},
				lexiconSortList: [],
				lexiconList: [],
				lexiconListIndex: 0,
				lexiconSortId: 0,
				lexiconIds: [],
				selected: '',
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		computed: {
			describe() {
				let text = ""
				if (this.usedCar.car_describe) {
					text = this.usedCar.car_describe
				} else if (this.usedCar.car_describe_ug) {
					text = this.usedCar.car_describe_ug
				} else if (this.usedCar.car_describe_kz) {
					text = this.usedCar.car_describe_kz
				}
				return text
			}
		},
		mounted() {
			let lang = localStorage.getItem('lang');
			if (lang) {
				this.selected = lang
			} else {
				this.selected = 'ug'
			}
			this.getLexiconSortListData();
			this.loadUsedCar();
		},
		methods: {
			loadUsedCar() {
				let id = this.$route.params.id;
				if (id) {
					getCarTranslate(id).then((res) => {
						if (res.status == 200) {
							let list = res.data;
							this.usedCar.id = list.id
							this.usedCar.car_describe = list.car_describe
							this.usedCar.car_describe_ug = list.car_describe_ug
							this.usedCar.car_describe_kz = list.car_describe_kz;
							if (list.address) {
								this.usedCar.address = list.address;
								this.usedCar.address_ug = list.address_ug;
								this.usedCar.address_kz = list.address_kz;

								this.address = list.address;
								this.address_ug = list.address_ug;
								this.address_kz = list.address_kz;
							}
							if (list.car_info_dict_ids && list.car_info_dict_ids.length) {
								let arr = [];
								list.car_info_dict_ids.split(",").forEach(id => {
									arr.push(Number(id))
								})
								this.lexiconIds = arr;
							}
							this.desc.car_desc = list.car_desc
							this.desc.car_desc_ug = list.car_desc_ug
							this.desc.car_desc_kz = list.car_desc_kz


							contentArr = list.car_info_dict_list
							this.pushContent()
						}
					});
				}

			},
			dismiss() {
				this.$router.back()
			},
			save() {
				if (this.loading) return;
				this.loading = true;
				let data = {
					...this.desc,
					id: this.usedCar.id,
					car_info_dict_ids: this.lexiconIds,
					address: this.usedCar.address,
					address_ug: this.usedCar.address_ug,
					address_kz: this.usedCar.address_kz,
				}
				updateCarTranslate(data).then((res) => {
					if (res.status == 200) {
						this.showToast("已更新")
						setTimeout(() => {
							this.dismiss()
						}, 400)
					} else {
						this.showToast("失败")
					}
					this.loading = false;
				}).catch(err => {
					console.log(err)
					this.loading = false;
				})
			},
			getLexiconSortListData() {
				getLexiconSortList().then(res => {
					if (res.status == 200) {
						this.lexiconSortList = res.data.data;
						this.lexiconList = new Array(res.data.data.length);
						this.getLexiconListData(null, res.data.data[0].id)
					}
				})
			},
			getLexiconListData(e, _id) {
				let id, index;
				if (e) {
					id = e.currentTarget.dataset.id;
					index = e.currentTarget.dataset.index;
					this.lexiconListIndex = index;
				} else if (_id) {
					id = _id
				}
				this.lexiconSortId = id;
				if (this.lexiconList[this.lexiconListIndex] && this.lexiconList[this.lexiconListIndex].length) {
					return
				}
				let data = {
					category_id: id,
					status: 1
				}
				getLexiconList(data).then(res => {
					if (res.status == 200) {
						this.lexiconList[this.lexiconListIndex] = res.data.data
					}
				})
			},
			async changeContent(e) {
				if (e) {
					let id = Number(e.currentTarget.dataset.id);
					let index = Number(e.currentTarget.dataset.index);
					if (this.lexiconIds.includes(id)) {
						this.lexiconIds.splice(this.lexiconIds.indexOf(id), 1);
						contentArr.forEach((item, i) => {
							if (item.id == id) {
								contentArr.splice(i, 1);
							}
						})
					} else {
						this.lexiconIds.push(id)
						contentArr.push({
							id: id,
							info_tag: this.lexiconList[this.lexiconListIndex][index].info_tag,
							info_tag_ug: this.lexiconList[this.lexiconListIndex][index].info_tag_ug,
							info_tag_kz: this.lexiconList[this.lexiconListIndex][index].info_tag_kz,
						})
					}
				}
				this.pushContent()

			},
			pushContent() {
				let content = {
					car_describe: "",
					car_describe_ug: "",
					car_describe_kz: ""
				}

				contentArr.forEach(item => {
					content.car_describe += item.info_tag + "; "
					content.car_describe_ug += item.info_tag_ug + " ؛"
					content.car_describe_kz += item.info_tag_kz + " ؛"
				})
				this.content = content;
			},
			translateDate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				let from = "cn"
				if (!source) {
					source = e.currentTarget.dataset.source_ug;
					from = "uy"
					if (!this.usedCar[source]) {
						source = e.currentTarget.dataset.source_kz;
						from = "ka"
					}
				}
				let text = this.usedCar[source]
				let startText = "";
				if (text.indexOf('新疆维吾尔自治区') == 0 && to == 'uy') {
					startText = "شىنجاڭ ئۇيغۇر ئاپتونوم رايونى "
					text = text.substr(8)
				}
				if (text) {
					translate(text, to, from).then((res) => {
						if (res.status == 200) {
							this.usedCar[target] = startText + res.data;
						} else {
							this.showToast(res.message)
						}
					}).catch(err => {
						this.showToast("出错了!")
						console.log(err)
					})
				} else {
					this.showToast("没有翻译内容")
				}

			},
			toAddLexicon() {
				this.$router.push({
					name: "LexiconAdd"
				});
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	@import url("~@/assets/style/table.less");
	@textareaHeight: 100px;
	@rightWidth: 550px;

	.right {
		.buttons {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			background-color: #f8f8f8;
			border-radius: 5px;

			.button {
				height: @inputHeight;
				line-height: @inputHeight;
				flex: 1;
				margin: 0 16px;
				border-radius: 10px;
				color: #fff;
				text-align: center;
				background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
				opacity: 0.9;
				cursor: pointer;

			}

			.button-mini {
				margin: 8px;
				padding: 0 8px;
				line-height: 1.8;
				border-radius: 5px;
				color: #fff;
				text-align: center;
				background-color: #000;

				// background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
				opacity: 0.9;
				cursor: pointer;

				&.selected {
					background: linear-gradient(135deg, #f5af19, #f12711);
				}
			}
		}

		.checkboxs {
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			color: #000;

			.checkbox {
				width: 24%;
				height: @inputHeight*0.8;
				line-height: @inputHeight*0.8;
				margin: 8px;
				padding: 0 8px;
				border: 1px solid gray;
				border-radius: 5px;
			}
		}
	}
</style>
