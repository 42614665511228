import axios from 'axios'
import router from '../router/index'
// 创建 axios 实例
const service = axios.create({
    // baseURL: "http://localhost:8081/api/",
    baseURL: 'https://izpak.elghar.cn/admin/',
    timeout: 100000, // request timeout
})
//  请求拦截器
service.interceptors.request.use(
    (config) => {
        config.headers['Content-Type'] = 'application/json;charset=UTF-8'
        config.headers['Accept'] = 'application/json'
        let token = localStorage.getItem('token') || null
        if (token) {
            config.headers['Authorization'] = token;
        }else {
			router.replace('/login');
		}
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    (response) => {
        const res = response.data;
		if(response.headers && response.headers.authorization){
			localStorage.setItem('token',response.headers.authorization);
		}
        // 届时根据后端返回success或者code值判断
		if(res.status == 401){
			router.replace('/login')
			localStorage.removeItem('loginData')
			localStorage.removeItem('token')
		}
        if (res.success === true) {
            return res
        } else {
            return res
        }
    },
    (error) => {
        // 响应错误
        if (error.response.status === 401) {
            router.replace('/login')
            localStorage.removeItem('loginData')
            localStorage.removeItem('token')
        }
        return Promise.reject(error)
    }
)

export default service