<template>
	<div class="container">
		<div class="title">
			<div class="text">区域翻译</div>
		</div>
		<div class="filter">
			<div class="city-list">
				<div class="city-item" :class="{ on: table == 'regions_shanghai' }" data-index="regions_shanghai"
					@click="switchCity">
					上海
				</div>

				<div class="city-item" :class="{ on: table == 'regions_beijing' }" data-index="regions_beijing"
					@click="switchCity">
					北京
				</div>

				<div class="city-item" :class="{ on: table == 'regions_tianjin' }" data-index="regions_tianjin"
					@click="switchCity">
					天津
				</div>

				<div class="city-item" :class="{ on: table == 'regions_xj' }" data-index="regions_xj"
					@click="switchCity">
					新疆
				</div>
			</div>
			<div class="status">
				<div class="status-item">
					<div class="text">维语</div>
					<switcha :on="untranslated_ug" v-on:changed="onTranslateUgChanged" />
				</div>
				<div class="status-item">
					<div class="text">哈语</div>
					<switcha :on="untranslated_kz" v-on:changed="onTranslateKzChanged" />
				</div>
			</div>
		</div>

		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维文</div>
					<div class="col flex-1">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in region" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<region-translate-modal ref="translateModal" :info="infoToTranslate" :table="table"
			v-on:confirm="reloadRegion" />
	</div>
</template>
<script>
	import {
		getRegion
	} from "@/api/region";
	import Paginator from "@/components/widgets/Paginator.vue";
	import Switcha from "@/components/widgets/Switch.vue";
	import RegionTranslateModal from "@/components/region/RegionTranslateModal.vue";
	export default {
		components: {
			Paginator,
			Switcha,
			RegionTranslateModal,
		},
		data() {
			return {
				table: "regions_beijing",
				untranslated_ug: false,
				untranslated_kz: false,
				routes: [],
				region: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
			};
		},
		created() {
			this.loadRegion()
		},
		methods: {
			loadRegion() {
				getRegion(
					this.curPage,
					this.pageSize,
					this.table,
					this.untranslated_ug,
					this.untranslated_kz
				).then((res) => {
					if (res.status == 200) {
						this.region = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			switchCity(e) {
				let index = e.currentTarget.dataset.index;
				this.table = index;
				this.loadRegion();
			},
			reloadRegion(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadRegion();
			},
			onTranslateUgChanged() {
				this.untranslated_ug = !this.untranslated_ug;
				this.loadRegion();
			},

			onTranslateKzChanged() {
				this.untranslated_kz = !this.untranslated_kz;
				this.loadRegion();
			},
			onPageChanged(page) {
				this.reloadRegion(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.region[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/table.less");

	.container {
		.filter {
			min-height: @titleHeight;
			background: white;
			margin: 16px 32px;
			border-radius: 8px;
			display: flex;
			align-items: center;
			padding: 0 16px;

			.city-list {
				flex: 1;
				display: flex;
				flex-wrap: wrap;

				.city-item {
					padding: 16px;
					cursor: pointer;
					color: #999;

					&.on {
						color: #f12711;
					}

					&:hover {
						color: #f12711;
					}
				}
			}

			.status {
				display: flex;

				.status-item {
					margin-left: 32px;
					display: flex;
					align-items: center;

					.text {
						margin-right: 16px;
					}
				}
			}
		}
	}
</style>
