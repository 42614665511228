<template>
	<div class="container">
		<div class="title">
			<div class="text">更新员工信息</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" :src="info.avatar_url || ''" :onerror="defaultImg" />
					<input class="logo-file" type="file" accept="image/*" :value="logoFile" @change="updateLogo" />
					<span class="logo-tip">修改</span>
				</div>
				<div class="logo-title">Logo</div>
			</div>
			<div class="row">
				<div class="left">姓名</div>
				<div class="right">
					<div class="language">
						<input type="text" class="before" v-model="info.real_name">
						<span class="after">中文</span>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_ug">
						<span class="after">维文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="uy"
							data-source="real_name" data-target="real_name_ug">翻译</div>
					</div>
					<div class="language">
						<input type="text" class="before rtl" v-model="info.real_name_kz">
						<span class="after">哈文</span>
						<div class="after translate-btn" type="button" @click="translateDate" data-to="ka"
							data-source="real_name" data-target="real_name_kz">翻译</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">身份证号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.id_number" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">手机号</div>
				<div class="right">
					<input type="text" class="input" v-model="info.phone_number" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">生成海报</div>
				<div class="right">
					<img v-if="poster" :src="poster" alt="" class="img" :data-url="poster"
						:data-tool="true" @click="openPreview" />
					<button v-if="!poster" class="poster-btn" type="button" data-lang="zh" @click="getPosterImg">中文海报</button>
					<button v-if="!poster" class="poster-btn" type="button" data-lang="ug" @click="getPosterImg">维文海报</button>
					<button v-if="!poster" class="poster-btn" type="button" data-lang="kz" @click="getPosterImg">哈文海报</button>
					<span class="poster-loading" v-if="posterLoading">生成中..</span>
				</div>
			</div>
			<div class="btn" v-if="info.id">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="preview" :class="{ show: preview.isShow }">
			<img class="preview-img" :style="'transform:rotate(' + preview.rotate + 'deg)'" :src="preview.url" alt=""
				@click="preview.rotate += 90" />
			<div class="preview-btn">
				<i class="iconfont icon-guanbi" @click="preview.isShow = false"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		uploadFile
	} from "@/api/common";
	import {
		updateAgent,
		getAgent,
		getMakePoster
	} from "@/api/agent";
	import {
		translate
	} from "@/api/translate.js";
	export default {
		data() {
			return {
				info: {},
				posterLoading:false,
				poster:"",
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				toastText: "请正确输入参数",
				isShowToast: false,
				logoFile:"",
				preview: {
					url: "",
					rotate: 0,
					isShow: false,
				},
			}
		},
		created() {
			if (this.$route.params.id) {
				this.getAgentData(this.$route.params.id)
			} else {
				this.showToast("获取数据失败")
			}

		},
		methods: {
			getAgentData(id) {
				getAgent(id).then(res => {
					if (res.status == 200) {
						this.info = res.data
					}
				})
			},
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.real_name || !this.info.real_name.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.real_name_ug || !this.info.real_name_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.real_name_kz || !this.info.real_name_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				if (!this.info.id_number || this.info.id_number.length != 18) {
					this.showToast("请正确输入身份证号")
					return
				}
				if (!this.info.phone_number || this.info.phone_number.length != 11) {
					this.showToast("请正确输入手号")
					return
				}
				if (!this.info.avatar_url) {
					this.showToast("请上传头像")
					return
				}
				//发送请求
				isLoad = true;
				updateAgent(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("更新成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			updateLogo(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info.avatar_url = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			translateDate(e) {
				let source = e.currentTarget.dataset.source;
				let target = e.currentTarget.dataset.target;
				let to = e.currentTarget.dataset.to;
				translate(this.info[source], to).then((res) => {
					if (res.status == 200) {
						this.info[target] = res.data;
					}
				});
			},
			getPosterImg(e){
				if(this.posterLoading) return;
				let lang = e.currentTarget.dataset.lang;
				this.posterLoading = true;
				getMakePoster(this.info.id,lang).then(res=>{
					this.posterLoading = false;
					if(res.status == 200){
						this.poster = res.data.url
						self.showToast("生成成功");
					}
				}).catch(err=>{
					console.log(err)
					this.posterLoading = false;
				})
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				this.preview.rotate = 0;
				this.preview.url = url;
				this.preview.isShow = true;
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	
	.container {
		
		.poster-btn {
			height: 30px;
			line-height: 30px;
			width: 80px;
			text-align: center;
			border: none;
			background-color: #5e9ec0;
			color: #fff;
			border-radius: 10px;
			cursor: pointer;
			margin-right: 18px;
		}
		
		.poster-loading {
			height: 30px;
			line-height: 30px;
			color: #dd524d;
		}
	}
</style>
