import http from '../utils/Http.js'
export function getSettingList(data) {
	return http({
		url: 'settings',
		method: 'GET',
		params: data
	})
}
export function addSetting(data) {
	return http({
		url: 'setting',
		method: 'POST',
		data: data
	})
}
export function updateSetting(data) {
	return http({
		url: 'setting',
		method: 'PUT',
		data: data
	})
}
