<template>
	<div class="container">
		<div class="title">
			<div class="text">修改个人信息</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" :src="user.avatar || ''" :onerror="defaultImg"/>
					<input class="logo-file" type="file" accept="image/*" :value="avatarFile" @change="updateAvatar" />
					<span class="logo-tip">修改</span>
				</div>
				<div class="logo-title">头像</div>
			</div>
			<div class="row">
				<div class="left">原密码：</div>
				<div class="right">
					<input type="password" class="input" v-model="password" placeholder="请输入原来的密码"/>
				</div>
			</div>
			<div class="row">
				<div class="left">修改密码：</div>
				<div class="right">
					<input type="password" class="input" v-model="updatePw" placeholder="请输入修改密码"/>
				</div>
			</div>
			<div class="row">
				<div class="left">确认密码：</div>
				<div class="right">
					<input type="password" class="input" v-model="updatePw2" placeholder="请再次输入修改密码"/>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item update" @click="update">修改</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		uploadFile
	} from "@/api/common";
	import {
		getUser,
		updateUser
	} from "@/api/admin-user";
	export default {
		data() {
			return {
				dataId: "",
				user: {},
				user_orgial: {},
				password: "", //愿密码
				updatePw: "", //修改密码
				updatePw2: "", //确认修改密码
				toastText: "请正确输入参数",
				isShowToast: false,
				status: ["审核中", "正常", "禁言", "封号"],
				avatarFile: "",
				//图片剪裁组件参数
				showMyUpload: false,
				params: {
					token: "123456798",
					name: "avatar",
					image: "123.png",
				},
				headers: {
					smail: "*_~",
				},
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			};
		},
		created() {
			this.dataId = this.$route.params.id;
			this.getData()
		},
		methods: {
			cancel() {
				//取消按钮
				this.$router.back();
			},
			getData() {
				getUser(this.dataId).then(res => {
					this.user = res.data;
					this.user_orgial = {
						...res.data
					};
				})
			},
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				
				let data = {}
				if (this.user.avatar.trim() != this.user_orgial.avatar.trim() && this.user.avatar.trim().indexOf("http") ==
					0) {
					data = {
						avatar: this.user.avatar.trim()
					}
				}
				if (this.password.length < 6) {
					return this.showToast("密码太短了！");
				}
				if (this.updatePw.length < 6 || this.updatePw2.length < 6) {
					return this.showToast("密码太短了!");
				}
				if (this.updatePw == this.updatePw2) {
					data = {
						...data,
						pwd: this.updatePw.trim(),
						old_password: this.password
					}
				}

				if (Object.getOwnPropertyNames(data).length > 0) {
					//发送请求
					isLoad = true;
					updateUser(this.dataId, data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					})
				} else {
					this.showToast("没更改")
					isLoad = false;
				}

			},

			updateAvatar(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.user.avatar = res.data.url;
								self.showToast("长传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>