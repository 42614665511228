<template>
	<div class="container">
		<div class="title">
			<div class="text">添加车辆国家排放标准</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input class="input" v-model="info.output_standard" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">维语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.output_standard_ug"
						placeholder="كىرگۈزۈڭ...">
				</div>
			</div>
			<div class="row">
				<div class="left">哈语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.output_standard_kz"
						placeholder="كىرگىزىڭىز...">
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.top" @keypress="inputChange" placeholder="数字越大越靠后">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addUsedCarStandard
	} from "@/api/used-car.js";
	export default {
		data() {
			return {
				info: {
					output_standard:"",
					output_standard_ug:"",
					output_standard_kz:"",
					top:"",
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if(!this.info.output_standard){
					this.showToast("请输入名称！")
					return
				}
				if(this.info.top == ""){
					this.showToast("请输入排序号！")
					return
				}
				isLoad = true;
				addUsedCarStandard(this.info).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>