<template>
	<div class="container">
		<div class="title">
			<div class="text">添加会员</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input type="text" class="input" v-model="info.name" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">维文名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_ug" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">哈文名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_kz" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">价格</div>
				<div class="right">
					<input type="number" class="input" v-model="info.price" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">原价</div>
				<div class="right">
					<input type="number" class="input old-price" v-model="info.old_price" placeholder="">
				</div>
			</div>
			<div class="row">
				<div class="left">有效期(天)</div>
				<div class="right">
					<input type="number" class="input" v-model="info.duration" @input="inputChange" placeholder="单位:天">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addVipPlan
	} from "@/api/vip-plan";
	export default {
		data() {
			return {
				info: {
					name: "",
					name_ug: "",
					name_kz: "",
					price: "",
					old_price: "",
					duration: ""
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {

		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.name || !this.info.name.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.name_ug || !this.info.name_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.name_kz || !this.info.name_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				if (!this.info.price && this.info.price.length != 0) {
					this.showToast("请输入价格")
					return
				}
				if (!this.info.old_price && this.info.old_price.length != 0) {
					this.showToast("请输入原价格")
					return
				}
				if (!this.info.duration) {
					this.showToast("请正确输入有效期")
					return
				}
				isLoad = true;
				addVipPlan(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("添加成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	
	.container {
		.old-price {
			text-decoration: line-through;
		}
	}
</style>
