<template>
	<div class="container">
		<div class="title">
			<div class="text">添加帮助信息</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">类型</div>
				<div class="right">
					<select class="select" v-model="help.category">
						<option :value="-1">请选择</option>
						<option :value="1">商务</option>
						<option :value="2">帮助</option>
						<option :value="3">关于我们</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">标题</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="help.title">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="help.title_ug">
						<i class="after">维语</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="help.title_kz">
						<i class="after">哈语</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">链接</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="help.url" placeholder="https://">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before" type="text" v-model="help.url_ug" placeholder="https://">
						<i class="after">维语</i>
					</div>
					<div class="language">
						<input class="before" type="text" v-model="help.url_kz" placeholder="https://">
						<i class="after">哈语</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">排序</div>
				<div class="right">
					<input type="number" class="input" v-model="help.top" @keypress="inputChange" placeholder="请输入排序号(越大越靠后)">
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<switcha :on="help.status==1" v-on:changed="help.status=help.status?0:1" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addHelp
	} from "@/api/help";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				help: {
					title: "",
					title_ug: "",
					title_kz: "",
					url: "",
					url_ug: "",
					url_kz: "",
					top: "",
					status: 1,
					category:-1,
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {

		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (this.help.category == -1) {
					this.showToast("请选择链接类别")
					return
				}
				if (this.help.title == undefined || this.help.title == "" || this.help.title == null) {
					this.showToast("请输入标题")
					return
				}
				if (this.help.top == undefined || this.help.top == "" || this.help.top == null) {
					this.showToast("请输入排序号")
					return
				}
				
				if(this.help.top*1>=0){
					this.help.top = this.help.top*1
				}else {
					this.showToast("请正确输入排序号")
					return
				}
				isLoad = true;
				addHelp(this.help).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>