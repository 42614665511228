<template>
	<div class="container">
		<div class="title">
			<div class="text">添加配件分类</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">所属分类</div>
				<div class="right">
					<select v-model="info.parent_id" class="select">
						<option :value="-1">请选择</option>
						<option v-for="(item,index) in dataList" :value="item.id" :key="index">{{item.category_name}}</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input class="input" v-model="info.category_name" placeholder="请输入...">
				</div>
			</div>
			<div class="row">
				<div class="left">维语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.category_name_ug"
						placeholder="كىرگۈزۈڭ...">
				</div>
			</div>
			<div class="row">
				<div class="left">哈语名称</div>
				<div class="right">
					<input class="input rtl" v-model="info.category_name_kz"
						placeholder="كىرگىزىڭىز...">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addCategory
	} from "@/api/parts.js";
	export default {
		data() {
			return {
				info: {
					category_name:"",
					category_name_ug:"",
					category_name_kz:"",
					parent_id:-1,
				},
				dataList: [{
					id:1,
					category_name: "一级分类",
					category_name_ug: "一级分类",
					category_name_kz: "一级分类",
					parent_id: 0,
				},{
					id:2,
					category_name: "分类名称",
					category_name_ug: "分类名称",
					category_name_kz: "分类名称",
					parent_id: 1,
				}],
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if(!this.info.category_name){
					this.showToast("请输入分类名称！")
					return
				}
				isLoad = true;
				addCategory(this.info).then(res => {
					if (res.status == 200) {
						this.showToast(res.data)
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})
			},
			cancel() {
				this.$router.back()
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
