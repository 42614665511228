<template>
	<div class="container">
		<div class="title">
			<div class="text">汽车厂家</div>
		</div>
		<div class="serach">
			<div class="iconfont icon-refresh refresh" @click="refresh"></div>
			<div class="status">
				<div class="status-item">
					<div class="text">维语</div>
					<switcha :on="untranslated_ug" v-on:changed="onTranslateUgChanged" />
				</div>
				<div class="status-item">
					<div class="text">哈语</div>
					<switcha :on="untranslated_kz" v-on:changed="onTranslateKzChanged" />
				</div>
			</div>
		</div>

		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维语</div>
					<div class="col flex-1">哈语</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(manufacturer, index) in manufacturers" :key="index">
					<div class="col id">{{ manufacturer.id }}</div>
					<div class="col flex-1">{{ manufacturer.manufacturer_name }}</div>
					<div class="col flex-1 rtl-left">
						{{ manufacturer.manufacturer_name_ug }}
					</div>
					<div class="col flex-1 rtl-left">
						{{ manufacturer.manufacturer_name_kz }}
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<manufacturer-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadManufacturers" />
	</div>
</template>
<script>
	import {
		getCarManufacturers
	} from "@/api/car";
	import Paginator from "@/components/widgets/Paginator.vue";
	import Switcha from "@/components/widgets/Switch.vue";
	import ManufacturerTranslateModal from "@/components/car/ManufacturerTranslateModal.vue";
	export default {
		components: {
			Paginator,
			ManufacturerTranslateModal,
			Switcha
		},
		data() {
			return {
				routes: [],
				manufacturers: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
				untranslated_ug: false,
				untranslated_kz: false,
			};
		},
		created() {
			this.loadManufacturers();
		},
		methods: {
			loadManufacturers() {
				getCarManufacturers(this.curPage, this.pageSize, this.untranslated_ug,
					this.untranslated_kz).then((res) => {
					if (res.status == 200) {
						this.manufacturers = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			onTranslateUgChanged() {
				this.untranslated_ug = !this.untranslated_ug;
			
				this.loadManufacturers();
			},
			onTranslateKzChanged() {
				this.untranslated_kz = !this.untranslated_kz;
			
				this.loadManufacturers();
			},
			reloadManufacturers(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadManufacturers();
			},
			refresh() {
				this.curPage = 1;
				this.loadManufacturers();
			},
			onPageChanged(page) {
				this.reloadManufacturers(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(
					JSON.stringify(this.manufacturers[index])
				);
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>