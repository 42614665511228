<template>
	<div class="container">
		<div class="title">
			<div class="text">更新用户信息</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<input class="logo-file" type="file" accept="image/*" :value="avatarFile" @change="updateAvatar" />
					<img class="logo-img" :src="user.avatar || ''" :onerror="defaultImg"/>
					<span class="logo-tip">修改</span>
				</div>
				<div class="logo-title">头像</div>
			</div>
			<div class="row">
				<div class="left">用户名：</div>
				<div class="right">
					<input type="text" class="input" v-model="user.real_name" placeholder="请输入名字"/>
				</div>
			</div>
			<div class="row">
				<div class="left">手机号：</div>
				<div class="right">
					<input type="text" class="input" v-model="user.usr" placeholder="请输入手机号"/>
				</div>
			</div>
			<div class="row">
				<div class="left">密码：</div>
				<div class="right">
					<input type="password" class="input" v-model="password" placeholder="请输入密码"/>
				</div>
			</div>
			<div class="row">
				<div class="left">状态：</div>
				<div class="right">
					<span class="tag" :class="{ selected: item.status == user.status }"
						v-for="(item, statusIndex) in status" :key="statusIndex" :data-status="item.status"
						@click="user.status=item.status">{{ item.title }}</span>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item update" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		uploadFile
	} from "@/api/common";
	import {
		getUser,
		updateUser
	} from "@/api/admin-user";
	export default {
		data() {
			return {
				dataId: "",
				user: {},
				user_orgial: {},
				password:"",
				avatarFile: "",
				toastText: "请正确输入参数",
				isShowToast: false,
				status: [{
						"status": 1,
						"title": "正常"
					},
					{
						"status": 2,
						"title": "审核中"
					},
					{
						"status": 3,
						"title": "禁用"
					},

				],
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'"
			};
		},
		created() {
			this.dataId = this.$route.params.id;
			this.getData()
		},
		methods: {
			cancel() {
				//取消按钮
				this.$router.back();
			},
			getData() {
				getUser(this.dataId).then(res => {
					this.user = res.data;
					this.user.pwd = ""
					this.user_orgial = {
						...res.data
					};
				})
			},
			update() {
				//更新按钮
				if(isLoad){
					this.showToast("请稍等！")
					return
				}
				let data = {
					id:this.user.id
				}
				if (this.user.avatar.trim() != this.user_orgial.avatar.trim() && this.user.avatar.trim().indexOf("http")==0) {
					data = {
						avatar : this.user.avatar.trim()
					}
				}
				if (this.user.real_name.trim() != this.user_orgial.real_name.trim()) {
					data = {
						...data,
						real_name : this.user.real_name.trim()
					}
				}
				if (this.user.usr.trim() != this.user_orgial.usr.trim()) {
					data = {
						...data,
						usr : this.user.usr.trim()
					}
				}
				if (this.user.status != this.user_orgial.status) {
					data = {
						...data,
						status : this.user.status
					}
				}
				if (this.password.trim().length>5) {
					data = {
						...data,
						pwd : this.password.trim()
					}
				}else if(this.password.trim().length>0){
					this.showToast("密码太短了！")
					return
				}
				if(Object.getOwnPropertyNames(data).length>0){
					//发送请求
					isLoad = true;
					updateUser(this.dataId,data).then(res=>{
						if(res.status==200){
							this.showToast(res.data)
							setTimeout(()=>{
								this.$router.back()
							},500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(err=>{
						console.log(err);
						isLoad = false;
						this.showToast("出错了！")
					})
				}else{
					this.showToast("没更改")
					isLoad = false;
				}
				
			},
			updateAvatar(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.user.avatar = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>