import http from '../utils/Http.js'
export function getUsedCarList(data) {
	return http({
		url: 'used-cars',
		method: 'GET',
		params: data
	})
}
export function getUsedCar(id) {
	return http({
		url: 'used-car/' + id,
		method: 'GET'
	})
}
export function apply(id, status) {
	let data = {
		id: id,
		status: status
	}
	return http({
		url: 'used-car/apply',
		method: 'PUT',
		data: data,
	})
}
export function updateUsedCar(data) {
	return http({
		url: 'used-car/apply',
		method: 'PUT',
		data: data
	})
}

export function deleteUsedCar(id) {
	return http({
		url: 'used-car/' + id,
		method: 'DELETE',
	})
}

export function getCarTranslate(id) {
	return http({
		url: 'used-car/translate/' + id,
		method: 'GET'
	})
}
export function updateCarTranslate(data) {
	return http({
		url: 'used-car/translate',
		method: 'PUT',
		data: data,
	})
}
// 价位表
export function getUsedCarPriceList(data) {
	return http({
		url: 'prices',
		method: 'GET',
		params: data
	})
}
export function deleteUsedCarPrice(id) {
	return http({
		url: 'price',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}
export function addUsedCarPrice(data) {
	return http({
		url: 'price',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarPrice(data) {
	return http({
		url: 'price',
		method: 'PUT',
		data: data
	})
}
// 排量表
export function getUsedCarOutputVolumeList(data) {
	return http({
		url: 'output-volumes',
		method: 'GET',
		params: data
	})
}
export function addUsedCarOutputVolume(data) {
	return http({
		url: 'output-volume',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarOutputVolume(data) {
	return http({
		url: 'output-volume',
		method: 'PUT',
		data: data
	})
}
export function deleteUsedCarOutputVolume(id) {
	return http({
		url: 'output-volume',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}
// 颜色表
export function getUsedCarColorList(data) {
	return http({
		url: 'colors',
		method: 'GET',
		params: data
	})
}
export function addUsedCarColor(data) {
	return http({
		url: 'color',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarColor(data) {
	return http({
		url: 'color',
		method: 'PUT',
		data: data
	})
}
export function deleteUsedCarColor(id) {
	return http({
		url: 'color',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}

// 车辆标准信息
export function getUsedCarStandardList(data) {
	return http({
		url: 'output-standards',
		method: 'GET',
		params: data
	})
}
export function addUsedCarStandard(data) {
	return http({
		url: 'output-standard',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarStandard(data) {
	return http({
		url: 'output-standard',
		method: 'PUT',
		data: data
	})
}
export function deleteUsedCarStandard(id) {
	return http({
		url: 'output-standard',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}

// 燃料表
export function getUsedCarFuelList(data) {
	return http({
		url: 'fuels',
		method: 'GET',
		params: data
	})
}
export function addUsedCarFuel(data) {
	return http({
		url: 'fuel',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarFuel(data) {
	return http({
		url: 'fuel',
		method: 'PUT',
		data: data
	})
}
export function deleteUsedCarFuel(id) {
	return http({
		url: 'fuel',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}

// 变速箱
export function getUsedCarGearBoxList(data) {
	return http({
		url: 'gear-boxs',
		method: 'GET',
		params: data
	})
}
export function addUsedCarGearBox(data) {
	return http({
		url: 'gear-box',
		method: 'POST',
		data: data
	})
}
export function updateUsedCarGearBox(data) {
	return http({
		url: 'gear-box',
		method: 'PUT',
		data: data
	})
}
export function deleteUsedCarGearBox(id) {
	return http({
		url: 'gear-box',
		method: 'DELETE',
		data: {
			id: id
		}
	})
}
export function initVideo(data) {
	return http({
		url: 'init-video',
		method: 'POST',
		data: data
	})
}

export function addSeries(data) {
	return http({
		url: 'car-series/add',
		method: 'POST',
		data: data
	})
}

export function addModel(data) {
	return http({
		url: 'car-model/add',
		method: 'POST',
		data: data
	})
}

export function getCarTypeList(id = 0) {
	return http({
		url: 'get_car_types/list?parent_id=' + id,
		method: 'GET'
	})
}

export function getSeriesOrModel(data) {
	return http({
		url: 'used-car/get-series-model',
		method: 'GET',
		params: data
	})
}

// 标签
export function getUsedCarTagList(data) {
	return http({
		url: 'used-car-tag/list',
		method: 'GET',
		params: data
	})
}

export function getUsedCarTag(data) {
	return http({
		url: 'used-car-tag/get',
		method: 'GET',
		params: data
	})
}

export function addUsedCarTag(data) {
	return http({
		url: 'used-car-tag/add',
		method: 'POST',
		data: data
	})
}

export function updateUsedCarTag(data) {
	return http({
		url: 'used-car-tag/add',
		method: 'PUT',
		data: data
	})
}
