<template>
	<div class="container">
		<div class="title">
			<div class="text">系统日志</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">数据库名称</div>
					<div class="col flex-1">操作员名称</div>
					<div class="col flex-1">序号</div>
					<div class="col flex-1">操作ip</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(log,logIndex) in logList" :key="logIndex">
					<div class="col id">{{logIndex+1}}</div>
					<div class="col flex-1">{{log.goodsName}}</div>
					<div class="col flex-1">{{log.real_name}}</div>
					<div class="col flex-1">{{log.top}}</div>
					<div class="col flex-1">{{log.ip}}</div>
					<div class="col operation">
						<div class="operation-item" title="查看详细" :data-index="logIndex" @click="toDetailPage">
							<i class="iconfont icon-view"></i>
						</div>
					
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	// import {
	// 	getLogList
	// } from "@/api/admin-log";
	
	import Paginator from "@/components/widgets/Paginator.vue";
	
	export default {
		components:{
			Paginator
		},
		data(){
			return {
				logList:[{
					id:1,
					goodsName: "database",
					real_name:"阿里木江",
					type:"修改",
					top:123,
					time:"2021-12-12",
					ip:"192.168.12",
					detail:"内容"
				}],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
			}
		},
		methods:{
			toDetailPage(e){
				let index = e.currentTarget.dataset.index;
				this.$router.push({
					name:"AdminLogDetail",
					params:{
						info:encodeURIComponent(JSON.stringify(this.logList[index*1]))
					}
				})
			},
			loadData() {
				// getLogList(this.curPage, this.pageSize).then((res) => {
				// 	if (res.status == 200) {
				// 		this.logList = res.data.data;
				// 		this.totalRecords = res.data.total
				// 	}
				// });
			},
			reloadUser(page) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");

</style>

