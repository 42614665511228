<template>
	<div class="container">
		<div class="title">
			<div class="text">会员列表</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">会员名称</div>
					<div class="col flex-1">价格</div>
					<div class="col flex-1">原价</div>
					<div class="col flex-1">有效期</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in dataList" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1">{{ item.price }}</div>
					<div class="col flex-1 old-price">{{ item.old_price }}</div>
					<div class="col flex-1">{{ item.duration }}天</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getVipPlanList
	} from "@/api/vip-plan";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator,
		},
		data() {
			return {
				dataList: [],
				filter: {
					status: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created() {
			this.loadData();
		},
		methods: {
			toAddPage() {
				this.$router.push({
					name: "VipPlanAdd"
				});
			},
			reload(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reload(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "VipPlanUpdate",
					params: {
						id: this.dataList[index].id
					}
				});
			},
			loadData() {
				getVipPlanList().then((res) => {
					if (res.status == 200) {
						this.dataList = res.data;
						// this.totalRecords = res.data.total
						this.totalRecords = 1
					}
				});
			},
			rTime(e){
				let json_date = new Date(e).toJSON();
				return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
	
	.container {
		.old-price {
			text-decoration: line-through;
		}
	}
</style>
