<template>
  <div class="container" :class="{ shown: shown, fading: fading }">
    <div class="box" :class="{ fading: fading }">
      <div class="title">编辑路由</div>
      <div class="form">
        <div class="row">
          <label for="name">名称</label>
          <input type="hidden" v-model="id" />
          <input
            type="text"
            id="name"
            autocomplete="off"
            spellcheck="false"
            v-model="name"
          />
        </div>

        <div class="row">
          <label for="path">路径</label>
          <input
            type="text"
            id="path"
            autocomplete="off"
            spellcheck="false"
            v-model="path"
          />
        </div>

        <div class="row">
          <label>方法</label>
          <div class="single-selector">
            <div
              class="option"
              v-for="(method, index) in methods"
              :key="index"
              :class="{ selected: methodIndex == index }"
              :data-index="index"
              @click="switchMethod"
            >
              {{ method }}
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="button" class="cancel" @click="dismiss">取消</button>
        <button type="button" class="confirm" @click="add">保存</button>
      </div>
      <div class="hint">{{ hint }}</div>
    </div>
  </div>
</template>
<script>
let self;

import { updateRoute } from "@/api/admin.js";

export default {
  emits: ["updateFinish"],
  props: {
    info: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      shown: false,
      fading: false,
      id: 0,
      name: "",
      path: "",
      hint: "",
      methodIndex: 0,
      methods: ["GET", "POST", "PUT", "DELETE"],
    };
  },
  watch: {
    info(val) {
      this.id = val.id;
      this.name = val.name;
      this.path = val.path;
      for (let i = 0; i < this.methods.length; i++) {
        const method = this.methods[i];
        if (method == val.method) {
          this.methodIndex = i;
          break;
        }
      }
    },
  },
  mounted() {
    self = this;
  },
  methods: {
    switchMethod(e) {
      let index = Number(e.currentTarget.dataset.index);
      if (index === this.methodIndex) return;
      this.methodIndex = index;
    },
    show() {
      this.shown = true;
      setTimeout(() => {
        this.fading = true;
      }, 10);
    },
    reset() {
      this.name = "";
      this.path = "";
      this.methodIndex = 0;
    },
    dismiss() {
      this.reset();
      this.fading = false;
      setTimeout(() => (this.shown = false), 400);
    },
    showHint(hintText, duration = 2000) {
      this.hint = hintText;
      setTimeout(() => (this.hint = ""), duration);
    },
    add() {
      if (this.name == "") {
        this.showHint("请填写名称");
        return;
      }
      if (this.path == "") {
        this.showHint("请填写路径");
        return;
      }

      updateRoute(this.id,this.name, this.path, this.methods[this.methodIndex]).then(
        (res) => {
          if (res.status == 200) {
            self.dismiss();
            self.$emit("updateFinish");
          } else {
            self.showHint(res.message);
          }
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
@titleHeight: 50px;
@formRowHeight: 60px;
@optionHeight: 36px;
@buttonHeight: 40px;
.container {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 999;
  left: 0;
  top: 0;
  display: none;
  opacity: 0;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.618);
  transition: opacity 400ms;

  &.shown {
    display: flex;
  }

  &.fading {
    opacity: 1;
  }

  .box {
    width: 400px;
    min-height: 420px;
    background: white;
    border-radius: 16px;
    transform: scale(0.1);
    transition: transform 400ms;

    &.fading {
      transform: scale(1);
    }
    .title {
      height: @titleHeight;
      line-height: @titleHeight;
      font-size: 16px;
      text-align: center;
    }

    .form {
      padding: 32px;
      .row {
        display: flex;
        height: @formRowHeight;
        flex-direction: column;
        margin-bottom: 16px;

        label {
          margin-bottom: 4px;
        }

        input[type="text"] {
          flex: 1;
          border: none;
          background: #f7f8f9;
          border-radius: 20px;
          padding: 0 16px;
        }

        .single-selector {
          display: flex;
          .option {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            height: @optionHeight;
            border-radius: @optionHeight / 2;
            background: #f7f8f9;
            margin: 8px;
            cursor: pointer;

            &.selected {
              background: linear-gradient(-220deg, #f5af19, #f12711);
              color: white;
            }
          }
        }
      }
    }

    .buttons {
      display: flex;

      button {
        flex: 1;
        border: none;
        height: @buttonHeight;
        border-radius: @buttonHeight / 2;
        margin: 0 32px;
        cursor: pointer;

        &.confirm {
          background: linear-gradient(-220deg, #f5af19, #f12711);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #f12711 0 1px 4px;
          box-shadow: #f5af1966 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }

        &.cancel {
          background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
          background-size: 200% @titleHeight;
          color: white;
          text-shadow: #2c3e50 0 1px 4px;
          box-shadow: #bdc3c766 0 4px 16px;
          transition: background-position-x 400ms;

          &:hover {
            background-position-x: 100%;
          }
        }
      }
    }

    .hint {
      padding: 32px;
      text-align: center;
    }
  }
}
</style>