<template>
	<div class="container">
		<div class="title">
			<div class="text">发送消息</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">全部用户</div>
				<div class="right">
					<switcha :on="message.all==1" v-on:changed="message.all=message.all==0?1:0" />
				</div>
			</div>
			<div class="row" v-if="message.all==0">
				<div class="left">指定用户</div>
				<div class="right">
					<input type="text" class="right input" v-model="message.mobile_phone" placeholder="请输入用户手机号...">
				</div>
			</div>
			<div class="row">
				<label class="left">选择消息</label>
				<div class="right">
					<select class="select" @change="changeMsgTemplate">
						<option :value="-1">请选择</option>
						<option :value="index" v-for="(item,index) in msg_template" :key="index">{{item.title}}</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">中文内容</div>
				<div class="right">
					<textarea class="textarea" v-model="message.content" placeholder="" disabled></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">维语内容</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="message.content_ug"
						placeholder="" disabled></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">哈语内容</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="message.content_kz"
						placeholder="" disabled></textarea>
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		sendMessage
	} from "@/api/message";
	import {
		getMsgTemplateList
	} from "@/api/msg-template";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				message: {
					all:1,
					mobile_phone:"",
					content:"",
					content_ug:"",
					content_kz:"",
					template_id:-1
				},
				msg_template:[],
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			this.getMsgTemplateData()
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getSendData();
				if(data){
					isLoad = true;
					sendMessage(data).then(res => {
						if (res.status == 200) {
							this.showToast(res.data)
							setTimeout(() => {
								this.$router.back()
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				}
				
			},
			getSendData(){
				let data = {
					all : this.message.all
				}
				if(this.message.all==0){
					if(this.message.mobile_phone.length!=11){
						this.showToast("请正确输入手机号");
						return false
					}else{
						data = {
							...data,
							mobile_phone : this.message.mobile_phone
						}
					}
				}else {
					data = {
						...data,
						mobile_phone : ""
					}
				}
				if(this.message.template_id==-1){
					this.showToast("请选择消息");
					return
				}else {
					data = {
						...data,
						template_id : this.message.template_id
					}
				}
				return data
			},
			cancel() {
				this.$router.back()
			},
			changeMsgTemplate(e){
				let index = e.target.value;
				if(index*1==-1){
					this.message.template_id = -1;
					this.message.content = ""
					this.message.content_ug = ""
					this.message.content_kz = ""
				}else if(index*1<this.msg_template.length) {
					this.message.template_id = this.msg_template[index].id
					this.message.content = this.msg_template[index].content
					this.message.content_ug = this.msg_template[index].content_ug
					this.message.content_kz = this.msg_template[index].content_kz
				}
			},
			getMsgTemplateData(){
				getMsgTemplateList(1,20).then(res=>{
					if(res.status==200){
						this.msg_template = res.data.data
					}
				})
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>