<template>
	<div class="container">
		<div class="title">
			<div class="text">车架号信息</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.search_lang}">
					<label class="text">范围</label>
					<select class="select-mini" v-model="filter.search_lang">
						<option value="all">请选择</option>
						<option value="zh">中文名称</option>
						<option value="ug">维文名称</option>
						<option value="kz">哈文名称</option>
					</select>
					<input class="input" :class="{'rtl':filter.search_lang=='ug'||filter.search_lang=='kz'}" type="text"
						v-model="filter.search_word" placeholder="请输入" />
				</div>
				<!-- 				<div class="list-item" :class="{on:filterData.name ||filterData.name_ug || filterData.name_kz}">
					<div class="switcha">
						<label class="text">维语</label>
						<switcha class="switcha" :on="filterData.untranslated_ug" v-on:changed="onTranslateUgChanged" />
					</div>
				</div>
				<div class="list-item" :class="{on:filterData.name ||filterData.name_ug || filterData.name_kz}">
					<div class="switcha">
						<label class="text">维语</label>
						<switcha class="switcha" :on="filterData.untranslated_kz" v-on:changed="onTranslateKzChanged" />
					</div>
				</div> -->
				<div class="list-item">
					<div class="search-btn" @click="loadVins">搜索</div>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-2">车架号</div>
					<div class="col flex-2">中文名称</div>
					<div class="col flex-2">维文名称</div>
					<div class="col flex-2">哈文名称</div>
					<div class="col flex-1">年款</div>
					<div class="col flex-1">车系</div>
					<div class="col flex-1">品牌</div>
					<div class="col flex-1">国IV</div>
					<div class="col flex-1">排量</div>
					<div class="col flex-1">变速箱</div>
					<div class="col flex-1">档位数</div>
					<div class="col flex-1">驱动</div>
					<div class="col flex-1">缸数</div>
					<div class="col flex-1">最大功率</div>
					<div class="col flex-1">进气方式</div>

					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in vin" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-2">{{ item.vin_code }}</div>
					<div class="col flex-2">{{ item.vin_name }}</div>
					<div class="col flex-2 rtl-left">{{ item.vin_name_ug }}</div>
					<div class="col flex-2 rtl-left">{{ item.vin_name_kz }}</div>
					<div class="col flex-1">{{ item.model_year }}</div>
					<div class="col flex-1">{{ item.series_name }}</div>
					<div class="col flex-1">{{ item.brand_name }}</div>
					<div class="col flex-1">{{ item.output_standard }}</div>
					<div class="col flex-1">{{ item.output_volume }}</div>
					<div class="col flex-1">{{ item.gear_box }}</div>
					<div class="col flex-1">{{ item.gear_count }}</div>
					<div class="col flex-1">{{ item.drive_type }}</div>
					<div class="col flex-1">{{ item.cylinder_count }}</div>
					<div class="col flex-1">{{ item.max_power }}</div>
					<div class="col flex-1">{{ item.air_inlet_mode }}</div>

					<div class="col operation">
						<div class="operation-item" title="翻译" :data-index="index" @click="showModal">
							<i class="iconfont icon-fanyi-full"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
		<vin-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadVins" />
	</div>
</template>
<script>
	import {
		getVinList
	} from "@/api/vin";
	import Paginator from "@/components/widgets/Paginator.vue";
	// import Switcha from "@/components/widgets/Switch.vue";
	import VinTranslateModal from "@/components/vin/VinTranslateModal.vue";
	export default {
		components: {
			Paginator,
			// Switcha,
			VinTranslateModal
		},
		data() {
			return {
				vin: [],
				filter: {
					search_lang: 'all',
					search_word: "",
				},
				filterData: {
					page: 1,
					size: 20,
					// untranslated_ug: false,
					// untranslated_kz: false
				},
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
				untranslated_ug: false,
				untranslated_kz: false,
			};
		},
		created() {
			this.loadVins();
		},
		methods: {
			loadVins() {
				this.vin = []
				this.filterData = this.getFilterData();
				getVinList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.vin = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			getFilterData() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size,
					// untranslated_ug: this.filterData.untranslated_ug,
					// untranslated_kz: this.filterData.untranslated_kz
				}
				if (this.filter.search_lang != 'all' && this.filter.search_word.trim().length > 1) {
					data = {
						...data,
						search_lang: this.filter.search_lang,
						search_word: this.filter.search_word.trim()
					}
				}else {
					this.filter.search_word = ""
				}
				if (Object.values(data).sort().toString() != Object.values(this.filterData).sort().toString()) {
					data.page = 1;
				}
				return data
			},
			onTranslateUgChanged() {
				this.filterData.untranslated_ug = !this.filterData.untranslated_ug;

				this.reloadVins();
			},
			onTranslateKzChanged() {
				this.filterData.untranslated_kz = !this.filterData.untranslated_kz;

				this.reloadVins();
			},
			reloadVins(page) {
				this.filterData.page = page;
				this.loadVins();
			},
			onPageChanged(page) {
				this.reloadVins(page);
			},
			showModal(e) {
				if (typeof e == undefined) return;
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.vin[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");

	@inputWidth: 250px;

	.rtl {
		direction: rtl;
	}
</style>
