<template>
	<div class="container">
		<div class="title">
			<div class="text">消息列表</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toSendPage">发送</div>
			</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.mobile_phone}">
					<label class="text">手机号</label>
					<input class="input" type="number" v-model="filter.mobile_phone" @keypress="inputChange" placeholder="请输入手机号..."/>
				</div>
				<div class="list-item" :class="{on:filterData.type*1>=0}">
					<label class="text">消息类型</label>
					<select class="select" v-model="filter.type">
						<option :value="-1">请选择</option>
						<option :value="1">通知</option>
						<option :value="2">审核未通过</option>
					</select>
				</div>
				<div class="list-item" :class="{on:filterData.status*1>=0}">
					<label class="text">消息状态</label>
					<select class="select" v-model="filter.status">
						<option :value="-1">请选择</option>
						<option :value="0">未读消息</option>
						<option :value="1">已读消息</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadData">搜索</div>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-2">名称</div>
					<div class="col flex-2">手机号</div>
					<div class="col flex-1">用户类型</div>
					<div class="col flex-1">消息类型</div>
					<div class="col flex-1">状态</div>
					<div class="col flex-1">时间</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">{{index+1}}</div>
					<!-- 名称 -->
					<div class="col flex-2">
						<span v-if="item.customer">
							{{item.customer.real_name}}
						</span>
						<span v-if="item.customer_id==0 && item.merchant_id==0">
							全部用户
						</span>
						<span v-if="item.merchant">
							{{item.merchant.merchant_name}}
						</span>
						<span v-if="item.car && item.car.used_car">
							{{item.car.used_car.vin_name}}
						</span>
					</div>
					<!-- 手机号 -->
					<div class="col flex-2">
						<span v-if="item.customer">
							{{item.customer.mobile_phone}}
						</span>
						<span v-if="item.merchant">
							{{item.merchant.mobile_phone}}
						</span>
						<span v-if="item.car && item.car.used_car">
							{{item.car.contact_mobile}}
						</span>
						<span v-if="item.customer_id==0 && item.merchant_id==0">-</span>
					</div>
					<!-- 用户类型 -->
					<div class="col flex-1">
						<span v-if="item.customer">用户</span>
						<span v-if="item.merchant">商家</span>
						<span v-if="item.car && item.car.used_car">二手车</span>
						<span v-if="item.customer_id==0 && item.merchant_id==0">-</span>
					</div>
					<!-- 消息类型 -->
					<div class="col flex-1">
						<span v-if="item.type==1">通知</span>
						<span v-if="item.type==2">审核未通过</span>
					</div>

					<!-- 状态 -->
					<div class="col flex-1" :class="{gray:item.status==1}">
						<span v-if="item.status==0">未读</span>
						<span v-if="item.status==1">已读</span>
					</div>
					<div class="col flex-1">{{item.created_at}}</div>
					<div class="col operation">
						<div class="operation-item" title="查看" :data-index="index" @click="toDetailPage">
							<i class="iconfont icon-view"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page"
					:change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getMessageList,
	} from "@/api/message";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator
		},
		data() {
			return {
				dataList: [],
				filter: {
					mobile_phone: "", //用户名称，商家名称
					type: -1, //消息类型：通知，审核中
					status: -1 //状态：已读，未读
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created() {
			this.loadData()
		},
		methods: {
			reloadUser(page) {
				if (page > 0) {
					this.filterData.page = page
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			toDetailPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "MessageDetail",
					params: {
						info: encodeURIComponent(JSON.stringify(this.dataList[index]))
					}
				});
			},
			toSendPage() {
				this.$router.push({
					name: "MessageSend"
				});
			},
			loadData() {
				this.dataList = []
				this.filterData = this.getFilter()
				getMessageList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			getFilter() {
				let data = {
					page: this.filterData.page,
					size: this.filterData.size
				}
				if (this.filter.mobile_phone.trim().length > 1) {
					data = {
						...data,
						mobile_phone: this.filter.mobile_phone.trim()
					}
				}
				if (this.filter.type * 1 != -1) {
					data = {
						...data,
						type: this.filter.type * 1
					}
				}
				if (this.filter.status * 1 != -1) {
					data = {
						...data,
						status: this.filter.status * 1
					}
				}
				if(Object.values(data).sort().toString()!=Object.values(this.filterData).sort().toString()){
					data.page = 1;
				}
				return data
			},
			inputChange(e){
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>