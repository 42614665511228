import http from '../utils/Http.js'
export function getCarAttachmentList(page = 1, size) {
    let data = {
        page: page,
        size: size
    }
    return http({
        url: 'car-attach',
        method: 'GET',
        params: data
    })
}

export function createCarAttach(data) {
    return http({
        url: 'car-attach',
        method: 'POST',
        data: data,
    })
}

export function updateCarAttach(data) {
    return http({
        url: 'car-attach',
        method: 'PUT',
        data: data,
    })
}

export function daleteAttach(id) {
    return http({
        url: 'car-attach',
        method: 'DELETE',
        data: {
            id: id
        },
    })

}