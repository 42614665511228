<template>
	<div class="container">
		<div class="title">
			<div class="text">汽车品牌</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">图标</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维文</div>
					<div class="col flex-1">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(brand, index) in brands" :key="index">
					<div class="col id">{{ brand.id }}</div>
					<div class="col avatar">
						<img class="avatar-img no-radius" :src="brand.brand_logo" :onerror="defaultImg">
					</div>
					<div class="col flex-1">
						{{ brand.brand_name }}
					</div>
					<div class="col flex-1 rtl-left">{{ brand.brand_name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ brand.brand_name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<brand-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadCarBrands" />
	</div>
</template>
<script>
	import {
		getCarBrands
	} from "@/api/car";
	import Paginator from "@/components/widgets/Paginator.vue";
	import BrandTranslateModal from "@/components/car/BrandTranslateModal.vue";
	export default {
		components: {
			Paginator,
			BrandTranslateModal,
		},
		data() {
			return {
				routes: [],
				brands: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
				defaultImg:"this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
			};
		},
		created() {
			this.loadBrands();
		},
		methods: {
			loadBrands() {
				getCarBrands(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.brands = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadCarBrands(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadBrands();
			},
			onPageChanged(page) {
				this.reloadCarBrands(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.brands[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>
<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>
