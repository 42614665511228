<template>
	<div class="container">
		<div class="title">
			<div class="text">关于我们</div>
		</div>
		<div class="content">
			<div class="logo">
				<div class="logo-box">
					<img class="logo-img" :src="info.logo || ''" :onerror="defaultImg" :data-url="info.logo"
						@click="openPreview"/>
					<input class="logo-file" type="file" accept="image/*" :value="logoFile" v-if="!disabled" @change="updateLogo" />
					<span class="logo-tip" v-if="!disabled">修改</span>
				</div>
				<div class="logo-title">Logo</div>
			</div>
			<div class="row">
				<div class="left">标题</div>
				<div class="right">
					<div class="language">
						<input class="before" type="text" v-model="info.title" :disabled="disabled">
						<i class="after">中文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.title_ug" :disabled="disabled">
						<i class="after">维文</i>
					</div>
					<div class="language">
						<input class="before rtl" type="text" v-model="info.title_kz" :disabled="disabled">
						<i class="after">哈文</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">内容</div>
				<div class="right">
					<div class="language language-textarea">
						<textarea class="before" v-model="info.content" :disabled="disabled"></textarea>
						<i class="after">中文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" v-model="info.content_ug" :disabled="disabled"></textarea>
						<i class="after">维文</i>
					</div>
					<div class="language language-textarea">
						<textarea class="before rtl" v-model="info.content_kz" :disabled="disabled"></textarea>
						<i class="after">哈文</i>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="left">编辑</div>
				<div class="right">
					<switcha :on="!disabled" v-on:changed="changeDisabled" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item " v-if="!disabled" @click="cancel">取消</div>
				<div class="btn-item add" type="submit" v-if="!disabled" @click="update">更新</div>
			</div>
		</div>
		<div class="preview" :class="{show:preview.isShow}">
			<img class="preview-img" :style="'transform:rotate('+preview.rotate+'deg)'" :src="preview.url" alt="">
			<div class="preview-btn">
				<i class="iconfont icon-guanbi" @click="preview.isShow=false"></i>
				<i class="iconfont icon-xuanzhuan" v-if="preview.isTool" @click="preview.rotate+=90"></i>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		uploadFile
	} from "@/api/common";
	import {
		getAbout,
		updateAbout
	} from "@/api/about.js";
	import Switcha from "@/components/widgets/Switch.vue";
	export default {
		components: {
			Switcha
		},
		data() {
			return {
				info: {
					logo: "https://izpak-admin.elghar.cn/assets/img/default.png",
					title: "",
					title_ug: "",
					title_kz: "",
					content: "",
					content_ug: "",
					content_kz: "",
				},
				logoFile: "",
				disabled: true,
				info_orginal: {},
				preview: {
					url: "",
					isTool: false,
					rotate: 0,
					isShow: false
				},
				defaultImg: "this.src='https://izpak-admin.elghar.cn/assets/img/default.png'",
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			this.getAboutData()
		},
		methods: {
			getAboutData(){
				getAbout().then(res=>{
					if(res.status==200){
						this.info = res.data;
						this.info_orginal = {
							...res.data
						}
					}
				})
			},
			update() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				let data = this.getChangeData();
				if (data) {
					isLoad = true;
					updateAbout(data).then(res => {
						if (res.status == 200) {
							this.showToast("更新成功！")
							this.getAboutData()
							setTimeout(() => {
								this.disabled = true;
							}, 500)
						} else {
							this.showToast(res.message)
						}
						isLoad = false;
					}).catch(() => {
						isLoad = false;
						this.showToast("出错了！")
					})
				} else {
					this.showToast("没有可更新数据！")
				}

			},
			cancel() {
				this.disabled = true;
				this.info = {
					...this.info_orginal
				}
			},
			getChangeData() {
				let data = {
					id: this.info.id
				}
				let keys = Object.keys(this.info);
				for (var i = 0; i < keys.length; i++) {
					if (this.info[keys[i]] != this.info_orginal[keys[i]] && typeof(this.info[keys[i]]) != 'object') {
						data[keys[i]] = this.info[keys[i]]
					}
				}
				if (Object.keys(data).length > 1) {
					return data
				} else {
					return false
				}
			},
			openPreview(e) {
				let url = e.currentTarget.dataset.url;
				let isTool = e.currentTarget.dataset.tool ? true : false
				this.preview.url = url;
				this.preview.isTool = isTool;
				this.preview.isShow = true;
			},
			changeDisabled() {
				this.disabled = !this.disabled
			},
			updateLogo(event) {
				let self = this;
				let file = event.target.files[0];
				if (file) {
					//创建用来读取此文件的对象
					let reader = new FileReader();
					//使用该对象读取file文件
					reader.readAsDataURL(file);
					//读取文件成功后执行的方法函数
					reader.onload = function() {
						this.formData = new FormData();
						this.formData.append("image", file, file.name);
						//读取成功后返回的一个参数e，整个的一个进度事件
						uploadFile(this.formData).then((res) => {
							if (res.status == 200) {
								self.info.logo = res.data.url;
								self.showToast("上传成功");
							} else {
								self.showToast("失败：" + res.message);
							}

						});
					};
				}
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>