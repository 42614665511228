import http from '../utils/Http'


export function getCarBrands(page = 1, size) {
	let data = {
		page: page,
		size: size
	}
	return http({
		url: 'car/brands',
		method: 'GET',
		params: data,
	})
}

export function getCarSeries(page = 1, size, untranslatedUg = false, untranslatedKz = false,word) {
	let data = {
		page: page,
		size: size,
		untranslated_ug: untranslatedUg,
		untranslated_kz: untranslatedKz
	}
	if(word){
		data['word'] = word
	}
	return http({
		url: 'car/series',
		method: 'GET',
		params: data,
	})
}


export function getCarManufacturers(page = 1, size, untranslatedUg = false, untranslatedKz = false) {
	let data = {
		page: page,
		size: size,
		untranslated_ug: untranslatedUg,
		untranslated_kz: untranslatedKz
	}
	return http({
		url: 'car/manufacturers',
		method: 'GET',
		params: data,
	})
}


export function getCarModels(page = 1, size, untranslatedUg = false, untranslatedKz = false, word) {
	let data = {
		page: page,
		size: size,
		untranslated_ug: untranslatedUg,
		untranslated_kz: untranslatedKz
	}
	if(word){
		data['word'] = word
	}
	return http({
		url: 'car/models',
		method: 'GET',
		params: data,
	})
}



export function translateBrandInfo(id, nameUg, nameKz, brand_logo) {
	let data = {
		id: id,
		nameUg: nameUg,
		nameKz: nameKz,
		brand_logo: brand_logo
	}
	return http({
		url: 'car/brand',
		method: 'POST',
		data: data,
	})
}
export function translateSeriesInfo(id, nameUg, nameKz) {
	let data = {
		id: id,
		nameUg: nameUg,
		nameKz: nameKz
	}
	return http({
		url: 'car/series',
		method: 'POST',
		data: data,
	})
}
export function translateManufacturerInfo(id, nameUg, nameKz) {
	let data = {
		id: id,
		nameUg: nameUg,
		nameKz: nameKz
	}
	return http({
		url: 'car/manufacturer',
		method: 'POST',
		data: data,
	})
}

export function translateModelInfo(id, nameUg, nameKz) {
	let data = {
		id: id,
		nameUg: nameUg,
		nameKz: nameKz
	}
	return http({
		url: 'car/model',
		method: 'POST',
		data: data,
	})
}



export function getDictionary(page = 1, size, word, untranslatedUg = false, untranslatedKz = false) {
	let data = {
		page: page,
		size: size,
		keyWord: word,
		untranslated_ug: untranslatedUg,
		untranslated_kz: untranslatedKz
	}
	return http({
		url: 'car/dictionary',
		method: 'GET',
		params: data,
	})
}


export function translateDictionaryInfo(id, nameUg, nameKz) {
	let data = {
		id: id,
		nameUg: nameUg,
		nameKz: nameKz
	}
	return http({
		url: 'car/translate-dictionary',
		method: 'POST',
		data: data,
	})
}

export function translateDictionary(name) {
	let data = {
		name: name
	}
	return http({
		url: 'car/dictionary',
		method: 'POST',
		data: data,
	})
}
