<template>
	<div class="container">
		<div class="title">
			<div class="text">角色管理</div>
			<div class="meta-functions">
				<div class="function-item add" @click="toAddPage">
					<i class="iconfont icon-add refresh"></i>
					<span>添加</span>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">角色名称</div>
					<div class="col flex-2">规则名称</div>
					<div class="col flex-2">描述</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(role, roleIndex) in roles" :key="roleIndex">
					<div class="col id">{{role.id}}</div>
					<div class="col flex-1">{{role.name}}</div>
					<div class="col flex-2">{{role.ruleName}}</div>
					<div class="col flex-2">{{role.ruleMsg}}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
						<div class="operation-item" title="删除" :data-id="role.id" :data-name="role.name"
							@click="showConfirmDialog">
							<i class="iconfont icon-shanchu"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<dialog-box ref="dialogBox" :content="dialogContent" v-on:confirm="onConfirmDelete" />
	</div>
</template>
<script>
	let standById = 0;

	import {
		// getRoles,
		deleteRole
	} from "@/api/admin";

	import DialogBox from "@/components/widgets/DialogBox";
	import Paginator from "@/components/widgets/Paginator.vue";
	
	export default {
		components: {
			DialogBox,
			Paginator
		},
		data() {
			return {
				roles: [{
					id: 1,
					name: "内容审核员",
					ruleName: "规则名称xxx",
					ruleMsg: "规则描述xxx"
				}], //角色数据，真实调用时清理本数据
				dialogContent: "",
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
			}
		},
		methods: {
			toAddPage() {
				this.$router.push("/admin/role/add");
			},
			loadData() {
				// getRoles().then((res) => {
				// 	if (res.status == 200) {
				// 		this.roles = res.data;
				// 	}
				// });
			},
			toEditPage(e) {
				this.$router.push(
					"/admin/role/update/" + e.currentTarget.dataset.id
				);
			},
			reloadUser(page) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reloadUser(page);
			},
			showConfirmDialog(e) {
				let dialogContent =
					"确定要删除《" + e.currentTarget.dataset.name + "》吗？";
				standById = e.currentTarget.dataset.id;
				this.dialogContent = dialogContent;
				this.$refs.dialogBox.show();
			},
			onConfirmDelete() {
				if (standById == 0) return;
				let self = this;
				deleteRole(standById).then((res) => {
					if (res.status == 200) {
						self.loadData();
					}
				});
			},
		},
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>