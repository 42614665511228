<template>
	<div class="container">
		<div class="title">
			<div class="text">更新分类</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">分类</div>
				<div class="right">
					<select class="select" v-model="info.category_id">
						<option :value="-1">请选择分类</option>
						<option :value="item.id" v-for="(item,index) in categoryList" :key="index">{{item.category_name}}</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<textarea class="textarea" v-model="info.info_tag"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">维文名称</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="info.info_tag_ug"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">哈文名称</div>
				<div class="right">
					<textarea class="textarea rtl" v-model="info.info_tag_kz"></textarea>
				</div>
			</div>
			<div class="row">
				<div class="left">状态</div>
				<div class="right">
					<select class="select" v-model="info.status">
						<option :value="0">隐藏</option>
						<option :value="1">显示</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.info_sort" @keypress="inputChange" ref="topInput"
						placeholder="数字越大越靠前" />
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="update">更新</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		updateLexicon,
		getLexiconSortList
	} from "@/api/lexicon";
	export default {
		data() {
			return {
				info: {},
				categoryList:[],
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		created() {
			if (this.$route.params.info) {
				this.info = JSON.parse(decodeURIComponent(this.$route.params.info));
				this.getCategoryList()
			} else {
				this.showToast("获取数据失败")
			}
		},
		methods: {
			update() {
				//更新按钮
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (!this.info.info_tag || !this.info.info_tag.trim()) {
					this.showToast("请输入中文名称")
					return
				}
				if (!this.info.info_tag_ug || !this.info.info_tag_ug.trim()) {
					this.showToast("请输入维文名称")
					return
				}
				if (!this.info.info_tag_kz || !this.info.info_tag_kz.trim()) {
					this.showToast("请输入哈文名称")
					return
				}
				if(this.info.category_id==-1){
					this.showToast("请选择分类")
					return
				}
				if(!(this.info.info_sort*1>=0)){
					this.showToast("请输入排序后")
					return
				}
				this.info.info_sort = Number(this.info.info_sort)
				isLoad = true;
				//发送请求
				isLoad = true;
				updateLexicon(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("更新成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			getCategoryList(){
				getLexiconSortList().then(res=>{
					if(res.status == 200){
						this.categoryList = res.data.data;
					}
				})
			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
	
	.container {
		
		.poster-btn {
			height: 30px;
			line-height: 30px;
			width: 80px;
			text-align: center;
			border: none;
			background-color: #5e9ec0;
			color: #fff;
			border-radius: 10px;
			cursor: pointer;
			margin-right: 18px;
		}
		
		.poster-loading {
			height: 30px;
			line-height: 30px;
			color: #dd524d;
		}
	}
</style>
