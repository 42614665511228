<template>
	<div class="container">
		<div class="title">
			<div class="text">添加车龄区间</div>
		</div>
		<div class="content">
			<div class="row">
				<div class="left">中文名称</div>
				<div class="right">
					<input type="text" class="input" v-model="info.name" placeholder="请输入中文名称">
				</div>
			</div>
			<div class="row">
				<div class="left">维语名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_ug" placeholder="请输入维语名称">
				</div>
			</div>
			<div class="row">
				<div class="left">哈语名称</div>
				<div class="right">
					<input type="text" class="input rtl" v-model="info.name_kz" placeholder="请输入哈语名称">
				</div>
			</div>
			<div class="row">
				<div class="left">最小值</div>
				<div class="right">
					<input type="number" class="input" v-model="info.min_age" @keypress="inputChange"
						placeholder="请输入最小值">
				</div>
			</div>
			<div class="row">
				<div class="left">最大值</div>
				<div class="right">
					<input type="number" class="input" v-model="info.max_age" @keypress="inputChange"
						placeholder="请输入最大值">
				</div>
			</div>
			<div class="row">
				<div class="left">排序号</div>
				<div class="right">
					<input type="number" class="input" v-model="info.top" @keypress="inputChange"
						placeholder="请输入排序号(越大越靠后)">
				</div>
			</div>
			<div class="btn">
				<div class="btn-item" @click="cancel">取消</div>
				<div class="btn-item add" @click="add">添加</div>
			</div>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
		<div class="toast" v-show="isShowToast">
			<span>{{ toastText }}</span>
		</div>
	</div>
</template>

<script>
	let isLoad = false;
	import {
		addFilterAge
	} from "@/api/filters";
	export default {
		data() {
			return {
				info: {
					name: "",
					name_ug: "",
					name_kz: "",
					min_age: "",
					max_age: "",
					top: 1
				},
				toastText: "请正确输入参数",
				isShowToast: false,
			}
		},
		methods: {
			add() {
				if (isLoad) {
					this.showToast("请稍等！")
					return
				}
				if (this.info.name == "") {
					this.showToast("请输入中文名称！")
					return
				}
				if (this.info.name_ug == "") {
					this.showToast("请输入维语名称！")
					return
				}
				if (this.info.name_kz == "") {
					this.showToast("请输入哈语名称！")
					return
				}
				this.info.min_age = Number(this.info.min_age)
				this.info.max_age = Number(this.info.max_age)
				if (!this.info.max_age) {
					this.showToast("请正确输入最大值！")
					return
				}
				isLoad = true;
				addFilterAge(this.info).then(res => {
					if (res.status == 200) {
						this.showToast("添加成功")
						setTimeout(() => {
							this.$router.back()
						}, 500)
					} else {
						this.showToast(res.message)
					}
					isLoad = false;
				}).catch(() => {
					isLoad = false;
					this.showToast("出错了！")
				})

			},
			cancel() {
				this.$router.back()
			},
			inputChange(e) {
				e.returnValue = /[\d]/.test(String.fromCharCode(e.keyCode))
			},
			showToast(e) {
				this.toastText = e;
				this.isShowToast = true;
				setTimeout(() => {
					this.isShowToast = false;
				}, 1500);
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/detail.less");
</style>
