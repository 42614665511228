<template>
	<div class="container" :class="{ shown: shown, fading: fading }">
		<div class="box" :class="{ fading: fading }">
			<div class="title">{{ title }}</div>
			<div class="content">{{ content }}</div>
			<div class="bottom">
				<button type="button" class="cancel" @click="dismiss" v-if="buttons.length>0">
					{{buttons[0]}}
				</button>
				<button type="button" class="confirm" @click="confirm" v-if="buttons.length>1">
					{{buttons[1]}}
				</button>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		emits: ["confirm"],
		props: {
			title: {
				type: String,
				default: "提示",
			},
			content: {
				type: String,
				default: "提示内容",
			},
			positiveText: {
				type: String,
				default: "确定",
			},
			negativeText: {
				type: String,
				default: "取消",
			},
			buttons: {
				// type: Array,
				default: ["取消","确定"],
			},
		},
		data() {
			return {
				shown: false,
				fading: false,
			};
		},
		methods: {
			show() {
				this.shown = true;
				setTimeout(() => {
					this.fading = true;
				}, 10);
			},
			dismiss() {
				this.fading = false;
				setTimeout(() => (this.shown = false), 400);
			},
			confirm() {
				this.$emit("confirm");
				this.dismiss();
			},
		},
	};
</script>
<style lang="less" scoped>
	@titleHeight: 50px;
	@bottomHeight: 66px;
	@buttonHeight: 40px;

	.container {
		position: fixed;
		width: 100%;
		height: 100%;
		z-index: 999;
		left: 0;
		top: 0;
		display: none;
		opacity: 0;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.618);
		transition: opacity 400ms;
		user-select: none;

		&.shown {
			display: flex;
		}

		&.fading {
			opacity: 1;
		}

		.box {
			display: flex;
			flex-direction: column;
			width: 360px;
			min-height: 160px;
			border-radius: 16px;
			background: white;
			transform: scale(0.1);
			transition: transform 400ms;

			&.fading {
				transform: scale(1);
			}

			.title {
				height: @titleHeight;
				line-height: @titleHeight;
				font-size: 16px;
				text-align: center;
			}

			.content {
				flex: 1;
				padding: 0 32px 16px 32px;
			}

			.bottom {
				height: @bottomHeight;
				display: flex;

				button {
					flex: 1;
					border: none;
					height: @buttonHeight;
					border-radius: @buttonHeight / 2;
					margin: 0 32px;
					cursor: pointer;

					&.confirm {
						background: linear-gradient(-220deg, #f5af19, #f12711);
						background-size: 200% @titleHeight;
						color: white;
						text-shadow: #f12711 0 1px 4px;
						box-shadow: #f5af1966 0 4px 16px;
						transition: background-position-x 400ms;

						&:hover {
							background-position-x: 100%;
						}
					}

					&.cancel {
						background: linear-gradient(-220deg, #bdc3c7, #2c3e50);
						background-size: 200% @titleHeight;
						color: white;
						text-shadow: #2c3e50 0 1px 4px;
						box-shadow: #bdc3c766 0 4px 16px;
						transition: background-position-x 400ms;

						&:hover {
							background-position-x: 100%;
						}
					}
				}
			}
		}
	}
</style>
