<template>
	<div class="container">
		<div class="title">
			<div class="text">汽车系列</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">中文名称</div>
					<div class="col flex-1 rtl-left">维文名称</div>
					<div class="col flex-1 rtl-left">哈文名称</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in carSecurityList" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.label }}</div>
					<div class="col flex-1 rtl-left">{{ item.label_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.label_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<config-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadCarSecurityList" />
	</div>
</template>
<script>
	import {
		getCarSecurityConfigList
	} from "@/api/car-security-config";
	import Paginator from "@/components/widgets/Paginator.vue";
	import ConfigTranslateModal from "@/components/car/ConfigTranslateModal.vue";
	export default {
		components: {
			Paginator,
			ConfigTranslateModal,
		},
		data() {
			return {
				routes: [],
				carSecurityList: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
			};
		},
		created() {
			this.loadCarSecurityList();
		},
		methods: {
			loadCarSecurityList() {
				getCarSecurityConfigList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.carSecurityList = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadCarSecurityList(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadCarSecurityList();
			},
			onPageChanged(page) {
				this.reloadCarSecurityList(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.carSecurityList[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>
