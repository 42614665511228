<template>
	<div class="container">
		<div class="title">
			<div class="text">反馈与建议</div>
		</div>
		<div class="filter">
			<div class="list">
				<div class="list-item" :class="{on:filterData.status>=0}">
					<label class="text">状态</label>
					<select class="select-mini" v-model="filter.status">
						<option :value="-1">请选择</option>
						<option :value="0">未处理</option>
						<option :value="1">处理中</option>
						<option :value="2">已处理</option>
					</select>
				</div>
				<div class="list-item">
					<div class="search-btn" @click="loadData">搜索</div>
				</div>
			</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col avatar">头像</div>
					<div class="col flex-1">用户名</div>
					<div class="col flex-1">手机号</div>
					<div class="col flex-1">状态</div>
					<div class="col flex-1">时间</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item,index) in dataList" :key="index">
					<div class="col id">
						{{item.id}}
					</div>
					<div class="col avatar">
						<img class="avatar-img" :src="item.customer.avatar_url" >
					</div>
					<div class="col flex-1">
						{{item.customer.real_name}}
					</div>
					<div class="col flex-1">
						{{item.customer.mobile_phone}}
					</div>
					<div class="col flex-1">
						<span v-if="item.status==0">未处理</span>
						<span v-if="item.status==1">处理中</span>
						<span v-if="item.status==2">已处理</span>
					</div>
					<div class="col flex-1" :data-time="item.created_at">
						{{rTime(item.created_at)}}
					</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="toEditPage">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="filterData.size" :page="filterData.page" :change="onPageChanged" />
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getAdviceList
	} from "@/api/advice";
	import Paginator from "@/components/widgets/Paginator.vue";
	export default {
		components: {
			Paginator
		},
		data() {
			return {
				dataList: [],
				filter: {
					status: -1,
				},
				filterData: {
					page: 1,
					size: 20,
				},
				totalRecords: 0,
				dialogContent: "",
			}
		},
		created(){
			this.loadData()
		},
		methods: {
			loadData() {
				if(this.filter.status == -1){
					this.filterData = {
						page: this.filterData.page,
						size: this.filterData.size
					}
				}else {
					this.filterData["status"] = this.filter.status;
				}
				getAdviceList(this.filterData).then((res) => {
					if (res.status == 200) {
						this.dataList = res.data.data;
						// this.totalRecords = res.data.total
						this.totalRecords = 1
					}
				});
			},
			reload(page) {
				if (page > 0) {
					this.filterData.page = page;
				}
				this.loadData();
			},
			onPageChanged(page) {
				this.reload(page);
			},
			toEditPage(e) {
				let index = e.currentTarget.dataset.index
				this.$router.push({
					name: "AdviceUpdate",
					params: {
						id: this.dataList[index].id
					}
				});
			},
			rTime(e){
				let json_date = new Date(e).toJSON();
				return new Date(new Date(json_date) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '') 
			},
		}
	}
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/filter.less");
	@import url("~@/assets/style/table.less");
</style>
