<template>
	<div class="container">
		<div class="title">
			<div class="text">省份翻译</div>
		</div>
		<div class="table">
			<div class="table-header">
				<div class="row">
					<div class="col id">#</div>
					<div class="col flex-1">名称</div>
					<div class="col flex-1">维文</div>
					<div class="col flex-1">哈文</div>
					<div class="col operation">操作</div>
				</div>
			</div>
			<div class="table-body">
				<div class="row" v-for="(item, index) in provinces" :key="index">
					<div class="col id">{{ item.id }}</div>
					<div class="col flex-1">{{ item.name }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_ug }}</div>
					<div class="col flex-1 rtl-left">{{ item.name_kz }}</div>
					<div class="col operation">
						<div class="operation-item" title="编辑" :data-index="index" @click="showTranslateModal">
							<i class="iconfont icon-bianji"></i>
						</div>
					</div>
				</div>
			</div>
			<div class="paginator">
				<paginator :total="totalRecords" :size="pageSize" :page="curPage" :change="onPageChanged" />
			</div>
		</div>
		<provinces-translate-modal ref="translateModal" :info="infoToTranslate" v-on:confirm="reloadProvinces" />
	</div>
</template>
<script>
	import {
		getProvincesList
	} from "@/api/provinces";
	import Paginator from "@/components/widgets/Paginator.vue";
	import ProvincesTranslateModal from "@/components/provinces/ProvincesTranslateModal.vue";
	export default {
		components: {
			Paginator,
			ProvincesTranslateModal,
		},
		data() {
			return {
				routes: [],
				provinces: [],
				curPage: 1,
				pageSize: 20,
				totalRecords: 0,
				infoToTranslate: null,
			};
		},
		created() {
			this.loadProvinces();
		},
		methods: {
			loadProvinces() {
				getProvincesList(this.curPage, this.pageSize).then((res) => {
					if (res.status == 200) {
						this.provinces = res.data.data;
						this.totalRecords = res.data.total;
					}
				});
			},
			reloadProvinces(page = 0) {
				if (page > 0) {
					this.curPage = page;
				}
				this.loadProvinces();
			},
			onPageChanged(page) {
				this.reloadProvinces(page);
			},
			showTranslateModal(e) {
				let index = e.currentTarget.dataset.index;
				this.infoToTranslate = JSON.parse(JSON.stringify(this.provinces[index]));
				this.$refs.translateModal.show();
			},
		},
	};
</script>

<style lang="less" scoped>
	@import url("~@/assets/style/table.less");
</style>